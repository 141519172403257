import './acces_clients.scss'
import ClientDataService from '../../services/clientsService'
import { useEffect, useState } from 'react'
import { useUserAuth } from '../../context/UserAuthContext'
import { Button } from 'react-bootstrap'
import ClientListSuiviQualite from '../ClientLists/clientListSuiviQualite/ClientListSuiviQualite'
import ClientListRapportPassage from '../ClientLists/clientsListRapportsPassage/ClientListRapportPassage'
import ClientListCheckMateriel from '../ClientLists/clientListCheckMateriel/ClientListCheckMateriel'




function AccesClientsForm() {

    //Log out
    const { logOut } = useUserAuth()

    const handleLogOut = async () => {
        try {
            localStorage.removeItem('isClient')
            localStorage.removeItem('password')
            localStorage.removeItem('clients')
            localStorage.removeItem('client')
            localStorage.removeItem('rapportType')
            localStorage.removeItem('showRapportPassage')
            localStorage.removeItem('showCheckMateriel')
            localStorage.removeItem('showSuiviQualite')
            await logOut()
        }
        catch (err) {
            console.log(err.message)
        }
    }


    const [password, setPassword] = useState(() => JSON.parse(localStorage.getItem('password')) || '')
    const [clients, setClients] = useState(() => JSON.parse(localStorage.getItem('clients')) || [])
    const [client, setClient] = useState(() => JSON.parse(localStorage.getItem('client')) || '')
    const [rapportType, setRapportType] = useState(() => JSON.parse(localStorage.getItem('rapportType')) || '')
    const [showRapportPassage, setShowRapportPassage] = useState(() => JSON.parse(localStorage.getItem('showRapportPassage')) || false)
    const [showCheckMateriel, setShowCheckMateriel] = useState(() => JSON.parse(localStorage.getItem('showCheckMateriel')) || false)
    const [showSuiviQualite, setShowSuiviQualite] = useState(() => JSON.parse(localStorage.getItem('showSuiviQualite')) || false)

    const [error, setError] = useState(false);


    //get clients
    useEffect(() => {
        getClients()

    }, [])

    /*const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }*/

    const getClients = async () => {
        try {
            const data = await ClientDataService.getActiveClients();
            const clientsList = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));


            const sortedClients = clientsList.sort((a, b) => a.client.localeCompare(b.client));

            setClients(sortedClients);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };

    //persister data avec localStorage
    useEffect(() => {
        if (password !== '') localStorage.setItem("password", JSON.stringify(password))
        if (clients.length) localStorage.setItem("clients", JSON.stringify(clients))
        if (client !== '') localStorage.setItem("client", JSON.stringify(client))
        if (rapportType !== '') localStorage.setItem("rapportType", JSON.stringify(rapportType))
        localStorage.setItem('showRapportPassage', JSON.stringify(showRapportPassage))
        localStorage.setItem('showCheckMateriel', JSON.stringify(showCheckMateriel))
        localStorage.setItem('showSuiviQualite', JSON.stringify(showSuiviQualite))
    }, [password, client, clients, rapportType, showRapportPassage, showCheckMateriel, showSuiviQualite])


    const handleSubmit = (e) => {
        e.preventDefault()
        setError(false)

        const clientsArray = []
        clients.map((client) => {
            clientsArray.push(client.client, client.password)

        })


        let indexClients = clientsArray.indexOf(password)
        if (indexClients !== -1) {

            setClient(clientsArray[indexClients - 1])
            if (rapportType === 'Rapports de passage') {
                setShowRapportPassage(true)
            }
            else if (rapportType === 'Checks Matériel') {
                setShowCheckMateriel(true)
            }
            else if (rapportType === 'Suivi Qualité') {
                setShowSuiviQualite(true)
            }
            else {
                alert("Veuillez choisir un type de document")
            }


        }
        else {
            setError(true)
        }

    }

    const goBack = () => {
        setShowRapportPassage(false)
    }

    const goBackToList = () => {
        setShowCheckMateriel(false)
    }

    const goBackHome = () => {
        setShowSuiviQualite(false)
    }




    return (

        <>

            {showRapportPassage === false & showCheckMateriel === false & showSuiviQualite === false ? (

                <div className='emailContainer'>

                    <div className="accessLogo">
                        <img src="/img/logo.svg" alt="logo netclean" className="m-3 logoAccess" />
                        <button onClick={handleLogOut} className="deco">
                            Déconnexion
                        </button>
                    </div>




                    <h1 className="display-1 text-center text-dark pt-5">Bienvenue sur votre espace clients</h1>
                    <h3 className='text-center'>Téléchargez vos rapports de visites</h3>


                    <div className="email">

                        <form >
                            <label className='title'>Confirmez votre Mot de passe</label>
                            <input type="password" placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />


                            <div className="formInput">

                                <select value={rapportType} onChange={(event) => { setRapportType(event.target.value) }} required>
                                    <option>Type de document ?</option>
                                    <option>Rapports de passage</option>
                                    <option >Checks Matériel</option>
                                    <option>Suivi Qualité</option>

                                </select>
                            </div>

                            <button type="submit" onClick={handleSubmit}>Afficher vos rapports</button>
                            {error === true ? <span>nom d'utilisateur ou email incorrect</span> : ""}

                        </form>
                        <div className="iso">
                            <div className="iso1">
                                <img src="/img/vca.jpg" alt="certificat vca" className="isoImg1" />
                                <img src="/img/ugbn.png" alt="certificat ugbn" className="isoImg1" />
                            </div>
                            <div className="iso2">
                                <img src="/img/iso.png" alt="certificats iso" className="isoImg2" />
                            </div>
                        </div>
                    </div>

                </div>
            ) : showRapportPassage === true ? (
                <ClientListRapportPassage client={client} goBack={goBack} />


            ) : showCheckMateriel === true ? (<ClientListCheckMateriel client={client} goBackToList={goBackToList} />) : (<ClientListSuiviQualite client={client} goBackHome={goBackHome} />)}

        </>
    )
}



export default AccesClientsForm