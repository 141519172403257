
import './checkMaterielSingle.scss'
import { Form, Container, InputGroup, Alert, Button, ToggleButton } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import ChecksMaterielDataService from '../../../services/checksMaterielServices'
import ClientDataService from '../../../services/clientsService'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import CopyText from '../../../components/CopyText'

function CheckMaterielSingle({ id, setRapportId }) {


    //Voir les clients 
    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }

    //states


    const [date, setDate] = useState("")
    const [client, setClient] = useState("")
    const [address, setAddress] = useState('')
    const [status, setStatus] = useState('')
    const [doneBy, setDoneBy] = useState('')
    const [produits, setProduits] = useState([])
    const [prodQtys, setProdQtys] = useState([])
    const [materiels, setMateriels] = useState([])
    const [matQtys, setMatQtys] = useState([])
    const [sacs, setSacs] = useState([])
    const [sacsQtys, setSacsQtys] = useState([])
    const [papiers, setPapiers] = useState([])
    const [papiersQtys, setPapiersQtys] = useState([])
    const [autre1, setAutre1] = useState("")
    const [packing1, setPacking1] = useState('')
    const [qty1, setQty1] = useState('')
    const [autre2, setAutre2] = useState("")
    const [packing2, setPacking2] = useState('')
    const [qty2, setQty2] = useState('')
    const [autre3, setAutre3] = useState("")
    const [packing3, setPacking3] = useState('')
    const [qty3, setQty3] = useState('')
    const [autre4, setAutre4] = useState("")
    const [packing4, setPacking4] = useState('')
    const [qty4, setQty4] = useState('')
    const [autre5, setAutre5] = useState("")
    const [packing5, setPacking5] = useState('')
    const [qty5, setQty5] = useState('')
    const [clientName, setClientName] = useState("")
    const [signature, setSignature] = useState('')
    const [deliveredBy, setDeliveredBy] = useState('')
    const [checked, setChecked] = useState('')

    const [message, setMessage] = useState({ error: false, msg: "" })

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")

        const newCheckMateriel = {
            date: date,
            client: client,
            address: address,
            status: status,
            doneBy: doneBy,
            produit: produits,
            prodQty: prodQtys,
            materiel: materiels,
            matQty: matQtys,
            sac: sacs,
            sacQty: sacsQtys,
            papier: papiers,
            papierQty: papiersQtys,
            autre1: autre1,
            packing1: packing1,
            qty1: qty1,
            autre2: autre2,
            packing2: packing2,
            qty2: qty2,
            autre3: autre3,
            packing3: packing3,
            qty3: qty3,
            autre4: autre4,
            packing4: packing4,
            qty4: qty4,
            autre5: autre5,
            packing5: packing5,
            qty5: qty5,
            clientName: clientName,
            deliveredBy: deliveredBy,
            checked: checked === undefined ? '' : 'Vérifié'

        }

        try {
            if (id !== undefined && id !== "") {
                await ChecksMaterielDataService.updateCheckMateriel(id, newCheckMateriel)
                setRapportId("")
                setMessage({ error: false, msg: "Les modifications ont été enregistrées" })
            }
            else {
                setMessage({ error: true, msg: "Le rapport est vide" })
            }

        } catch (err) {
            setMessage({ error: true, msg: err.message })
        }


    }

    const handleEdit = async () => {
        setMessage("")
        try {
            const docSnap = await ChecksMaterielDataService.getCheckMateriel(id)
            setDate(docSnap.data().date)
            setClient(docSnap.data().client)
            setAddress(docSnap.data().address)
            setStatus(docSnap.data().status)
            setDoneBy(docSnap.data().doneBy)
            setProduits(docSnap.data().produit)
            setProdQtys(docSnap.data().prodQty)
            setMateriels(docSnap.data().materiel)
            setMatQtys(docSnap.data().matQty)
            setSacs(docSnap.data().sac)
            setSacsQtys(docSnap.data().sacQty)
            setPapiers(docSnap.data().papier)
            setPapiersQtys(docSnap.data().papierQty)
            setAutre1(docSnap.data().autre1)
            setPacking1(docSnap.data().packing1)
            setQty1(docSnap.data().qty1)
            setAutre2(docSnap.data().autre2)
            setPacking2(docSnap.data().packing2)
            setQty2(docSnap.data().qty2)
            setAutre3(docSnap.data().autre3)
            setPacking3(docSnap.data().packing3)
            setQty3(docSnap.data().qty3)
            setAutre4(docSnap.data().autre4)
            setPacking4(docSnap.data().packing4)
            setQty4(docSnap.data().qty4)
            setAutre5(docSnap.data().autre5)
            setPacking5(docSnap.data().packing5)
            setQty5(docSnap.data().qty5)
            setClientName(docSnap.data().clientName)
            setSignature(docSnap.data().signature)
            setDeliveredBy(docSnap.data().deliveredBy)
            setChecked(docSnap.data().checked)
        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }

    useEffect(() => {
        if (id !== undefined && id !== "") {
            handleEdit()
        }
    }, [id])

    //imprimer le rapport,créer PDF et envoyer par mail
    const [sent,setSent] = useState(false)
    const [displayPrint, setDisplayPrint] = useState(false)

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `contrôle-matériel${date}${client}`,
        onAfterPrint: () => setSent(true)
    })

    const handlePDF = (e) => {
        e.preventDefault()
        getClientEmail()
        confirmAlert({
            title: 'Confirmez',
            message: "Etes-vous sûr d'avoir vérifié ce rapport avant de l'envoyer ?",
            buttons: [
                {
                    label: 'Oui',
                    onClick: async () => {
                        const rapport = {
                            date: date,
                            client: client,
                            address: address,
                            status: status,
                            doneBy: doneBy,
                            produit: produits,
                            prodQty: prodQtys,
                            materiel: materiels,
                            matQty: matQtys,
                            sac: sacs,
                            sacQty: sacsQtys,
                            papier: papiers,
                            papierQty: papiersQtys,
                            autre1: autre1,
                            packing1: packing1,
                            qty1: qty1,
                            autre2: autre2,
                            packing2: packing2,
                            qty2: qty2,
                            autre3: autre3,
                            packing3: packing3,
                            qty3: qty3,
                            autre4: autre4,
                            packing4: packing4,
                            qty4: qty4,
                            autre5: autre5,
                            packing5: packing5,
                            qty5: qty5,
                            clientName: clientName,
                            deliveredBy: deliveredBy,
                            sent: sent === false ? 'OUI' : ''
                        }
                        try{
                            await ChecksMaterielDataService.updateCheckMateriel(id, rapport)
                        }
                        catch (err){
                            setMessage({ error: true, msg: err.message })
                        }
                        setDisplayPrint(true)
                    }
                },
                {
                    label: 'Non',

                }
            ]
        })
    }

    //Trouver l'email du client
    const [clientEmail, setClientEmail] = useState('')

    const getClientEmail = () => clients.map((thisClient) => {
        if (thisClient.client === client) {
            setClientEmail(thisClient.email);
        }
    })


    if (displayPrint === true) {
        return (
            <>
                <Button className='m-5 bg-secondary' onClick={handlePrint} style={{ cursor: "pointer" }}>Print / PDF et envoyer</Button>
                <CopyText clientEmail={clientEmail} />
                <div ref={componentRef} className='text-center'>
                    <h4 className='mb-5 '>Contrôle du {date}</h4>
                    <Container className='w-50 mb-4'>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Date</InputGroup.Text>
                            <Form.Control type="Texte" value={date} readOnly={true} />
                        </InputGroup>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Client</InputGroup.Text>
                            <Form.Control type="Texte" value={client} readOnly={true} />
                        </InputGroup>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Adresse</InputGroup.Text>
                            <Form.Control type="Texte" value={address} readOnly={true} />
                        </InputGroup>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Status</InputGroup.Text>
                            <Form.Control type="Texte" value={status} readOnly={true} />
                        </InputGroup>
                    </Container>
                    <div className='text-center mt-4'>
                        <h5>Produits</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {produits.map((produit, index) => {

                                    return (
                                        <tr key={[produit.id]} >
                                            <td value={[produit.product]}>{[produit.product]}</td>
                                            <td>{[produit.packing]}</td>
                                            <td className="table-primary">

                                                <div className="input-group " >
                                                    <input type="number" className="form-control" value={prodQtys[index]} />
                                                </div>

                                            </td>

                                        </tr>

                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Petit Matériel</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materiels.map((materiel, index) => {

                                    return (
                                        <tr key={[materiel.id]} >
                                            <td value={[materiel.product]}>{[materiel.product]}</td>
                                            <td>{[materiel.packing]}</td>
                                            <td className="table-primary">
                                                <div className="input-group">
                                                    <input type="number" className="form-control" value={matQtys[index]} />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Sacs Aspirateur</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sacs.map((sac, index) => {

                                    return (
                                        <tr key={[sac.id]}>
                                            <td value={[sac.product]}>{[sac.product]}</td>
                                            <td>{[sac.packing]}</td>
                                            <td className="table-primary">
                                                <div className="input-group">
                                                    <input type="number" className="form-control" value={sacsQtys[index]} onChange={(event) => {
                                                        setSacsQtys((prev) => {
                                                            const result = [...prev]
                                                            result[index] = event.target.value
                                                            return result
                                                        })


                                                    }} />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Papier</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {papiers.map((papier, index) => {

                                    return (
                                        <tr key={[papier.id]} >
                                            <td value={[papier.product]}>{[papier.product]}</td>
                                            <td>{[papier.packing]}</td>
                                            <td className="table-primary">
                                                <div className="input-group">
                                                    <input type="number" className="form-control" value={papiersQtys[index]} onChange={(event) => {
                                                        setPapiersQtys((prev) => {
                                                            const result = [...prev]
                                                            result[index] = event.target.value
                                                            return result
                                                        })


                                                    }} />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Autre</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>Produit</th>
                                    <th>Conditionnement</th>
                                    <th>Quantité</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre1} onChange={(event) => { setAutre1(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing1} onChange={(event) => { setPacking1(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty1} onChange={(event) => { setQty1(event.target.value) }} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre2} onChange={(event) => { setAutre2(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing2} onChange={(event) => { setPacking2(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty2} onChange={(event) => { setQty2(event.target.value) }} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre3} onChange={(event) => { setAutre3(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing3} onChange={(event) => { setPacking3(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty3} onChange={(event) => { setQty3(event.target.value) }} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre4} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing4} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty4} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre5} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing5} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty5} />
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Container className='w-50 mb-4 mt-5'>
                        <InputGroup className='mb-3 p-3 mt-5'>
                            <InputGroup.Text>Etabli par</InputGroup.Text>
                            <Form.Control type="Texte" value={doneBy} readOnly={true} />
                        </InputGroup>

                        <InputGroup className="mb-3 p-3" >
                            <InputGroup.Text>Livré par: </InputGroup.Text>
                            <Form.Control type="Texte" value={deliveredBy} readOnly={true} />
                        </InputGroup>

                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Nom Client</InputGroup.Text>
                            <Form.Control type="Texte" value={clientName} readOnly={true} />
                        </InputGroup>

                        <p className='text-center h5'>Signature Client</p>
                        <div className="signature">
                            <img src={signature} alt="" />
                        </div>
                    </Container>
                </div>
            </>
        )
    }
    else {
        return (
            <div className="checkMaterielSingle">

                <div className="checkMaterielSingleContainer">

                </div>
                <div className="printSend">
                    <Button className='bg-warning text-dark print' onClick={handlePDF}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-1 bi bi-printer-fill" viewBox="0 0 16 16" >
                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    </svg>Créer PDF et envoyer ou imprimer</Button>

                </div>
                <div className="mt-5 text-center">
                    <h3>Contrôle Matériel du {date}</h3>
                    <Container className='mt-5'>
                        <form onSubmit={handleSubmit} className='m-5'>

                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Date</InputGroup.Text>
                                <Form.Control type="Texte" value={date} onChange={(event) => { setDate(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Client</InputGroup.Text>
                                <Form.Select value={client} onChange={(event) => { setClient(event.target.value) }} aria-label="Default select example">
                                    <option>Choisir un client</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>

                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Adresse</InputGroup.Text>
                                <Form.Control type="Texte" value={address} onChange={(event) => { setAddress(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Status</InputGroup.Text>
                                <Form.Select aria-label="Default select example" value={status} onChange={(event) => { setStatus(event.target.value) }}>
                                    <option>Sélectionner un status</option>
                                    <option value="Nouveau">Nouveau client</option>
                                    <option value="Régulier">Régulier</option>
                                    <option value="GN">Grand Nettoyage</option>
                                    <option value="Test">Test</option>

                                </Form.Select>
                            </InputGroup>

                            <hr className='mb-5' />

                            
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"

                                >
                                    <Typography>Produits</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="container">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Quantité</th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                {produits.map((produit, index) => {

                                                    return (
                                                        <tr key={[produit.id]} >
                                                            <td value={[produit.product]}>{[produit.product]}</td>
                                                            <td>{[produit.packing]}</td>
                                                            <td className="table-primary">

                                                                <div className="input-group " >
                                                                    <input type="text" className="form-control" value={prodQtys[index]} onChange={(event) => {
                                                                        setProdQtys((prev) => {
                                                                            const result = [...prev]
                                                                            result[index] = event.target.value
                                                                            return result
                                                                        })


                                                                    }} />
                                                                </div>

                                                            </td>

                                                        </tr>

                                                    )
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>

                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Petit Matériel</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="container">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Quantité</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {materiels.map((materiel, index) => {

                                                    return (
                                                        <tr key={[materiel.id]} >
                                                            <td value={[materiel.product]}>{[materiel.product]}</td>
                                                            <td>{[materiel.packing]}</td>
                                                            <td className="table-primary">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={matQtys[index]} onChange={(event) => {
                                                                        setMatQtys((prev) => {
                                                                            const result = [...prev]
                                                                            result[index] = event.target.value
                                                                            return result
                                                                        })


                                                                    }} />
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>

                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Sacs Aspirateur</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="container">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Quantité</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sacs.map((sac, index) => {

                                                    return (
                                                        <tr key={[sac.id]}>
                                                            <td value={[sac.product]}>{[sac.product]}</td>
                                                            <td>{[sac.packing]}</td>
                                                            <td className="table-primary">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={sacsQtys[index]} onChange={(event) => {
                                                                        setSacsQtys((prev) => {
                                                                            const result = [...prev]
                                                                            result[index] = event.target.value
                                                                            return result
                                                                        })


                                                                    }} />
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>

                            </Accordion>
                            <Accordion className='newPage'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Papier</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="container">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th>Quantité</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {papiers.map((papier, index) => {

                                                    return (
                                                        <tr key={[papier.id]} >
                                                            <td value={[papier.product]}>{[papier.product]}</td>
                                                            <td>{[papier.packing]}</td>
                                                            <td className="table-primary">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" value={papiersQtys[index]} onChange={(event) => {
                                                                        setPapiersQtys((prev) => {
                                                                            const result = [...prev]
                                                                            result[index] = event.target.value
                                                                            return result
                                                                        })


                                                                    }} />
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>

                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Autres</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="container">
                                        <table className="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Produit</th>
                                                    <th>Conditionnement</th>
                                                    <th>Quantité</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={autre1} onChange={(event) => { setAutre1(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={packing1} onChange={(event) => { setPacking1(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td className="table-primary">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={qty1} onChange={(event) => { setQty1(event.target.value) }} />
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={autre2} onChange={(event) => { setAutre2(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={packing2} onChange={(event) => { setPacking2(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td className="table-primary">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={qty2} onChange={(event) => { setQty2(event.target.value) }} />
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={autre3} onChange={(event) => { setAutre3(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={packing3} onChange={(event) => { setPacking3(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td className="table-primary">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={qty3} onChange={(event) => { setQty3(event.target.value) }} />
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={autre4} onChange={(event) => { setAutre4(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={packing4} onChange={(event) => { setPacking4(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td className="table-primary">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={qty4} onChange={(event) => { setQty4(event.target.value) }} />
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={autre5} onChange={(event) => { setAutre5(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={packing5} onChange={(event) => { setPacking5(event.target.value) }} />
                                                        </div>
                                                    </td>
                                                    <td className="table-primary">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control" value={qty5} onChange={(event) => { setQty5(event.target.value) }} />
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>

                            </Accordion>
                            {/* </div> */}

                            <InputGroup className='mb-5 p-3 mt-5'>
                                <InputGroup.Text>Etabli par</InputGroup.Text>
                                <Form.Control type="Texte" value={doneBy} onChange={(event) => { setDoneBy(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Livré par: </InputGroup.Text>
                                <Form.Control type="Texte" value={deliveredBy} onChange={(event) => { setDeliveredBy(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className='mb-5 p-3 newPage'>
                                <InputGroup.Text>Nom Client</InputGroup.Text>
                                <Form.Control type="Texte" value={clientName} onChange={(event) => { setClientName(event.target.value) }} />
                            </InputGroup>

                            <p className='text-center h5'>Signature du Client</p>
                            <p className='text-secondary text-center mb-5 printP'>La signature n'est pas modifiable</p>
                            <div className="signature">
                                <img src={signature} alt="" />
                            </div>

                            <h5>Cliquer pour marquer ce rapport comme "Vérifié" </h5>
                            
                                    <ToggleButton
                                        id="toggle-check"
                                        type="checkbox"
                                        variant="warning"
                                        value={checked}
                                        onChange={(e) => setChecked("Vérifié")}
                                        className={checked === "Vérifié" ? "mb-3 text-white bg-success h6" : "bg-warning text-dark"}
                                    >
                                        {checked === undefined || checked === '' ? "Non Vérifié" : checked}
                                    </ToggleButton>
                                    <p>Cliquer sur Modifier pour valider</p>

                            <div className='mt-5 mb-5'>
                                <button className="button" type="submit"> Modifier</button>
                            </div>
                        </form>
                    </Container>
                    <div className="p-2 h5 m-2 text-center">

                        {message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>{message?.msg}</Alert>)}
                    </div>
                </div>
            </div>

        )
    }
}

export default CheckMaterielSingle