import Navigation from '../../components/navigation/Navigation'
import { Form, Button, Container, FormLabel, FormGroup, InputGroup, FormControl, Alert } from 'react-bootstrap'
import SignaturePad from 'react-signature-canvas'
import './rapportPassage.scss'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import { useState, useEffect, useRef } from 'react'
import 'date-fns/locale/fr'
import { storage } from '../../firebase-config'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import ClientDataService from '../../services/clientsService'
import RapportsPassagesDataService from '../../services/rapportsPassagesServices'
import NotificationDataService from '../../services/notificationsService'
import { serverTimestamp } from 'firebase/firestore'


registerLocale('fr', fr)

function RapportPassage() {



    //Voir les clients pour dropdown "Choisir client"
    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    /*const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }*/

    const getClients = async () => {
        try {
            const data = await ClientDataService.getActiveClients();
            const clientsList = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));


            const sortedClients = clientsList.sort((a, b) => a.client.localeCompare(b.client));

            setClients(sortedClients);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };

    //Uploader photos
   
    const [uploaded, setUploaded] = useState(false)

    const [files, setFiles] = useState([])

    useEffect(() => {
        files.map((file) => {
            const uploadFile = () => {

                const uniqueName =  new Date().getTime() + file.name

                const storageRef = ref(storage, uniqueName)
                const uploadTask = uploadBytesResumable(storageRef, file)

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        console.log("Upload is " + progress + "% done")
                        if (progress === 100){
                            setUploaded(true)
                        }
                        switch (snapshot.state) {
                            case "paused":
                                console.log("Upload is paused")
                                break
                            case "running":
                                console.log("Upload is running")
                                break
                            default:
                                break
                        }
                    },
                    (error) => {
                        console.log(error)
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((DownloadURLs) => {
                            setImages(prev => [...prev, DownloadURLs])
                        })
                    }
                )
                
            }
            
            
            return file && uploadFile()
        })

    }, [files])

    //Signature 
    let sigCanvas = useRef({})


    //states 
    const [Cdate, setDate] = useState(new Date().toLocaleDateString("fr-FR"))
    const [client, setClient] = useState("")
    const [present, setPresent] = useState("")
    const [clothes, setClothes] = useState("")
    const [presentation, setPresentation] = useState("")
    const [cart, setCart] = useState("")
    const [products, setProducts] = useState("")
    const [colors, setColors] = useState("")
    const [remarks, setRemarks] = useState("")
    const [actions, setActions] = useState("")
    const [clientName, setClientName] = useState("")
    const [images, setImages] = useState([])
    const [signature, setSignature] = useState('')



    const [message, setMessage] = useState({ error: false, msg: "" })



    const save = (event) => {
        event.preventDefault()
        const sigData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        setSignature(sigData)

    }

    const clear = () => {
        sigCanvas.current.clear()
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")
        if (client === "" || present === "" || clothes === "" || presentation === "" || cart === "" || products === "" || colors === "") {
            setMessage({ error: true, msg: "Les champs * sont obligatoires" })
            return
        }


        const newNotification = {
            type:"passage"
        }

        const newRapport = {
            date: Cdate,
            client: client,
            present: present,
            clothes: clothes,
            presentation: presentation,
            cart: cart,
            products: products,
            colors: colors,
            remarks: remarks,
            actions: actions,
            clientName: clientName,
            image: images,
            signature: signature,
            createdAt: serverTimestamp()

        }

        try {
            await NotificationDataService.addNotification(newNotification)
            await RapportsPassagesDataService.addRapportPassage(newRapport)
            setMessage({ error: false, msg: "Le rapport a bien été envoyé" })

        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }

    }


    return (
        <>
            <Navigation />
            <div>

                <div className="mt-5 text-center">
                    <h1>Rapport de Passage : Contrôle classique</h1>
                    <Container className='mt-5'>
                        <Form className='m-5 mb-1' onSubmit={handleSubmit} >

                            <p>* Date: </p>
                            <DatePicker

                                dateFormat='dd/MM/yyyy'
                                value={Cdate}
                                onChange={(date) => {
                                    const dateString = new Date(date).toLocaleDateString("fr-FR")
                                    setDate(dateString)
                                }}
                                locale='fr'
                                showYearDropdown
                                scrollableMonthYearDropdown
                                className='mb-5 input'
                            />


                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>* Client</InputGroup.Text>
                                <Form.Select value={client} onChange={(event) => { setClient(event.target.value) }} aria-label="Default select example">
                                    <option>Choisir un client</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>

                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>* Présents</InputGroup.Text>
                                <Form.Control type="Texte" value={present} onChange={(event) => { setPresent(event.target.value) }} />
                            </InputGroup>


                            <h3>Rencontre du personnel sur place</h3>

                            <FormGroup className='mb-3'>
                                <FormLabel className='h5 mt-4 mb-3'>* Vêtement de travail :</FormLabel>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            className='me-4'
                                            inline
                                            label="OK"
                                            name="clothes"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            checked={clothes === 'OK'}
                                            value="OK"
                                            onChange={(event) => { setClothes(event.target.value) }}

                                        />
                                        <Form.Check
                                            inline
                                            label="pas_OK"
                                            name="clothes"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            checked={clothes === 'pas_OK'}
                                            value="pas_OK"
                                            onChange={(event) => { setClothes(event.target.value) }}
                                        />

                                    </div>
                                ))}
                            </FormGroup>

                            <FormGroup className='mb-3'>
                                <FormLabel className='h5 mt-4 mb-3'>* Présentation du personnel exécutant :</FormLabel>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            className='me-4'
                                            inline
                                            label="OK"
                                            name="presentation"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            checked={presentation === 'OK'}
                                            value="OK"
                                            onChange={(event) => { setPresentation(event.target.value) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="pas_OK"
                                            name="presentation"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            checked={presentation === 'pas_OK'}
                                            value="pas_OK"
                                            onChange={(event) => { setPresentation(event.target.value) }}
                                        />

                                    </div>
                                ))}
                            </FormGroup>

                            <FormGroup className='mb-3'>
                                <FormLabel className='h5 mt-4 mb-3'>* Présentation chariot :</FormLabel>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            className='me-4'
                                            inline
                                            label="OK"
                                            name="cart"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            checked={cart === 'OK'}
                                            value="OK"
                                            onChange={(event) => { setCart(event.target.value) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="pas_OK"
                                            name="cart"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            checked={cart === 'pas_OK'}
                                            value="pas_OK"
                                            onChange={(event) => { setCart(event.target.value) }}
                                        />

                                    </div>
                                ))}
                            </FormGroup>

                            <FormGroup className='mb-3'>
                                <FormLabel className='h5 mt-4 mb-3'>* Utilisation respective des produits et dosages :</FormLabel>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            className='me-4'
                                            inline
                                            label="OK"
                                            name="products"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            checked={products === 'OK'}
                                            value="OK"
                                            onChange={(event) => { setProducts(event.target.value) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="pas_OK"
                                            name="products"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            checked={products === 'pas_OK'}
                                            value="pas_OK"
                                            onChange={(event) => { setProducts(event.target.value) }}
                                        />

                                    </div>
                                ))}
                            </FormGroup>

                            <FormGroup className='mb-5'>
                                <FormLabel className='h5 mt-4 mb-3'>* Respect des codes couleurs :</FormLabel>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                        <Form.Check
                                            className='me-4'
                                            inline
                                            label="OK"
                                            name="colors"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            checked={colors === 'OK'}
                                            value="OK"
                                            onChange={(event) => { setColors(event.target.value) }}
                                        />
                                        <Form.Check
                                            inline
                                            label="pas_OK"
                                            name="colors"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            checked={colors === 'pas_OK'}
                                            value="pas_OK"
                                            onChange={(event) => { setColors(event.target.value) }}
                                        />

                                    </div>
                                ))}
                            </FormGroup>

                            <InputGroup className='mb-5'>
                                <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                                <FormControl as="textarea" value={remarks} onChange={(event) => { setRemarks(event.target.value) }} aria-label="With textarea" />
                            </InputGroup>

                            <InputGroup className='mb-5'>
                                <InputGroup.Text>Actions à prendre</InputGroup.Text>
                                <FormControl as="textarea" value={actions} onChange={(event) => { setActions(event.target.value) }} aria-label="With textarea" />
                            </InputGroup>

                            <p className='h5'>Ajouter une photo: (veuillez utiliser un format PNG ou JPEG/JPG)</p>
                            <InputGroup className='mb-1'>

                                <FormControl type="file"
                                    multiple
                                    onChange={(event) => {
                                        for (let i = 0; i < event.target.files.length; i++) {
                                            const image = event.target.files[i]
                                            setFiles((prev) => [...prev, image])
                                        }
                                    }
                                    } />
                            </InputGroup>
                            <p className='text-success text-center'>{uploaded === true ? "Téléchargement terminé" : ""}</p>


                            <InputGroup className="mb-5 mt-5">
                                <InputGroup.Text>Nom du Client</InputGroup.Text>
                                <Form.Control type="Texte" value={clientName} onChange={(event) => { setClientName(event.target.value) }} placeholder="nom du client" />
                            </InputGroup>

                            <p className='text-center mb-2 h5'>Signature Client ( Signez et Validez )</p>
                            <InputGroup>

                                <SignaturePad
                                    ref={sigCanvas}
                                    canvasProps={{
                                        className: "signatureCanvas"
                                    }}

                                />


                            </InputGroup>
                            <button onClick={clear} className="mb-5 mt-1 me-3">Effacer</button>
                            <button onClick={save} className="mb-5 mt-1">Valider</button>

                            <div className="mb-2">
                                <Button variant="primary" className="mt-3" type="submit">
                                    Enregistrer
                                </Button>
                            </div>
                        </Form>
                    </Container>

                    <div >

                    </div>{message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => {
                        if (message?.error) {
                            setMessage('')
                        } else {
                            window.location.reload()
                        }


                    }


                    }>{message?.msg}</Alert>)}

                </div>



            </div>

        </>
    )

}

export default RapportPassage

