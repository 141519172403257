import './newProduct.scss'
import { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import productDataService from '../../services/productsServices'

const NewProduct = ({ id, setProductId }) => {

    const [product, setProduct] = useState("")
    const [packing, setPacking ] = useState("")
    const [category, setCategory ] = useState("")
    

    const [message, setMessage ] = useState({error: false, msg:""})

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")
        if(product === "" || category === ""){
            setMessage({error: true, msg: "Le nom du produit et la catégorie sont requis"})
            return
        }
        const newProduct = {
            product: product,
            packing: packing,
            category: category
            
        }

        try {
            if(id !== undefined && id !== ""){
                await productDataService.updateProduct(id,newProduct)
                setProductId("")
                setMessage({error: false, msg: "Les modifications sont enregistrées"})
            }
            else {
                await productDataService.addProduct(newProduct)
                setMessage({error: false, msg: "Le nouvel article a été créé avec succès"})
            }
        } catch (err){
            setMessage({error: true, msg: err.message})
        }
        setProduct("")
        setPacking("")
        setCategory("")
        
    }
    
    const handleEdit = async () => {
        setMessage("")
        try{
            const docSnap = await productDataService.getProduct(id)
            setProduct(docSnap.data().product)
            setPacking(docSnap.data().packing)
            setCategory(docSnap.data().category)
            

        } catch (err){
            setMessage({error: true, msg: err.message})
        }
    }

    useEffect(() => {
        if(id !== undefined && id !== ""){
            handleEdit()
        }
    },[id])

    return (
        <div className="newProduct">
            <div className="newProductContainer">
                <div className="top">
                    <h1>Ajouter / Modifier un produit</h1>
                </div>
                <div className="p-2 h4 m-5 text-center">
                {message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>{message?.msg}</Alert>)}
                </div>
                <div className="body">
                    <form onSubmit={handleSubmit}>
                        <div className="formInput">
                            <label>Nom du produit</label>
                            <input type="text" value={product} onChange={(event) => {setProduct(event.target.value)}}/>
                        </div>

                        <div className="formInput">
                            <label>Conditionnement</label>
                            <input type="text" value={packing} onChange={(event) => {setPacking(event.target.value)}}/>
                        </div>

                        <div className="formInput">
                            <label>Catégorie</label>
                            <select value={category} onChange={(event) => {setCategory(event.target.value)}}>
                                <option>Choisir une catégorie</option>
                                <option>produit</option>
                                <option>petit matériel</option>
                                <option>sacs aspirateurs</option>
                                <option>papier</option>
                                
                            </select>
                        </div>


                        <button type="submit">Ajouter / Modifier</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewProduct