import './suiviQualiteSingle.scss'
import { Form, Button, Container, InputGroup, FormControl, Alert, FormGroup, FormLabel, ToggleButton } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuiviQualiteDataService from '../../../services/suiviQualiteServices'
import ClientDataService from '../../../services/clientsService'
import { useReactToPrint } from 'react-to-print'
import TextareaAutosize from '@mui/base/TextareaAutosize'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import CopyText from '../../../components/CopyText'

function SuiviQualiteSingle({ id, setRapportId }) {



    //Voir les clients pour dropdown "Choisir client"
    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }

    const [date, setDate] = useState('')
    const [inspector, setInspector] = useState('')
    const [client, setClient] = useState("")
    const [localisation, setLocalisation] = useState('')
    const [present, setPresent] = useState('')
    const [remarks, setRemarks] = useState('')
    const [action, setAction] = useState('')
    const [clientName, setClientName] = useState('')
    const [signature, setSignature] = useState('')
    const [message, setMessage] = useState({ error: false, msg: "" })
    const [checked, setChecked] = useState( '')

    //Accordeon - Hall d'accueil
    const [poubelleHall, setPoubelleHall] = useState(0)
    const [cendrierHall, setCendrierHall] = useState(0)
    const [portVitrHall, setPortVitrHall] = useState(0)
    const [mobilierHall, setMobilierHall] = useState(0)
    const [appuiHall, setAppuiHall] = useState(0)
    const [radiateurHall, setRadiateurHall] = useState(0)
    const [armoireHall, setArmoireHall] = useState(0)
    const [priseHall, setPriseHall] = useState(0)
    const [porteHall, setPorteHall] = useState(0)
    const [poussiereHall, setPoussiereHall] = useState(0)
    const [solHall, setSolHall] = useState(0)
    const [plintheHall, setPlintheHall] = useState(0)
    const [totalHall, setTotalHall] = useState(0)
    const [hallTotal, setHallTotal] = useState(0)

    //Accordeon Réception
    const [poubelleReception, setPoubelleReception] = useState(0)
    const [bureauReception, setBureauReception] = useState(0)
    const [phoneReception, setPhoneReception] = useState(0)
    const [objetReception, setObjetReception] = useState(0)
    const [siegeReception, setSiegeReception] = useState(0)
    const [appuiReception, setAppuiReception] = useState(0)
    const [radiateurReception, setRadiateurReception] = useState(0)
    const [armoireReception, setArmoireReception] = useState(0)
    const [priseReception, setPriseReception] = useState(0)
    const [porteReception, setPorteReception] = useState(0)
    const [poussiereReception, setPoussiereReception] = useState(0)
    const [solReception, setSolReception] = useState(0)
    const [plintheReception, setPlintheReception] = useState(0)
    const [totalReception, setTotalReception] = useState(0)
    const [receptionTotal, setReceptionTotal] = useState(0)

    //Accordeon Bureaux
    const [poubelleBureau, setPoubelleBureau] = useState(0)
    const [bureauBureau, setBureauBureau] = useState(0)
    const [phoneBureau, setPhoneBureau] = useState(0)
    const [objetBureau, setObjetBureau] = useState(0)
    const [siegeBureau, setSiegeBureau] = useState(0)
    const [appuiBureau, setAppuiBureau] = useState(0)
    const [radiateurBureau, setRadiateurBureau] = useState(0)
    const [armoireBureau, setArmoireBureau] = useState(0)
    const [priseBureau, setPriseBureau] = useState(0)
    const [porteBureau, setPorteBureau] = useState(0)
    const [poussiereBureau, setPoussiereBureau] = useState(0)
    const [solBureau, setSolBureau] = useState(0)
    const [plintheBureau, setPlintheBureau] = useState(0)
    const [totalBureaux, setTotalBureaux] = useState(0)
    const [bureauxTotal, setBureauxTotal] = useState(0)

    //Accordeon Sanitaires
    const [lavaboSanit, setLavaboSanit] = useState(0)
    const [robinetSanit, setRobinetSanit] = useState(0)
    const [miroirSanit, setMiroirSanit] = useState(0)
    const [distributeurSanit, setDistributeurSanit] = useState(0)
    const [poubelleSanit, setPoubelleSanit] = useState(0)
    const [wcSanit, setWcSanit] = useState(0)
    const [lunetteSanit, setLunetteSanit] = useState(0)
    const [urinoirSanit, setUrinoirSanit] = useState(0)
    const [doucheSanit, setDoucheSanit] = useState(0)
    const [cloisonSanit, setCloisonSanit] = useState(0)
    const [radiateurSanit, setRadiateurSanit] = useState(0)
    const [solSanit, setSolSanit] = useState(0)
    const [plintheSanit, setPlintheSanit] = useState(0)
    const [totalSanitaires, setTotalSanitaires] = useState(0)
    const [sanitairesTotal, setSanitairesTotal] = useState(0)

    //Accordeon Salles de Réunion 
    const [poubelleReunion, setPoubelleReunion] = useState(0)
    const [bureauReunion, setBureauReunion] = useState(0)
    const [phoneReunion, setPhoneReunion] = useState(0)
    const [objetReunion, setObjetReunion] = useState(0)
    const [siegeReunion, setSiegeReunion] = useState(0)
    const [appuiReunion, setAppuiReunion] = useState(0)
    const [radiateurReunion, setRadiateurReunion] = useState(0)
    const [armoireReunion, setArmoireReunion] = useState(0)
    const [priseReunion, setPriseReunion] = useState(0)
    const [porteReunion, setPorteReunion] = useState(0)
    const [poussiereReunion, setPoussiereReunion] = useState(0)
    const [solReunion, setSolReunion] = useState(0)
    const [plintheReunion, setPlintheReunion] = useState(0)
    const [totalReunion, setTotalReunion] = useState(0)
    const [reunionTotal, setReunionTotal] = useState(0)

    //Accordeon Douches / Vestiaires
    const [lavaboDouche, setLavaboDouche] = useState(0)
    const [robinetDouche, setRobinetDouche] = useState(0)
    const [miroirDouche, setMiroirDouche] = useState(0)
    const [distributeurDouche, setDistributeurDouche] = useState(0)
    const [poubelleDouche, setPoubelleDouche] = useState(0)
    const [wcDouche, setWcDouche] = useState(0)
    const [lunetteDouche, setLunetteDouche] = useState(0)
    const [urinoirDouche, setUrinoirDouche] = useState(0)
    const [doucheDouche, setDoucheDouche] = useState(0)
    const [cloisonDouche, setCloisonDouche] = useState(0)
    const [radiateurDouche, setRadiateurDouche] = useState(0)
    const [solDouche, setSolDouche] = useState(0)
    const [plintheDouche, setPlintheDouche] = useState(0)
    const [totalDouche, setTotalDouche] = useState(0)
    const [doucheTotal, setDoucheTotal] = useState(0)

    //Accordeon Réfectoire 
    const [solRefect, setSolRefect] = useState(0)
    const [bordRefect, setBordRefect] = useState(0)
    const [porteRefect, setPorteRefect] = useState(0)
    const [tableRefect, setTableRefect] = useState(0)
    const [chaiseRefect, setChaiseRefect] = useState(0)
    const [depoussierageRefect, setDepoussierageRefect] = useState(0)
    const [totalRefect, setTotalRefect] = useState(0)
    const [refectTotal, setRefectTotal] = useState(0)

    //Accordeon Cuisine 
    const [solCuisine, setSolCuisine] = useState(0)
    const [bordCuisine, setBordCuisine] = useState(0)
    const [porteCuisine, setPorteCuisine] = useState(0)
    const [tableCuisine, setTableCuisine] = useState(0)
    const [chaiseCuisine, setChaiseCuisine] = useState(0)
    const [depoussierageCuisine, setDepoussierageCuisine] = useState(0)
    const [totalCuisine, setTotalCuisine] = useState(0)
    const [cuisineTotal, setCuisineTotal] = useState(0)

    //Accordeon Circulations
    const [solCirculation, setSolCirculation] = useState(0)
    const [bordCirculation, setBordCirculation] = useState(0)
    const [porteCirculation, setPorteCirculation] = useState(0)
    const [cadreCirculation, setCadreCirculation] = useState(0)
    const [priseCirculation, setPriseCirculation] = useState(0)
    const [plintheCirculation, setPlintheCirculation] = useState(0)
    const [totalCirculation, setTotalCirculation] = useState(0)
    const [circulationsTotal, setCirculationTotal] = useState(0)

    //Accordeon Ascenseur
    const [solAscenseur, setSolAscenseur] = useState(0)
    const [bordAscenseur, setBordAscenseur] = useState(0)
    const [porteAscenseur, setPorteAscenseur] = useState(0)
    const [miroirAscenseur, setMiroirAscenseur] = useState(0)
    const [parIntAscenseur, setParIntAscenseur] = useState(0)
    const [parExtAscenseur, setParExtAscenseur] = useState(0)
    const [depoussierageAscenseur, setDepoussierageAscenseur] = useState(0)
    const [totalAscenseur, setTotalAscenseur] = useState(0)
    const [ascenseurTotal, setAscenseurTotal] = useState(0)

    //Accordeon Escaliers
    const [solEscalier, setSolEscalier] = useState(0)
    const [bordEscalier, setBordEscalier] = useState(0)
    const [porteEscalier, setPorteEscalier] = useState(0)
    const [cadreEscalier, setCadreEscalier] = useState(0)
    const [rampeEscalier, setRampeEscalier] = useState(0)
    const [totalEscalier, setTotalEscalier] = useState(0)
    const [escaliersTotal, setEscaliersTotal] = useState(0)

    //Accordeon Parking
    const [visuelParking, setVisuelParking] = useState(0)
    const [poubelleParking, setPoubelleParking] = useState(0)
    const [totalParking, setTotalParking] = useState(0)
    const [parkingTotal, setParkingTotal] = useState(0)

    //Accordeon Local de Nettoyage / Chariots / Produits
    const [local, setLocal] = useState(0)
    const [chariot, setChariot] = useState(0)
    const [stock, setStock] = useState(0)
    const [totalLocal, setTotalLocal] = useState(0)
    const [localTotal, setLocalTotal] = useState(0)

    //total Evaluation générale
    const [totalEval, setTotalEval] = useState(0)

    //non apllicable hall
    const [naPoubelleHall, setNaPoubelleHall] = useState(0)
    const [naCendrierHall, setNaCendrierHall] = useState(0)
    const [naPortVitrHall, setNaPortVitrHall] = useState(0)
    const [naMobilierHall, setNaMobilierHall] = useState(0)
    const [naAppuiHall, setNaAppuiHall] = useState(0)
    const [naRadiateurHall, setNaRadiateurHall] = useState(0)
    const [naArmoireHall, setNaArmoireHall] = useState(0)
    const [naPriseHall, setNaPriseHall] = useState(0)
    const [naPorteHall, setNaPorteHall] = useState(0)
    const [naPoussiereHall, setNaPoussiereHall] = useState(0)
    const [naSolHall, setNaSolHall] = useState(0)
    const [naPlintheHall, setNaPlintheHall] = useState(0)
    const [naHallTotal, setNaHallTotal] = useState(0)

    //handleHall functions 
    const handlePoubelleHall = (e) => {
        setNaPoubelleHall(0)
        setPoubelleHall(e.target.value)
    }

    const handleCendrierHall = (e) => {
        setNaCendrierHall(0)
        setCendrierHall(e.target.value)
    }

    const handlePortVitrHall = (e) => {
        setNaPortVitrHall(0)
        setPortVitrHall(e.target.value)
    } 

    const handleMobilierHall = (e) => {
        setNaMobilierHall(0)
        setMobilierHall(e.target.value)
    }

    const handleAppuiHall = (e) => {
        setNaAppuiHall(0)
        setAppuiHall(e.target.value)
    }

    const handleRadiateurHall = (e) => {
        setNaRadiateurHall(0)
        setRadiateurHall(e.target.value)
    }

    const handleArmoireHall = (e) => {
        setNaArmoireHall(0)
        setArmoireHall(e.target.value)
    }

    const handlePriseHall = (e) => {
        setNaPriseHall(0)
        setPriseHall(e.target.value)
    }

    const handlePorteHall = (e) => {
        setNaPorteHall(0)
        setPorteHall(e.target.value)
    }
    
    const handlePoussiereHall = (e) => {
        setNaPoussiereHall(0)
        setPoussiereHall(e.target.value)
    }

    const handleSolHall = (e) => {
        setNaSolHall(0)
        setSolHall(e.target.value)
    }

    const handlePlintheHall = (e) => {
        setNaPlintheHall(0)
        setPlintheHall(e.target.value)
    }

    //non applicable Reception
    const [naPoubelleRecept, setNaPoubelleRecept] = useState(0)
    const [naBureauRecept, setNaBureauRecept] = useState(0)
    const [naTelRecept, setNaTelRecept] = useState(0)
    const [naObjRecept, setNaObjRecept] = useState(0)
    const [naSiegeRecept, setNaSiegeRecept] = useState(0)
    const [naAppuiRecept, setNaAppuiRecept] = useState(0)
    const [naRadiateurRecept, setNaRadiateurRecept] = useState(0)
    const [naArmoireRecept, setNaArmoireRecept] = useState(0)
    const [naPriseRecept, setNaPriseRecept] = useState(0)
    const [naPorteRecept, setNaPorteRecept] = useState(0)
    const [naPoussiereRecept, setNaPoussiereRecept] = useState(0)
    const [naSolRecept, setNaSolRecept] = useState(0)
    const [naPlintheRecept, setNaPlintheRecept] = useState(0)
    const [naReceptTotal, setNaReceptTotal] = useState(0)

    //handleReception functions
    const handlePoubelleRecept = (e) => {
        setNaPoubelleRecept(0)
        setPoubelleReception(e.target.value)
    }

    const handleBureauRecept = (e) => {
        setNaBureauRecept(0)
        setBureauReception(e.target.value)
    }

    const handleTelRecept = (e) => {
        setNaTelRecept(0)
        setPhoneReception(e.target.value)
    }

    const handleObjRecept = (e) => {
        setNaObjRecept(0)
        setObjetReception(e.target.value)
    }

    const handleSiegeRecept = (e) => {
        setNaSiegeRecept(0)
        setSiegeReception(e.target.value)
    }

    const handleAppuiRecept = (e) => {
        setNaAppuiRecept(0)
        setAppuiReception(e.target.value)
    }

    const handleRadiateurRecept = (e) => {
        setNaRadiateurRecept(0)
        setRadiateurReception(e.target.value)
    }

    const handleArmoireRecept = (e) => {
        setNaArmoireRecept(0)
        setArmoireReception(e.target.value)
    }

    const handlePriseRecept = (e) => {
        setNaPriseRecept(0)
        setPriseReception(e.target.value)
    }

    const handlePorteRecept = (e) => {
        setNaPorteRecept(0)
        setPorteReception(e.target.value)
    }

    const handlePoussiereRecept = (e) => {
        setNaPoussiereRecept(0)
        setPoussiereReception(e.target.value)
    }

    const handleSolRecept = (e) => {
        setNaSolRecept(0)
        setSolReception(e.target.value)
    }

    const handlePlintheRecept = (e) => {
        setNaPlintheRecept(0)
        setPlintheReception(e.target.value)
    }


    //non applicable Bureaux
    const [naPoubelleBureau, setNaPoubelleBureau] = useState(0)
    const [naBureauBureau, setNaBureauBureau] = useState(0)
    const [naPhoneBureau, setNaPhoneBureau] = useState(0)
    const [naObjBureau, setNaObjBureau] = useState(0)
    const [naSiegeBureau, setNaSiegeBureau] = useState(0)
    const [naAppuiBureau, setNaAppuiBureau] = useState(0)
    const [naRadiateurBureau, setNaRadiateurBureau] = useState(0)
    const [naArmoireBureau, setNaArmoireBureau] = useState(0)
    const [naPriseBureau, setNaPriseBureau] = useState(0)
    const [naPorteBureau, setNaPorteBureau] = useState(0)
    const [naPoussiereBureau,setNaPoussiereBureau] = useState(0)
    const [naSolBureau, setNaSolBureau] = useState(0)
    const [naPlinthebureau, setNaPlintheBureau] = useState(0)
    const [naBureauTotal, setNaBureauTotal] = useState(0)

    //handleBureau functions
    const handlePoubelleBureau = (e) => {
        setNaPoubelleBureau(0)
        setPoubelleBureau(e.target.value)
    }

    const handleBureauBureau = (e) => {
        setNaBureauBureau(0)
        setBureauBureau(e.target.value)
    }

    const handlePhoneBureau = (e) => {
        setNaPhoneBureau(0)
        setPhoneBureau(e.target.value)
    }

    const handleObjBureau = (e) => {
        setNaObjBureau(0)
        setObjetBureau(e.target.value)
    }

    const handleSiegeBureau = (e) => {
        setNaSiegeBureau(0)
        setSiegeBureau(e.target.value)
    }

    const handleAppuiBureau = (e) => {
        setNaAppuiBureau(0)
        setAppuiBureau(e.target.value)
    }

    const handleRadiateurBureau = (e) => {
        setNaRadiateurBureau(0)
        setRadiateurBureau(e.target.value)
    }

    const handleArmoireBureau = (e) => {
        setNaArmoireBureau(0)
        setArmoireBureau(e.target.value)
    }

    const handlePriseBureau = (e) => {
        setNaPriseBureau(0)
        setPriseBureau(e.target.value)
    }

    const handlePorteBureau = (e) => {
        setNaPorteBureau(0)
        setPorteBureau(e.target.value)
    }

    const handlePoussiereBureau = (e) => {
        setNaPoussiereBureau(0)
        setPoussiereBureau(e.target.value)
    }

    const handleSolBureau = (e) => {
        setNaSolBureau(0)
        setSolBureau(e.target.value)
    }

    const handlePlintheBureau = (e) => {
        setNaPlintheBureau(0)
        setPlintheBureau(e.target.value)
    }

    //non applicable Sanitaires
    const [naLavabSanit, setNaLavabSanit] = useState(0)
    const [naRobinSanit, setNaRobinSanit] = useState(0)
    const [naMiroirSanit, setNaMiroirSanit] = useState(0)
    const [naDistrSanit, setNaDistrSanit] = useState(0)
    const [naPoubelleSanit, setNaPoubelleSanit] = useState(0)
    const [naWcSanit, setNaWcSanit] = useState(0)
    const [naLunSanit, setNaLunSanit] = useState(0)
    const [naUrinoirSanit, setNaUrinoirSanit] = useState(0)
    const [naDoucheSanit, setNaDoucheSanit] = useState(0)
    const [naCloisonSanit, setNaCloisonSanit] = useState(0)
    const [naRadiateurSanit, setNaRaditeurSanit] = useState(0)
    const [naSolSanit, setNaSolSanit] = useState(0)
    const [naPlintheSanit, setNaPlintheSanit] = useState(0)
    const [naSanitTotal, setNaSanitTotal] = useState(0)

    //handleSanitaires functions
    const handleLavabSanit = (e) => {
        setNaLavabSanit(0)
        setLavaboSanit(e.target.value)
    }

    const handleRobinSanit = (e) => {
        setNaRobinSanit(0)
        setRobinetSanit(e.target.value)
    }

    const handleMiroirSanit = (e) => {
        setNaMiroirSanit(0)
        setMiroirSanit(e.target.value)
    }

    const handleDistrSanit = (e) => {
        setNaDistrSanit(0)
        setDistributeurSanit(e.target.value)
    }

    const handlePoubelleSanit = (e) => {
        setNaPoubelleSanit(0)
        setPoubelleSanit(e.target.value)
    }

    const handleWcSanit = (e) => {
        setNaWcSanit(0)
        setWcSanit(e.target.value)
    }

    const handleLunSanit = (e) => {
        setNaLunSanit(0)
        setLunetteSanit(e.target.value)
    }

    const handleUrinoirSanit = (e) => {
        setNaUrinoirSanit(0)
        setUrinoirSanit(e.target.value)
    }

    const handleDoucheSanit = (e) => {
        setNaDoucheSanit(0)
        setDoucheSanit(e.target.value)
    }

    const handleCloisonSanit = (e) => {
        setNaCloisonSanit(0)
        setCloisonSanit(e.target.value)
    }

    const handleRadiateurSanit = (e) => {
        setNaRaditeurSanit(0)
        setRadiateurSanit(e.target.value)
    }

    const handleSolSanit = (e) => {
        setNaSolSanit(0)
        setSolSanit(e.target.value)
    }

    const handlePlintheSanit = (e) => {
        setNaPlintheSanit(0)
        setPlintheSanit(e.target.value)
    }

    //non applicable Reunion
    const [naPoubelleReunion, setNaPoubelleReunion] = useState(0)
    const [naBureauReunion, setNaBureauReunion] = useState(0)
    const [naPhoneReunion, setNaPhoneReunion] = useState(0)
    const [naObjReunion, setNaObjReunion] = useState(0)
    const [naSiegeReunion, setNaSiegeReunion] = useState(0)
    const [naAppuiReunion, setNaAppuiReunion] = useState(0)
    const [naRadiateurReunion, setNaRadiateurReunion] = useState(0)
    const [naArmoireReunion, setNaArmoireReunion] = useState(0)
    const [naPriseReunion, setNaPriseReunion] = useState(0)
    const [naPorteReunion, setNaPorteReunion] = useState(0)
    const [naPoussiereReunion, setNaPoussiereReunion] = useState(0)
    const [naSolReunion, setNaSolReunion] = useState(0)
    const [naPlintheReunion, setNaPlintheReunion] = useState(0)
    const [naReunionTotal, setNaReunionTotal] = useState(0)

    //handleReunion functions
    const handlePoubelleReunion = (e) => {
        setNaPoubelleReunion(0)
        setPoubelleReunion(e.target.value)
    }

    const handleBureauReunion = (e) => {
        setNaBureauReunion(0)
        setBureauReunion(e.target.value)
    }

    const handlePhoneReunion = (e) => {
        setNaPhoneReunion(0)
        setPhoneReunion(e.target.value)
    }

    const handleObjReunion = (e) => {
        setNaObjReunion(0)
        setObjetReunion(e.target.value)
    }

    const handleSiegeReunion = (e) => {
        setNaSiegeReunion(0)
        setSiegeReunion(e.target.value)
    }

    const handleAppuiReunion = (e) => {
        setNaAppuiReunion(0)
        setAppuiReunion(e.target.value)
    }

    const handleRadiateurReunion = (e) => {
        setNaRadiateurReunion(0)
        setRadiateurReunion(e.target.value)
    }

    const handleArmoirReunion = (e) => {
        setNaArmoireReunion(0)
        setArmoireReunion(e.target.value)
    }

    const handlePriseReunion = (e) => {
        setNaPriseReunion(0)
        setPriseReunion(e.target.value)
    }

    const handlePorteReunion = (e) => {
        setNaPorteReunion(0)
        setPorteReunion(e.target.value)
    }

    const handlePoussiereReunion = (e) => {
        setNaPoussiereReunion(0)
        setPoussiereReunion(e.target.value)
    }

    const handleSolReunion = (e) => {
        setNaSolReunion(0)
        setSolReunion(e.target.value)
    }

    const handlePlintheReunion = (e) => {
        setNaPlintheReunion(0)
        setPlintheReunion(e.target.value)
    }

    //non applicable Douches
    const [naLavabDouche, setNaLavabDouche] = useState(0)
    const [naRobinDouche, setNaRobinDouche] = useState(0)
    const [naMiroirDouche,setNaMiroirDouche] = useState(0)
    const [naDistrDouche, setNaDistrDouche] = useState(0)
    const [naPoubelleDouche, setNaPoubelleDouche] = useState(0)
    const [naWcDouche, setNaWcDouche] = useState(0)
    const [naLunDouche, setNaLunDouche] = useState(0)
    const [naUrinoirDouche, setNaUrinoirDouche] = useState(0)
    const [naDoucheDouche, setNaDoucheDouche] = useState(0)
    const [naCloisonDouche, setNaCloisonDouche] = useState(0)
    const [naRadiateurDouche, setNaRadiateurDouche] = useState(0)
    const [naSolDouche, setNaSolDouche] = useState(0)
    const [naPlintheDouche, setNaPlintheDouche] = useState(0)
    const [naDoucheTotal, setNaDoucheTotal] = useState(0)

    //handleDouche functions
    const handleLavabDouche = (e) => {
        setNaLavabDouche(0)
        setLavaboDouche(e.target.value)
    }

    const handleRobinDouche = (e) => {
        setNaRobinDouche(0)
        setRobinetDouche(e.target.value)
    }

    const handleMiroirDouche = (e) => {
        setNaMiroirDouche(0)
        setMiroirDouche(e.target.value)
    }

    const handleDistrDouche = (e) => {
        setNaDistrDouche(0)
        setDistributeurDouche(e.target.value)
    }

    const handlePoubelleDouche = (e) => {
        setNaPoubelleDouche(0)
        setPoubelleDouche(e.target.value)
    }

    const handleWcDouche = (e) => {
        setNaWcDouche(0)
        setWcDouche(e.target.value)
    }

    const handleLunDouche = (e) => {
        setNaLunDouche(0)
        setLunetteDouche(e.target.value)
    }

    const handleUrinoirDouche = (e) => {
        setNaUrinoirDouche(0)
        setUrinoirDouche(e.target.value)
    }

    const handleDoucheDouche = (e) => {
        setNaDoucheDouche(0)
        setDoucheDouche(e.target.value)
    }

    const handleCloisonDouche = (e) => {
        setNaCloisonDouche(0)
        setCloisonDouche(e.target.value)
    }

    const handleRadiateurDouche = (e) => {
        setNaRadiateurDouche(0)
        setRadiateurDouche(e.target.value)
    }

    const handleSolDouche = (e) => {
        setNaSolDouche(0)
        setSolDouche(e.target.value)
    }

    const handlePlintheDouche = (e) => {
        setNaPlintheDouche(0)
        setPlintheDouche(e.target.value)
    }

    //non applicable Refectoire
    const [naSolRefect, setNaSolRefect] = useState(0)
    const [naBordRefect, setNaBordRefect] = useState(0)
    const [naPorteRefect, setNaPorteRefect] = useState(0)
    const [naTableRefect, setNaTableRefect] = useState(0)
    const [naChaiseRefect, setNaChaiseRefect] = useState(0)
    const [naDepoussRefect, setNaDepoussRefect] = useState(0)
    const [naRefectTotal, setNaRefectTotal] = useState(0)

    //handleRefectoire funcions
    const handleSolRefect = (e) => {
        setNaSolRefect(0)
        setSolRefect(e.target.value)
    }

    const handleBordRefect = (e) => {
        setNaBordRefect(0)
        setBordRefect(e.target.value)
    }

    const handlePorteRefect = (e) => {
        setNaPorteRefect(0)
        setPorteRefect(e.target.value)
    }

    const handleTableRefect = (e) => {
        setNaTableRefect(0)
        setTableRefect(e.target.value)
    }

    const handleChaiseRefect = (e) => {
        setNaChaiseRefect(0)
        setChaiseRefect(e.target.value)
    }

    const handleDepoussRefect = (e) => {
        setNaDepoussRefect(0)
        setDepoussierageRefect(e.target.value)
    }


    //non applicable Cuisine
    const [naSolCuisine, setNaSolCuisine] = useState(0)
    const [naBordCuisine, setNaBordCuisine] = useState(0)
    const [naPorteCuisine, setNaPorteCuisine] = useState(0)
    const [naTableCuisine, setNaTableCuisine] = useState(0)
    const [naChaiseCuisine, setNaChaiseCuisine] = useState(0)
    const [naDepoussCuisine, setNaDepoussCuisine] = useState(0)
    const [naCuisineTotal, setNaCuisineTotal] = useState(0)

    //handleCuisine functions
    const handleSolCuisine = (e) => {
        setNaSolCuisine(0)
        setSolCuisine(e.target.value)
    }

    const handleBordCuisine = (e) => {
        setNaBordCuisine(0)
        setBordCuisine(e.target.value)
    }

    const handlePorteCuisine = (e) => {
        setNaPorteCuisine(0)
        setPorteCuisine(e.target.value)
    }

    const handleTableCuisine = (e) => {
        setNaTableCuisine(0)
        setTableCuisine(e.target.value)
    }

    const handleChaiseCuisine = (e) => {
        setNaChaiseCuisine(0)
        setChaiseCuisine(e.target.value)
    }

    const handleDepoussCuisine = (e) => {
        setNaDepoussCuisine(0)
        setDepoussierageCuisine(e.target.value)
    }

    //non applicable Circulations
    const [naSolCircul, setNaSolCircul] = useState(0)
    const [naBordCircul, setNaBordCircul] = useState(0)
    const [naPorteCircul, setNaPorteCircul] = useState(0)
    const [naCadreCircul, setNaCadreCircul] = useState(0)
    const [naPriseCircul, setNaPriseCircul] = useState(0)
    const [naPlintheCircul, setNaPlintheCircul] = useState(0)
    const [naCirculTotal, setNaCirculTotal] = useState(0)

    //handleCirculations functions
    const handleSolCircul = (e) => {
        setNaSolCircul(0)
        setSolCirculation(e.target.value)
    }

    const handleBordCircul = (e) => {
        setNaBordCircul(0)
        setBordCirculation(e.target.value)
    }

    const handlePorteCircul = (e) => {
        setNaPorteCircul(0)
        setPorteCirculation(e.target.value)
    }

    const handleCadreCircul = (e) => {
        setNaCadreCircul(0)
        setCadreCirculation(e.target.value)
    }

    const handlePriseCircul = (e) => {
        setNaPriseCircul(0)
        setPriseCirculation(e.target.value)
    }

    const handlePlintheCircul = (e) => {
        setNaPlintheCircul(0)
        setPlintheCirculation(e.target.value)
    }

    //non applicable Ascenseur
    const [naSolAscens, setNaSolAscens] = useState(0)
    const [naBordAscens, setNaBordAscens] = useState(0)
    const [naPorteAscens, setNaPorteAscens] = useState(0)
    const [naMiroirAscens, setNaMiroirAscens] = useState(0)
    const [naParoiIntAscens, setNaParoiIntAscens] = useState(0)
    const [naParoiExtAscens, setNaParoiExtAscens] = useState(0)
    const [naDepoussAscens, setNaDepoussAscens] = useState(0)
    const [naAscenseurTotal, setNaAscenseurTotal] = useState(0)

    //handleAscenseur functions
    const handleSolAscenseur = (e) => {
        setNaSolAscens(0)
        setSolAscenseur(e.target.value)
    }

    const handleBordAscenseur = (e) => {
        setNaBordAscens(0)
        setBordAscenseur(e.target.value)
    }

    const handlePorteAscenseur = (e) => {
        setNaPorteAscens(0)
        setPorteAscenseur(e.target.value)
    }

    const handleMiroirAscenseur = (e) => {
        setNaMiroirAscens(0)
        setMiroirAscenseur(e.target.value)
    }

    const handleParoiIntAscenseur = (e) => {
        setNaParoiIntAscens(0)
        setParIntAscenseur(e.target.value)
    }

    const handleParoiExtAscenseur = (e) => {
        setNaParoiExtAscens(0)
        setParExtAscenseur(e.target.value)
    }

    const handleDepoussAscenseur = (e) => {
        setNaDepoussAscens(0)
        setDepoussierageAscenseur(e.target.value)
    }


    //non applicable Escaliers
    const [naSolEscal, setNaSolEscal] = useState(0)
    const [naBordEscal, setNaBordEscal] = useState(0)
    const [naPorteEscal, setNaPorteEscal] = useState(0)
    const [naCadreEscal, setNaCadreEscal] = useState(0)
    const [naRampeEscal, setNaRampeEscal] = useState(0)
    const [naEscalTotal, setNaEscalTotal] = useState(0)

    //handleEscaliers functions
    const handleSolEscal = (e) => {
        setNaSolEscal(0)
        setSolEscalier(e.target.value)
    }

    const handleBordEscal = (e) => {
        setNaBordEscal(0)
        setBordEscalier(e.target.value)
    }

    const handlePorteEscal = (e) => {
        setNaPorteEscal(0)
        setPorteEscalier(e.target.value)
    }

    const handleCadreEscal = (e) => {
        setNaCadreEscal(0)
        setCadreEscalier(e.target.value)
    }

    const handleRampeEscal = (e) => {
        setNaRampeEscal(0)
        setRampeEscalier(e.target.value)
    }


    //non applicable Parking
    const [naVisuelParking, setNaVisuelParking] = useState(0)
    const [naPoubelleParking, setNaPoubelleParking] = useState(0)
    const [naParkingTotal, setNaParkingTotal] = useState(0)

    //handleParking functions
    const handleVisuelParking = (e) => {
        setNaVisuelParking(0)
        setVisuelParking(e.target.value)
    }

    const handlePoubelleParking = (e) => {
        setNaPoubelleParking(0)
        setPoubelleParking(e.target.value)
    }

    //non applicable Local
    const [naLocalLocal, setNaLocalLocal] = useState(0)
    const [naChariotLocal, setNaChariotLocal] = useState(0)
    const [naStockLocal, setNaStockLocal] = useState(0)
    const [naLocalTotal, setNaLocalTotal] = useState(0)

    //handleLocal functions
    const handleLocal = (e) => {
        setNaLocalLocal(0)
        setLocal(e.target.value)
    }

    const handleChariot = (e) => {
        setNaChariotLocal(0)
        setChariot(e.target.value)
    }

    const handleStock = (e) => {
        setNaStockLocal(0)
        setStock(e.target.value)
    }

    //total Hall
    useEffect(() => {
        setTotalHall(parseInt(poubelleHall) + parseInt(cendrierHall) + parseInt(portVitrHall) + parseInt(mobilierHall) + parseInt(appuiHall) + parseInt(radiateurHall) + parseInt(armoireHall) + parseInt(priseHall) + parseInt(porteHall) + parseInt(poussiereHall) + parseInt(solHall) + parseInt(plintheHall))
    }, [poubelleHall, cendrierHall, portVitrHall, mobilierHall, appuiHall, radiateurHall, armoireHall, priseHall, porteHall, poussiereHall, solHall, plintheHall])

    
    const naHallTot = naPoubelleHall + naCendrierHall + naPortVitrHall + naMobilierHall + naAppuiHall + naRadiateurHall + naArmoireHall + naPriseHall + naPorteHall + naPoussiereHall + naSolHall + naPlintheHall

    
    useEffect(() => {
        setHallTotal(60 - naHallTot)
    },[naHallTot]) 
    

    //total Réception
    useEffect(() => {
        setTotalReception(parseInt(poubelleReception) + parseInt(bureauReception) + parseInt(phoneReception) + parseInt(objetReception) + parseInt(siegeReception) + parseInt(appuiReception) + parseInt(radiateurReception) + parseInt(armoireReception) + parseInt(priseReception) + parseInt(porteReception) + parseInt(poussiereReception) + parseInt(solReception) + parseInt(plintheReception))
    }, [poubelleReception, bureauReception, phoneReception, objetReception, siegeReception, appuiReception, radiateurReception, armoireReception, priseReception, porteReception, poussiereReception, solReception, plintheReception])


    const naReceptTot = naPoubelleRecept + naBureauRecept + naTelRecept + naObjRecept + naSiegeRecept + naAppuiRecept + naRadiateurRecept + naArmoireRecept + naPriseRecept + naPorteRecept + naPoussiereRecept + naSolRecept + naPlintheRecept

    
    useEffect(() => {
        setReceptionTotal(65 - naReceptTot)
    },[naReceptTot])

    //total Bureaux  
    useEffect(() => {
        setTotalBureaux(parseInt(poubelleBureau) + parseInt(bureauBureau) + parseInt(phoneBureau) + parseInt(objetBureau) + parseInt(siegeBureau) + parseInt(appuiBureau) + parseInt(radiateurBureau) + parseInt(armoireBureau) + parseInt(priseBureau) + parseInt(porteBureau) + parseInt(poussiereBureau) + parseInt(solBureau) + parseInt(plintheBureau))
    }, [poubelleBureau, bureauBureau, phoneBureau, objetBureau, siegeBureau, appuiBureau, radiateurBureau, armoireBureau, priseBureau, porteBureau, poussiereBureau, solBureau, plintheBureau])

    const naBureauTot = naPoubelleBureau + naBureauBureau + naPhoneBureau + naObjBureau + naSiegeBureau + naAppuiBureau + naRadiateurBureau + naArmoireBureau + naPriseBureau + naPorteBureau + naPoussiereBureau + naSolBureau + naPlinthebureau 

    useEffect(() => {
        setBureauxTotal(65 - naBureauTot)
    },[naBureauTot])

    //total Sanitaires 
    useEffect(() => {
        setTotalSanitaires(parseInt(lavaboSanit) + parseInt(robinetSanit) + parseInt(miroirSanit) + parseInt(distributeurSanit) + parseInt(poubelleSanit) + parseInt(wcSanit) + parseInt(lunetteSanit) + parseInt(urinoirSanit) + parseInt(doucheSanit) + parseInt(cloisonSanit) + parseInt(radiateurSanit) + parseInt(solSanit) + parseInt(plintheSanit))
    }, [lavaboSanit, robinetSanit, miroirSanit, distributeurSanit, poubelleSanit, wcSanit, lunetteSanit, urinoirSanit, doucheSanit, cloisonSanit, radiateurSanit, solSanit, plintheSanit])

    const naSanitTot = naLavabSanit + naRobinSanit + naMiroirSanit + naDistrSanit + naPoubelleSanit + naWcSanit + naLunSanit + naUrinoirSanit + naDoucheSanit + naCloisonSanit + naRadiateurSanit + naSolSanit + naPlintheSanit 

    useEffect(() => {
        setSanitairesTotal(65 - naSanitTot)
    },[naSanitTot])

    //total Salles de Réunion 
    useEffect(() => {
        setTotalReunion(parseInt(poubelleReunion) + parseInt(bureauReunion) + parseInt(phoneReunion) + parseInt(objetReunion) + parseInt(siegeReunion) + parseInt(appuiReunion) + parseInt(radiateurReunion) + parseInt(armoireReunion) + parseInt(priseReunion) + parseInt(porteReunion) + parseInt(poussiereReunion) + parseInt(solReunion) + parseInt(plintheReunion))
    }, [poubelleReunion, bureauReunion, phoneReunion, objetReunion, siegeReunion, appuiReunion, radiateurReunion, armoireReunion, priseReunion, porteReunion, poussiereReunion, solReunion, plintheReunion])

    const naReunionTot = naPoubelleReunion + naBureauReunion + naPhoneReunion + naObjReunion + naSiegeReunion + naAppuiReunion + naRadiateurReunion + naArmoireReunion + naPriseReunion + naPorteReunion + naPoussiereReunion + naSolReunion + naPlintheReunion 

    useEffect(() => {
        setReunionTotal(65 - naReunionTot)
    },[naReunionTot])

    //total Douches/Vestiaires
    useEffect(() => {
        setTotalDouche(parseInt(lavaboDouche) + parseInt(robinetDouche) + parseInt(miroirDouche) + parseInt(distributeurDouche) + parseInt(poubelleDouche) + parseInt(wcDouche) + parseInt(lunetteDouche) + parseInt(urinoirDouche) + parseInt(doucheDouche) + parseInt(cloisonDouche) + parseInt(radiateurDouche) + parseInt(solDouche) + parseInt(plintheDouche))
    }, [lavaboDouche, robinetDouche, miroirDouche, distributeurDouche, poubelleDouche, wcDouche, lunetteDouche, urinoirDouche, doucheDouche, cloisonDouche, radiateurDouche, solDouche, plintheDouche])

    const naDoucheTot = naLavabDouche + naRobinDouche + naMiroirDouche + naDistrDouche + naPoubelleDouche + naWcDouche + naLunDouche + naUrinoirDouche + naDoucheDouche + naCloisonDouche + naRadiateurDouche + naSolDouche + naPlintheDouche 

    useEffect(() => {
        setDoucheTotal(65 - naDoucheTot)
    },[naDoucheTot])

    //total Réfectoire 
    useEffect(() => {
        setTotalRefect(parseInt(solRefect) + parseInt(bordRefect) + parseInt(porteRefect) + parseInt(tableRefect) + parseInt(chaiseRefect) + parseInt(depoussierageRefect))
    }, [solRefect, bordRefect, porteRefect, tableRefect, chaiseRefect, depoussierageRefect])

    const naRefectTot = naSolRefect + naBordRefect + naPorteRefect + naTableRefect + naChaiseRefect + naDepoussRefect
 
    useEffect(() => {
        setRefectTotal(30 - naRefectTot)
    },[naRefectTot])

    //total Cuisine 
    useEffect(() => {
        setTotalCuisine(parseInt(solCuisine) + parseInt(bordCuisine) + parseInt(porteCuisine) + parseInt(tableCuisine) + parseInt(chaiseCuisine) + parseInt(depoussierageCuisine))
    }, [solCuisine, bordCuisine, porteCuisine, tableCuisine, chaiseCuisine, depoussierageCuisine])

    const naCuisineTot = naSolCuisine + naBordCuisine + naPorteCuisine + naTableCuisine + naChaiseCuisine + naDepoussCuisine
 
    useEffect(() => {
        setCuisineTotal(30 - naCuisineTot)
    },[naCuisineTot])

    //total Circulations 
    useEffect(() => {
        setTotalCirculation(parseInt(solCirculation) + parseInt(bordCirculation) + parseInt(porteCirculation) + parseInt(cadreCirculation) + parseInt(priseCirculation) + parseInt(plintheCirculation))
    }, [solCirculation, bordCirculation, porteCirculation, cadreCirculation, priseCirculation, plintheCirculation])

    const naCirculTot = naSolCircul + naBordCircul + naPorteCircul + naCadreCircul + naPriseCircul + naPlintheCircul

    useEffect(() => {
        setCirculationTotal (30 - naCirculTot)
    },[naCirculTot])

    //total Ascenseur 
    useEffect(() => {
        setTotalAscenseur(parseInt(solAscenseur) + parseInt(bordAscenseur) + parseInt(porteAscenseur) + parseInt(miroirAscenseur) + parseInt(parIntAscenseur) + parseInt(parExtAscenseur) + parseInt(depoussierageAscenseur))
    }, [solAscenseur, bordAscenseur, porteAscenseur, miroirAscenseur, parIntAscenseur, parExtAscenseur, depoussierageAscenseur])

    const naAscenseurTot = naSolAscens + naBordAscens + naPorteAscens + naMiroirAscens + naParoiIntAscens + naParoiExtAscens + naDepoussAscens 

   useEffect(() => {
        setAscenseurTotal(35 - naAscenseurTot)
   },[naAscenseurTot])

    //total Escaliers
    useEffect(() => {
        setTotalEscalier(parseInt(solEscalier) + parseInt(bordEscalier) + parseInt(porteEscalier) + parseInt(cadreEscalier) + parseInt(rampeEscalier))
    }, [solEscalier, bordEscalier, porteEscalier, cadreEscalier, rampeEscalier])

    const naEscalTot = naSolEscal + naBordEscal + naPorteEscal + naCadreEscal + naRampeEscal 

    useEffect(() => {
        setEscaliersTotal(25 - naEscalTot)
    },[naEscalTot])

    //total Parking 
    useEffect(() => {
        setTotalParking(parseInt(visuelParking) + parseInt(poubelleParking))
    }, [visuelParking, poubelleParking])

    const naParkingTot = naVisuelParking + naPoubelleParking 

    useEffect(() => {
        setParkingTotal(10 - naParkingTot)
    },[naParkingTot])

    //total Local de Nettoyage / Chariots / Produits
    useEffect(() => {
        setTotalLocal(parseInt(local) + parseInt(chariot) + parseInt(stock))
    }, [local, chariot, stock])

    const naLocalTot = naLocalLocal + naChariotLocal + naStockLocal

    useEffect(() => {
        setLocalTotal(15 - naLocalTot)
    },[naLocalTot])

    //total Evaluation en %
    useEffect(() => {
        setTotalEval(Math.round((totalHall + totalReception + totalBureaux + totalSanitaires + totalReunion + totalDouche + totalRefect + totalCuisine + totalCirculation + totalAscenseur + totalEscalier + totalParking + totalLocal) / (560 - naHallTot - naReceptTot - naBureauTot - naSanitTot - naReunionTot - naDoucheTot - naRefectTot - naCuisineTot - naCirculTot - naAscenseurTot - naEscalTot - naParkingTot - naLocalTot) * 100))
    }, [totalHall, totalReception, totalBureaux, totalSanitaires, totalReunion, totalDouche, totalRefect, totalCuisine, totalCirculation, totalAscenseur, totalEscalier, totalParking, totalLocal,naHallTot,naReceptTot,naBureauTot,naSanitTot,naReunionTot,naDoucheTot,naRefectTot,naCuisineTot,naCirculTot, naAscenseurTot,naEscalTot,naParkingTot, naLocalTot])

    const perc = '%'

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")

        const newSuiviQualite = {
            date: date,
            inspector: inspector,
            client: client,
            localisation: localisation,
            present: present,
            remarks: remarks,
            action: action,
            clientName: clientName,
            signature: signature,
            checked: checked === undefined ? '' : 'Vérifié' ,
            //Hall
            poubelleHall: poubelleHall,
            cendrierHall: cendrierHall,
            portVitrHall: portVitrHall,
            mobilierHall: mobilierHall,
            appuiHall: appuiHall,
            radiateurHall: radiateurHall,
            armoireHall: armoireHall,
            priseHall: priseHall,
            porteHall: porteHall,
            poussiereHall: poussiereHall,
            solHall: solHall,
            plintheHall: plintheHall,
            totalHall: totalHall,
            hallTotal: hallTotal,
            naHallTotal: naHallTot,
            naPoubelleHall: naPoubelleHall,
            naCendrierHall: naCendrierHall,
            naPortVitrHall: naPortVitrHall,
            naMobilierHall: naMobilierHall,
            naAppuiHall: naAppuiHall,
            naRadiateurHall: naRadiateurHall,
            naArmoireHall: naArmoireHall,
            naPriseHall: naPriseHall,
            naPorteHall: naPorteHall,
            naPoussiereHall: naPoussiereHall,
            naSolHall: naSolHall,
            naPlintheHall: naPlintheHall,
            //Réception
            poubelleReception: poubelleReception,
            bureauReception: bureauReception,
            phoneReception: phoneReception,
            objetReception: objetReception,
            siegeReception: siegeReception,
            appuiReception: appuiReception,
            radiateurReception: radiateurReception,
            armoireReception: armoireReception,
            priseReception: priseReception,
            porteReception: porteReception,
            poussiereReception: poussiereReception,
            solReception: solReception,
            plintheReception: plintheReception,
            totalReception: totalReception,
            receptionTotal: receptionTotal,
            naReceptTotal: naReceptTot,
            naPoubelleRecept: naPoubelleRecept,
            naBureauRecept: naBureauRecept,
            naTelRecept: naTelRecept,
            naObjRecept: naObjRecept,
            naSiegeRecept: naSiegeRecept,
            naAppuiRecept: naAppuiRecept,
            naRadiateurRecept: naRadiateurRecept,
            naArmoireRecept: naArmoireRecept,
            naPriseRecept: naPriseRecept,
            naPorteRecept: naPorteRecept,
            naPoussiereRecept: naPoussiereRecept,
            naSolRecept: naSolRecept,
            naPlintheRecept: naPlintheRecept,
            //Bureaux
            poubelleBureau: poubelleBureau,
            bureauBureau: bureauBureau,
            phoneBureau: phoneBureau,
            objetBureau: objetBureau,
            siegeBureau: siegeBureau,
            appuiBureau: appuiBureau,
            radiateurBureau: radiateurBureau,
            armoireBureau: armoireBureau,
            priseBureau: priseBureau,
            porteBureau: porteBureau,
            poussiereBureau: poussiereBureau,
            solBureau: solBureau,
            plintheBureau: plintheBureau,
            totalBureaux: totalBureaux,
            bureauxTotal: bureauxTotal,
            naBureauTotal: naBureauTot,
            naPoubelleBureau: naPoubelleBureau,
            naBureauBureau: naBureauBureau,
            naPhoneBureau: naPhoneBureau,
            naObjBureau: naObjBureau,
            naSiegeBureau: naSiegeBureau,
            naAppuiBureau: naAppuiBureau,
            naRadiateurBureau: naRadiateurBureau,
            naArmoireBureau: naArmoireBureau,
            naPriseBureau: naPriseBureau,
            naPorteBureau: naPorteBureau,
            naPoussiereBureau: naPoussiereBureau,
            naSolBureau: naSolBureau,
            naPlinthebureau: naPlinthebureau,
            //Sanitaires
            lavaboSanit: lavaboSanit,
            robinetSanit: robinetSanit,
            miroirSanit: miroirSanit,
            distributeurSanit: distributeurSanit,
            poubelleSanit: poubelleSanit,
            wcSanit: wcSanit,
            lunetteSanit: lunetteSanit,
            urinoirSanit: urinoirSanit,
            doucheSanit: doucheSanit,
            cloisonSanit: cloisonSanit,
            radiateurSanit: radiateurSanit,
            solSanit: solSanit,
            plintheSanit: plintheSanit,
            totalSanitaires: totalSanitaires,
            sanitairesTotal: sanitairesTotal,
            naSanitTotal: naSanitTot,
            naLavabSanit: naLavabSanit,
            naRobinSanit: naRobinSanit,
            naMiroirSanit: naMiroirSanit,
            naDistrSanit: naDistrSanit,
            naPoubelleSanit: naPoubelleSanit,
            naWcSanit: naWcSanit,
            naLunSanit: naLunSanit,
            naUrinoirSanit: naUrinoirSanit,
            naDoucheSanit: naDoucheSanit,
            naCloisonSanit: naCloisonSanit,
            naRadiateurSanit: naRadiateurSanit,
            naSolSanit: naSolSanit,
            naPlintheSanit: naPlintheSanit,
            //Salles de Réunion
            poubelleReunion: poubelleReunion,
            bureauReunion: bureauReunion,
            phoneReunion: phoneReunion,
            objetReunion: objetReunion,
            siegeReunion: siegeReunion,
            appuiReunion: appuiReunion,
            radiateurReunion: radiateurReunion,
            armoireReunion: armoireReunion,
            priseReunion: priseReunion,
            porteReunion: porteReunion,
            poussiereReunion: poussiereReunion,
            solReunion: solReunion,
            plintheReunion: plintheReunion,
            totalReunion: totalReunion,
            reunionTotal: reunionTotal,
            naReunionTotal: naReunionTot,
            naPoubelleReunion: naPoubelleReunion,
            naBureauReunion: naBureauReunion,
            naPhoneReunion: naPhoneReunion,
            naObjReunion: naObjReunion,
            naSiegeReunion: naSiegeReunion,
            naAppuiReunion: naAppuiReunion,
            naRadiateurReunion: naRadiateurReunion,
            naArmoireReunion: naArmoireReunion,
            naPriseReunion: naPriseReunion,
            naPorteReunion: naPorteReunion,
            naPoussiereReunion: naPoussiereReunion,
            naSolReunion: naSolReunion,
            naPlintheReunion: naPlintheReunion,
            //Douches / Vestiaires
            lavaboDouche: lavaboDouche,
            robinetDouche: robinetDouche,
            miroirDouche: miroirDouche,
            distributeurDouche: distributeurDouche,
            poubelleDouche: poubelleDouche,
            wcDouche: wcDouche,
            lunetteDouche: lunetteDouche,
            urinoirDouche: urinoirDouche,
            doucheDouche: doucheDouche,
            cloisonDouche: cloisonDouche,
            radiateurDouche: radiateurDouche,
            solDouche: solDouche,
            plintheDouche: plintheDouche,
            totalDouche: totalDouche,
            doucheTotal: doucheTotal,
            naDoucheTotal: naDoucheTot,
            naLavabDouche: naLavabDouche,
            naRobinDouche: naRobinDouche,
            naMiroirDouche: naMiroirDouche,
            naDistrDouche: naDistrDouche,
            naPoubelleDouche: naPoubelleDouche,
            naWcDouche: naWcDouche,
            naLunDouche: naLunDouche,
            naUrinoirDouche: naUrinoirDouche,
            naDoucheDouche: naDoucheDouche,
            naCloisonDouche: naCloisonDouche,
            naRadiateurDouche: naRadiateurDouche,
            naSolDouche: naSolDouche,
            naPlintheDouche: naPlintheDouche,
            //Réfectoire
            solRefect: solRefect,
            bordRefect: bordRefect,
            porteRefect: porteRefect,
            tableRefect: tableRefect,
            chaiseRefect: chaiseRefect,
            depoussierageRefect: depoussierageRefect,
            totalRefect: totalRefect,
            refectTotal: refectTotal,
            naRefectTotal: naRefectTot,
            naSolRefect: naSolRefect,
            naBordRefect: naBordRefect,
            naPorteRefect: naPorteRefect,
            naTableRefect: naTableRefect,
            naChaiseRefect: naChaiseRefect,
            naDepoussRefect: naDepoussRefect,
            //Cuisine
            solCuisine: solCuisine,
            bordCuisine: bordCuisine,
            porteCuisine: porteCuisine,
            tableCuisine: tableCuisine,
            chaiseCuisine: chaiseCuisine,
            depoussierageCuisine: depoussierageCuisine,
            totalCuisine: totalCuisine,
            cuisineTotal: cuisineTotal,
            naCuisineTotal: naCuisineTot,
            naSolCuisine: naSolCuisine,
            naBordCuisine: naBordCuisine,
            naPorteCuisine: naPorteCuisine,
            naTableCuisine: naTableCuisine,
            naChaiseCuisine: naChaiseCuisine,
            naDepoussCuisine: naDepoussCuisine,
            //Circulations
            solCirculation: solCirculation,
            bordCirculation: bordCirculation,
            porteCirculation: porteCirculation,
            cadreCirculation: cadreCirculation,
            priseCirculation: priseCirculation,
            plintheCirculation: plintheCirculation,
            totalCirculation: totalCirculation,
            circulationsTotal: circulationsTotal,
            naCirculTotal: naCirculTot,
            naSolCircul: naSolCircul,
            naBordCircul: naBordCircul,
            naPorteCircul: naPorteCircul,
            naCadreCircul: naCadreCircul,
            naPriseCircul: naPriseCircul,
            naPlintheCircul: naPlintheCircul,
            //Ascenseur
            solAscenseur: solAscenseur,
            bordAscenseur: bordAscenseur,
            porteAscenseur: porteAscenseur,
            miroirAscenseur: miroirAscenseur,
            parIntAscenseur: parIntAscenseur,
            parExtAscenseur: parExtAscenseur,
            depoussierageAscenseur: depoussierageAscenseur,
            totalAscenseur: totalAscenseur,
            ascenseurTotal: ascenseurTotal,
            naAscenseurTotal: naAscenseurTot,
            naSolAscens: naSolAscens,
            naBordAscens: naBordAscens,
            naPorteAscens: naPorteAscens,
            naMiroirAscens: naMiroirAscens,
            naParoiIntAscens: naParoiIntAscens,
            naParoiExtAscens: naParoiExtAscens,
            naDepoussAscens: naDepoussAscens,
            //Escaliers
            solEscalier: solEscalier,
            bordEscalier: bordEscalier,
            porteEscalier: porteEscalier,
            cadreEscalier: cadreEscalier,
            rampeEscalier: rampeEscalier,
            totalEscalier: totalEscalier,
            escaliersTotal: escaliersTotal,
            naEscalTotal: naEscalTot,
            naSolEscal: naSolEscal,
            naBordEscal: naBordEscal,
            naPorteEscal: naPorteEscal,
            naCadreEscal: naCadreEscal,
            naRampeEscal: naRampeEscal,
            //Parking
            visuelParking: visuelParking,
            poubelleParking: poubelleParking,
            totalParking: totalParking,
            parkingTotal: parkingTotal,
            naParkingTotal: naParkingTot,
            naVisuelParking: naVisuelParking,
            naPoubelleParking: naPoubelleParking,
            //Local de Nettoyage / Chariots / Produits
            local: local,
            chariot: chariot,
            stock: stock,
            totalLocal: totalLocal,
            localTotal: localTotal,
            naLocalTotal: naLocalTot,
            naLocalLocal: naLocalLocal,
            naChariotLocal: naChariotLocal,
            naStockLocal: naStockLocal,
            //Evaluation
            totalEval: totalEval,
        }

        try {
            if (id !== undefined && id !== "") {
                await SuiviQualiteDataService.updateSuiviQualite(id, newSuiviQualite)
                setRapportId("")
                setMessage({ error: false, msg: "Les modifications ont été enregistrées" })
            }
        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }

    const handleEdit = async () => {
        setMessage("")
        try {
            const docSnap = await SuiviQualiteDataService.getSuiviQualite(id)
            setDate(docSnap.data().date)
            setInspector(docSnap.data().inspector)
            setClient(docSnap.data().client)
            setLocalisation(docSnap.data().localisation)
            setPresent(docSnap.data().present)
            setRemarks(docSnap.data().remarks)
            setAction(docSnap.data().action)
            setClientName(docSnap.data().clientName)
            setSignature(docSnap.data().signature)
            setChecked(docSnap.data().checked)
            //Hall
            setPoubelleHall(docSnap.data().poubelleHall)
            setCendrierHall(docSnap.data().cendrierHall)
            setPortVitrHall(docSnap.data().portVitrHall)
            setMobilierHall(docSnap.data().mobilierHall)
            setAppuiHall(docSnap.data().appuiHall)
            setRadiateurHall(docSnap.data().radiateurHall)
            setArmoireHall(docSnap.data().armoireHall)
            setPriseHall(docSnap.data().priseHall)
            setPorteHall(docSnap.data().porteHall)
            setPoussiereHall(docSnap.data().poussiereHall)
            setSolHall(docSnap.data().solHall)
            setPlintheHall(docSnap.data().plintheHall)
            setTotalHall(docSnap.data().totalHall)
            setHallTotal(docSnap.data().hallTotal)
            setNaHallTotal(docSnap.data().naHallTotal)
            setNaPoubelleHall(docSnap.data().naPoubelleHall)
            setNaCendrierHall(docSnap.data().naCendrierHall)
            setNaPortVitrHall(docSnap.data().naPortVitrHall)
            setNaMobilierHall(docSnap.data().naMobilierHall)
            setNaAppuiHall(docSnap.data().naAppuiHall)
            setNaRadiateurHall(docSnap.data().naRadiateurHall)
            setNaArmoireHall(docSnap.data().naArmoireHall)
            setNaPriseHall(docSnap.data().naPriseHall)
            setNaPorteHall(docSnap.data().naPorteHall)
            setNaPoussiereHall(docSnap.data().naPoussiereHall)
            setNaSolHall(docSnap.data().naSolHall)
            setNaPlintheHall(docSnap.data().naPlintheHall)
            //Réception
            setPoubelleReception(docSnap.data().poubelleReception)
            setBureauReception(docSnap.data().bureauReception)
            setPhoneReception(docSnap.data().phoneReception)
            setObjetReception(docSnap.data().objetReception)
            setSiegeReception(docSnap.data().siegeReception)
            setAppuiReception(docSnap.data().appuiReception)
            setRadiateurReception(docSnap.data().radiateurReception)
            setArmoireReception(docSnap.data().armoireReception)
            setPriseReception(docSnap.data().priseReception)
            setPorteReception(docSnap.data().porteReception)
            setPoussiereReception(docSnap.data().poussiereReception)
            setSolReception(docSnap.data().solReception)
            setPlintheReception(docSnap.data().plintheReception)
            setTotalReception(docSnap.data().totalReception)
            setReceptionTotal(docSnap.data().receptionTotal)
            setNaReceptTotal(docSnap.data().naReceptTotal)
            setNaPoubelleRecept(docSnap.data().naPoubelleRecept)
            setNaBureauRecept(docSnap.data().naBureauRecept)
            setNaTelRecept(docSnap.data().naTelRecept)
            setNaObjRecept(docSnap.data().naObjRecept)
            setNaSiegeRecept(docSnap.data().naSiegeRecept)
            setNaAppuiRecept(docSnap.data().naAppuiRecept)
            setNaRadiateurRecept(docSnap.data().naRadiateurRecept)
            setNaArmoireRecept(docSnap.data().naArmoireRecept)
            setNaPriseRecept(docSnap.data().naPriseRecept)
            setNaPorteRecept(docSnap.data().naPorteRecept)
            setNaPoussiereRecept(docSnap.data().naPoussiereRecept)
            setNaSolRecept(docSnap.data().naSolRecept)
            setNaPlintheRecept(docSnap.data().naPlintheRecept)
            //Bureaux
            setPoubelleBureau(docSnap.data().poubelleBureau)
            setBureauBureau(docSnap.data().bureauBureau)
            setPhoneBureau(docSnap.data().phoneBureau)
            setObjetBureau(docSnap.data().objetBureau)
            setSiegeBureau(docSnap.data().siegeBureau)
            setAppuiBureau(docSnap.data().appuiBureau)
            setRadiateurBureau(docSnap.data().radiateurBureau)
            setArmoireBureau(docSnap.data().armoireBureau)
            setPriseBureau(docSnap.data().priseBureau)
            setPorteBureau(docSnap.data().porteBureau)
            setPoussiereBureau(docSnap.data().poussiereBureau)
            setSolBureau(docSnap.data().solBureau)
            setPlintheBureau(docSnap.data().plintheBureau)
            setTotalBureaux(docSnap.data().totalBureaux)
            setBureauxTotal(docSnap.data().bureauxTotal)
            setNaBureauTotal(docSnap.data().naBureauTotal)
            setNaPoubelleBureau(docSnap.data().naPoubelleBureau)
            setNaBureauBureau(docSnap.data().naBureauBureau)
            setNaPhoneBureau(docSnap.data().naPhoneBureau)
            setNaObjBureau(docSnap.data().naObjBureau)
            setNaSiegeBureau(docSnap.data().naSiegeBureau)
            setNaAppuiBureau(docSnap.data().naAppuiBureau)
            setNaRadiateurBureau(docSnap.data().naRadiateurBureau)
            setNaArmoireBureau(docSnap.data().naArmoireBureau)
            setNaPriseBureau(docSnap.data().naPriseBureau)
            setNaPorteBureau(docSnap.data().naPorteBureau)
            setNaPoussiereBureau(docSnap.data().naPoussiereBureau)
            setNaSolBureau(docSnap.data().naSolBureau)
            setNaPlintheBureau(docSnap.data().naPlinthebureau)
            //Sanitaires
            setLavaboSanit(docSnap.data().lavaboSanit)
            setRobinetSanit(docSnap.data().robinetSanit)
            setMiroirSanit(docSnap.data().miroirSanit)
            setDistributeurSanit(docSnap.data().distributeurSanit)
            setPoubelleSanit(docSnap.data().poubelleSanit)
            setWcSanit(docSnap.data().wcSanit)
            setLunetteSanit(docSnap.data().lunetteSanit)
            setUrinoirSanit(docSnap.data().urinoirSanit)
            setDoucheSanit(docSnap.data().doucheSanit)
            setCloisonSanit(docSnap.data().cloisonSanit)
            setRadiateurSanit(docSnap.data().radiateurSanit)
            setSolSanit(docSnap.data().solSanit)
            setPlintheSanit(docSnap.data().plintheSanit)
            setTotalSanitaires(docSnap.data().totalSanitaires)
            setSanitairesTotal(docSnap.data().sanitairesTotal)
            setNaSanitTotal(docSnap.data().naSanitTotal)
            setNaLavabSanit(docSnap.data().naLavabSanit)
            setNaRobinSanit(docSnap.data().naRobinSanit)
            setNaMiroirSanit(docSnap.data().naMiroirSanit)
            setNaDistrSanit(docSnap.data().naDistrSanit)
            setNaPoubelleSanit(docSnap.data().naPoubelleSanit)
            setNaWcSanit(docSnap.data().naWcSanit)
            setNaLunSanit(docSnap.data().naLunSanit)
            setNaUrinoirSanit(docSnap.data().naUrinoirSanit)
            setNaDoucheSanit(docSnap.data().naDoucheSanit)
            setNaCloisonSanit(docSnap.data().naCloisonSanit)
            setNaRaditeurSanit(docSnap.data().naRadiateurSanit)
            setNaSolSanit(docSnap.data().naSolSanit)
            setNaPlintheSanit(docSnap.data().naPlintheSanit)
            //Salles de Réunion
            setPoubelleReunion(docSnap.data().poubelleReunion)
            setBureauReunion(docSnap.data().bureauReunion)
            setPhoneReunion(docSnap.data().phoneReunion)
            setObjetReunion(docSnap.data().objetReunion)
            setSiegeReunion(docSnap.data().siegeReunion)
            setAppuiReunion(docSnap.data().appuiReunion)
            setRadiateurReunion(docSnap.data().radiateurReunion)
            setArmoireReunion(docSnap.data().armoireReunion)
            setPriseReunion(docSnap.data().priseReunion)
            setPorteReunion(docSnap.data().porteReunion)
            setPoussiereReunion(docSnap.data().poussiereReunion)
            setSolReunion(docSnap.data().solReunion)
            setPlintheReunion(docSnap.data().plintheReunion)
            setTotalReunion(docSnap.data().totalReunion)
            setReunionTotal(docSnap.data().reunionTotal)
            setNaReunionTotal(docSnap.data().naReunionTotal)
            setNaPoubelleReunion(docSnap.data().naPoubelleReunion)
            setNaBureauReunion(docSnap.data().naBureauReunion)
            setNaPhoneReunion(docSnap.data().naPhoneReunion)
            setNaObjReunion(docSnap.data().naObjReunion)
            setNaSiegeReunion(docSnap.data().naSiegeReunion)
            setNaAppuiReunion(docSnap.data().naAppuiReunion)
            setNaRadiateurReunion(docSnap.data().naRadiateurReunion)
            setNaArmoireReunion(docSnap.data().naArmoireReunion)
            setNaPriseReunion(docSnap.data().naPriseReunion)
            setNaPorteReunion(docSnap.data().naPorteReunion)
            setNaPoussiereReunion(docSnap.data().naPoussiereReunion)
            setNaSolReunion(docSnap.data().naSolReunion)
            setNaPlintheReunion(docSnap.data().naPlintheReunion)
            //Douches / Vestiaies
            setLavaboDouche(docSnap.data().lavaboDouche)
            setRobinetDouche(docSnap.data().robinetDouche)
            setMiroirDouche(docSnap.data().miroirDouche)
            setDistributeurDouche(docSnap.data().distributeurDouche)
            setPoubelleDouche(docSnap.data().poubelleDouche)
            setWcDouche(docSnap.data().wcDouche)
            setLunetteDouche(docSnap.data().lunetteDouche)
            setUrinoirDouche(docSnap.data().urinoirDouche)
            setDoucheDouche(docSnap.data().doucheDouche)
            setCloisonDouche(docSnap.data().cloisonDouche)
            setRadiateurDouche(docSnap.data().radiateurDouche)
            setSolDouche(docSnap.data().solDouche)
            setPlintheDouche(docSnap.data().plintheDouche)
            setTotalDouche(docSnap.data().totalDouche)
            setDoucheTotal(docSnap.data().doucheTotal)
            setNaDoucheTotal(docSnap.data().naDoucheTotal)
            setNaLavabDouche(docSnap.data().naLavabDouche)
            setNaRobinDouche(docSnap.data().naRobinDouche)
            setNaMiroirDouche(docSnap.data().naMiroirDouche)
            setNaDistrDouche(docSnap.data().naDistrDouche)
            setNaPoubelleDouche(docSnap.data().naPoubelleDouche)
            setNaWcDouche(docSnap.data().naWcDouche)
            setNaLunDouche(docSnap.data().naLunDouche)
            setNaUrinoirDouche(docSnap.data().naUrinoirDouche)
            setNaDoucheDouche(docSnap.data().naDoucheDouche)
            setNaCloisonDouche(docSnap.data().naCloisonDouche)
            setNaRadiateurDouche(docSnap.data().naRadiateurDouche)
            setNaSolDouche(docSnap.data().naSolDouche)
            setNaPlintheDouche(docSnap.data().naPlintheDouche)
            //Réfectoire
            setSolRefect(docSnap.data().solRefect)
            setBordRefect(docSnap.data().bordRefect)
            setPorteRefect(docSnap.data().porteRefect)
            setTableRefect(docSnap.data().tableRefect)
            setChaiseRefect(docSnap.data().chaiseRefect)
            setDepoussierageRefect(docSnap.data().depoussierageRefect)
            setTotalRefect(docSnap.data().totalRefect)
            setRefectTotal(docSnap.data().refectTotal)
            setNaRefectTotal(docSnap.data().naRefectTotal)
            setNaSolRefect(docSnap.data().naSolRefect)
            setNaBordRefect(docSnap.data().naBordRefect)
            setNaPorteRefect(docSnap.data().naPorteRefect)
            setNaTableRefect(docSnap.data().naTableRefect)
            setNaChaiseRefect(docSnap.data().naChaiseRefect)
            setNaDepoussRefect(docSnap.data().naDepoussRefect)
            //Cuisine
            setSolCuisine(docSnap.data().solCuisine)
            setBordCuisine(docSnap.data().bordCuisine)
            setPorteCuisine(docSnap.data().porteCuisine)
            setTableCuisine(docSnap.data().tableCuisine)
            setChaiseCuisine(docSnap.data().chaiseCuisine)
            setDepoussierageCuisine(docSnap.data().depoussierageCuisine)
            setTotalCuisine(docSnap.data().totalCuisine)
            setCuisineTotal(docSnap.data().cuisineTotal)
            setNaCuisineTotal(docSnap.data().naCuisineTotal)
            setNaSolCuisine(docSnap.data().naSolCuisine)
            setNaBordCuisine(docSnap.data().naBordCuisine)
            setNaPorteCuisine(docSnap.data().naPorteCuisine)
            setNaTableCuisine(docSnap.data().naTableCuisine)
            setNaChaiseCuisine(docSnap.data().naChaiseCuisine)
            setNaDepoussCuisine(docSnap.data().naDepoussCuisine)
            //Circulations
            setSolCirculation(docSnap.data().solCirculation)
            setBordCirculation(docSnap.data().bordCirculation)
            setPorteCirculation(docSnap.data().porteCirculation)
            setCadreCirculation(docSnap.data().cadreCirculation)
            setPriseCirculation(docSnap.data().priseCirculation)
            setPlintheCirculation(docSnap.data().plintheCirculation)
            setTotalCirculation(docSnap.data().totalCirculation)
            setCirculationTotal(docSnap.data().circulationsTotal)
            setNaCirculTotal(docSnap.data().naCirculTotal)
            setNaSolCircul(docSnap.data().naSolCircul)
            setNaBordCircul(docSnap.data().naBordCircul)
            setNaPorteCircul(docSnap.data().naPorteCircul)
            setNaCadreCircul(docSnap.data().naCadreCircul)
            setNaPriseCircul(docSnap.data().naPriseCircul)
            setNaPlintheCircul(docSnap.data().naPlintheCircul)
            //Ascenseur
            setSolAscenseur(docSnap.data().solAscenseur)
            setBordAscenseur(docSnap.data().bordAscenseur)
            setPorteAscenseur(docSnap.data().porteAscenseur)
            setMiroirAscenseur(docSnap.data().miroirAscenseur)
            setParIntAscenseur(docSnap.data().parIntAscenseur)
            setParExtAscenseur(docSnap.data().parExtAscenseur)
            setDepoussierageAscenseur(docSnap.data().depoussierageAscenseur)
            setTotalAscenseur(docSnap.data().totalAscenseur)
            setAscenseurTotal(docSnap.data().ascenseurTotal)
            setNaAscenseurTotal(docSnap.data().naAscenseurTotal)
            setNaSolAscens(docSnap.data().naSolAscens)
            setNaBordAscens(docSnap.data().naBordAscens)
            setNaPorteAscens(docSnap.data().naPorteAscens)
            setNaMiroirAscens(docSnap.data().naMiroirAscens)
            setNaParoiIntAscens(docSnap.data().naParoiIntAscens)
            setNaParoiExtAscens(docSnap.data().naParoiExtAscens)
            setNaDepoussAscens(docSnap.data().naDepoussAscens)
            //Escaliers
            setSolEscalier(docSnap.data().solEscalier)
            setBordEscalier(docSnap.data().bordEscalier)
            setPorteEscalier(docSnap.data().porteEscalier)
            setCadreEscalier(docSnap.data().cadreEscalier)
            setRampeEscalier(docSnap.data().rampeEscalier)
            setTotalEscalier(docSnap.data().totalEscalier)
            setEscaliersTotal(docSnap.data().escaliersTotal)
            setNaEscalTotal(docSnap.data().naEscalTotal)
            setNaSolEscal(docSnap.data().naSolEscal)
            setNaBordEscal(docSnap.data().naBordEscal)
            setNaPorteEscal(docSnap.data().naPorteEscal)
            setNaCadreEscal(docSnap.data().naCadreEscal)
            setNaRampeEscal(docSnap.data().naRampeEscal)
            //Parking
            setVisuelParking(docSnap.data().visuelParking)
            setPoubelleParking(docSnap.data().poubelleParking)
            setTotalParking(docSnap.data().totalParking)
            setParkingTotal(docSnap.data().parkingTotal)
            setNaParkingTotal(docSnap.data().naParkingTotal)
            setNaVisuelParking(docSnap.data().naVisuelParking)
            setNaPoubelleParking(docSnap.data().naPoubelleParking)
            //Local de Nettoyage / Chariots / Produits
            setLocal(docSnap.data().local)
            setChariot(docSnap.data().chariot)
            setStock(docSnap.data().stock)
            setTotalLocal(docSnap.data().totalLocal)
            setLocalTotal(docSnap.data().localTotal)
            setNaLocalTotal(docSnap.data().naLocalTotal)
            setNaLocalLocal(docSnap.data().naLocalLocal)
            setNaChariotLocal(docSnap.data().naChariotLocal)
            setNaStockLocal(docSnap.data().naStockLocal)
            //Evaluation Générale
            setTotalEval(docSnap.data().totalEval)

        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }

    useEffect(() => {
        if (id !== undefined && id !== "") {
            handleEdit()
        }
    }, [id])

    


    //imprimer le rapport, créer PDF et envoyer par mail
    const [sent, setSent] = useState(false)
    const [displayPrint, setDisplayPrint] = useState(false)

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `suivi qualité ${date}${client} `,
        onAfterPrint: () => setSent(true)

    })

    

    const handlePDF = (e) => {
        e.preventDefault()
        getClientEmail()
        confirmAlert({
            title: 'Confirmez',
            message: "Etes-vous sûr d'avoir vérifié ce rapport avant de l'envoyer ?",
            buttons: [
                {
                    label: 'Oui',
                    onClick: async () => {
                        const rapport = {
                            date: date,
                            inspector: inspector,
                            client: client,
                            localisation: localisation,
                            present: present,
                            remarks: remarks,
                            action: action,
                            clientName: clientName,
                            signature: signature,
                            //Hall
                            poubelleHall: poubelleHall,
                            cendrierHall: cendrierHall,
                            portVitrHall: portVitrHall,
                            mobilierHall: mobilierHall,
                            appuiHall: appuiHall,
                            radiateurHall: radiateurHall,
                            armoireHall: armoireHall,
                            priseHall: priseHall,
                            porteHall: porteHall,
                            poussiereHall: poussiereHall,
                            solHall: solHall,
                            plintheHall: plintheHall,
                            totalHall: totalHall,
                            //Réception
                            poubelleReception: poubelleReception,
                            bureauReception: bureauReception,
                            phoneReception: phoneReception,
                            objetReception: objetReception,
                            siegeReception: siegeReception,
                            appuiReception: appuiReception,
                            radiateurReception: radiateurReception,
                            armoireReception: armoireReception,
                            priseReception: priseReception,
                            porteReception: porteReception,
                            poussiereReception: poussiereReception,
                            solReception: solReception,
                            plintheReception: plintheReception,
                            totalReception: totalReception,
                            //Bureaux
                            poubelleBureau: poubelleBureau,
                            bureauBureau: bureauBureau,
                            phoneBureau: phoneBureau,
                            objetBureau: objetBureau,
                            siegeBureau: siegeBureau,
                            appuiBureau: appuiBureau,
                            radiateurBureau: radiateurBureau,
                            armoireBureau: armoireBureau,
                            priseBureau: priseBureau,
                            porteBureau: porteBureau,
                            poussiereBureau: poussiereBureau,
                            solBureau: solBureau,
                            plintheBureau: plintheBureau,
                            totalBureaux: totalBureaux,
                            //Sanitaires
                            lavaboSanit: lavaboSanit,
                            robinetSanit: robinetSanit,
                            miroirSanit: miroirSanit,
                            distributeurSanit: distributeurSanit,
                            poubelleSanit: poubelleSanit,
                            wcSanit: wcSanit,
                            lunetteSanit: lunetteSanit,
                            urinoirSanit: urinoirSanit,
                            doucheSanit: doucheSanit,
                            cloisonSanit: cloisonSanit,
                            radiateurSanit: radiateurSanit,
                            solSanit: solSanit,
                            plintheSanit: plintheSanit,
                            totalSanitaires: totalSanitaires,
                            //Salles de Réunion
                            poubelleReunion: poubelleReunion,
                            bureauReunion: bureauReunion,
                            phoneReunion: phoneReunion,
                            objetReunion: objetReunion,
                            siegeReunion: siegeReunion,
                            appuiReunion: appuiReunion,
                            radiateurReunion: radiateurReunion,
                            armoireReunion: armoireReunion,
                            priseReunion: priseReunion,
                            porteReunion: porteReunion,
                            poussiereReunion: poussiereReunion,
                            solReunion: solReunion,
                            plintheReunion: plintheReunion,
                            totalReunion: totalReunion,
                            //Douches / Vestiaires
                            lavaboDouche: lavaboDouche,
                            robinetDouche: robinetDouche,
                            miroirDouche: miroirDouche,
                            distributeurDouche: distributeurDouche,
                            poubelleDouche: poubelleDouche,
                            wcDouche: wcDouche,
                            lunetteDouche: lunetteDouche,
                            urinoirDouche: urinoirDouche,
                            doucheDouche: doucheDouche,
                            cloisonDouche: cloisonDouche,
                            radiateurDouche: radiateurDouche,
                            solDouche: solDouche,
                            plintheDouche: plintheDouche,
                            totalDouche: totalDouche,
                            //Réfectoire
                            solRefect: solRefect,
                            bordRefect: bordRefect,
                            porteRefect: porteRefect,
                            tableRefect: tableRefect,
                            chaiseRefect: chaiseRefect,
                            depoussierageRefect: depoussierageRefect,
                            totalRefect: totalRefect,
                            //Cuisine
                            solCuisine: solCuisine,
                            bordCuisine: bordCuisine,
                            porteCuisine: porteCuisine,
                            tableCuisine: tableCuisine,
                            chaiseCuisine: chaiseCuisine,
                            depoussierageCuisine: depoussierageCuisine,
                            totalCuisine: totalCuisine,
                            //Circulations
                            solCirculation: solCirculation,
                            bordCirculation: bordCirculation,
                            porteCirculation: porteCirculation,
                            cadreCirculation: cadreCirculation,
                            priseCirculation: priseCirculation,
                            plintheCirculation: plintheCirculation,
                            totalCirculation: totalCirculation,
                            //Ascenseur
                            solAscenseur: solAscenseur,
                            bordAscenseur: bordAscenseur,
                            porteAscenseur: porteAscenseur,
                            miroirAscenseur: miroirAscenseur,
                            parIntAscenseur: parIntAscenseur,
                            parExtAscenseur: parExtAscenseur,
                            depoussierageAscenseur: depoussierageAscenseur,
                            totalAscenseur: totalAscenseur,
                            //Escaliers
                            solEscalier: solEscalier,
                            bordEscalier: bordEscalier,
                            porteEscalier: porteEscalier,
                            cadreEscalier: cadreEscalier,
                            rampeEscalier: rampeEscalier,
                            totalEscalier: totalEscalier,
                            //Parking
                            visuelParking: visuelParking,
                            poubelleParking: poubelleParking,
                            totalParking: totalParking,
                            //Local de Nettoyage / Chariots / Produits
                            local: local,
                            chariot: chariot,
                            stock: stock,
                            totalLocal: totalLocal,
                            //Evaluation
                            totalEval: totalEval,
                            sent: sent === false ? 'OUI' : ''
                        }
                        try {
                            await SuiviQualiteDataService.updateSuiviQualite(id, rapport)
                        }
                        catch (err) {
                            setMessage({ error: true, msg: err.message })
                        }

                        setDisplayPrint(true)
                    }
                },
                {
                    label: 'Non',

                }
            ]
        })
    }

  
    //Trouver l'email du client
    const [clientEmail, setClientEmail] = useState('')

    const getClientEmail = () => clients.map((thisClient) => {
        if (thisClient.client === client) {
            setClientEmail(thisClient.email);
        }
    })

    if (displayPrint === true) {
        return (
            <>
                <Button className='m-5 bg-secondary' onClick={handlePrint} style={{ cursor: "pointer" }}>Print / PDF et envoyer</Button>
                <CopyText clientEmail={clientEmail} />
                <div ref={componentRef} className='text-center'>
                    <h4 className='mb-5 '>Contrôle Qualité du {date}</h4>
                    <Container className='w-50 mb-4'>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Date</InputGroup.Text>
                            <Form.Control type="Texte" value={date} readOnly={true} />
                        </InputGroup>

                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Nom de l'inspecteur</InputGroup.Text>
                            <Form.Control type="Texte" value={inspector} readOnly={true} />
                        </InputGroup>

                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Client / Chantier</InputGroup.Text>
                            <Form.Control type="Texte" value={client} readOnly={true} />
                        </InputGroup>


                        <InputGroup className="mb-3 p-3" >
                            <InputGroup.Text>Localisation du contrôle</InputGroup.Text>
                            <Form.Control type="Texte" value={localisation} readOnly={true} />
                        </InputGroup>

                        <InputGroup className="mb-3 p-3" >
                            <InputGroup.Text>Présents</InputGroup.Text>
                            <Form.Control type="Texte" value={present} readOnly={true} />
                        </InputGroup>
                    </Container>
                    <div className='text-center mt-4 newPage'>
                        <h5>Hall d'accueil/entrée</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleHall > 0 ? poubelleHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Cendriers</td>
                                    <td>{cendrierHall > 0 ? cendrierHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes Vitrées</td>
                                    <td>{portVitrHall > 0 ? portVitrHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Mobilier</td>
                                    <td>{mobilierHall > 0 ? mobilierHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiHall > 0 ? appuiHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurHall > 0 ? radiateurHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireHall > 0 ? armoireHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseHall > 0 ? priseHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteHall > 0 ? porteHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereHall > 0 ? poussiereHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solHall > 0 ? solHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheHall > 0 ? plintheHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Hall / Entrée</strong></td>
                                    <td>{totalHall > 0 ? `${totalHall}  / ${hallTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Réception</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleReception > 0 ? poubelleReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bureaux</td>
                                    <td>{bureauReception > 0 ? bureauReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Téléphones</td>
                                    <td>{phoneReception > 0 ? phoneReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Objets de bureau</td>
                                    <td>{objetReception > 0 ? objetReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sièges de bureau</td>
                                    <td>{siegeReception > 0 ? siegeReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiReception > 0 ? appuiReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurReception > 0 ? radiateurReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireReception > 0 ? armoireReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseReception > 0 ? priseReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteReception > 0 ? porteReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereReception > 0 ? poussiereReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solReception > 0 ? solReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheReception > 0 ? plintheReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Réception</strong></td>
                                    <td>{totalReception > 0 ? `${totalReception} / ${receptionTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Bureaux</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleBureau > 0 ? poubelleBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bureaux</td>
                                    <td>{bureauBureau > 0 ? bureauBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Téléphones</td>
                                    <td>{phoneBureau > 0 ? phoneBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Objets de bureau</td>
                                    <td>{objetBureau > 0 ? objetBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sièges de bureau</td>
                                    <td>{siegeBureau > 0 ? siegeBureau : 'N/A' }</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiBureau > 0 ? appuiBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurBureau > 0 ? radiateurBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireBureau > 0 ? armoireBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseBureau > 0 ? priseBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteBureau > 0 ? porteBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereBureau > 0 ? poussiereBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solBureau > 0 ? solBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheBureau > 0 ? plintheBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Bureaux</strong></td>
                                    <td>{totalBureaux > 0 ? `${totalBureaux} / ${bureauxTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Salles de Réunion</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleReunion > 0 ? poubelleReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bureaux</td>
                                    <td>{bureauReunion > 0 ? bureauReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Téléphones</td>
                                    <td>{phoneReunion > 0 ? phoneReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Objets de bureau</td>
                                    <td>{objetReunion > 0 ? objetReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sièges de bureau</td>
                                    <td>{siegeReunion > 0 ? siegeReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiReunion > 0 ? appuiReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurReunion > 0 ? radiateurReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireReunion > 0 ? armoireReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseReunion > 0 ? priseReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteReunion > 0 ? porteReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereReunion > 0 ? poussiereReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solReunion > 0 ? solReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheReunion > 0 ? plintheReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Salles de Réunion</strong></td>
                                    <td>{totalReunion > 0 ? `${totalReunion} / ${reunionTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Sanitaires</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Lavabos</td>
                                    <td>{lavaboSanit > 0 ? lavaboSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Robinets / siphons</td>
                                    <td>{robinetSanit > 0 ? robinetSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Miroirs</td>
                                    <td>{miroirSanit > 0 ? miroirSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Distributeurs de savon / papier</td>
                                    <td>{distributeurSanit > 0 ? distributeurSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleSanit > 0 ? poubelleSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>WC</td>
                                    <td>{wcSanit > 0 ? wcSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Lunette WC</td>
                                    <td>{lunetteSanit > 0 ? lunetteSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Urinoirs</td>
                                    <td>{urinoirSanit > 0 ? urinoirSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Douches</td>
                                    <td>{doucheSanit > 0 ? doucheSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes / cloisons</td>
                                    <td>{cloisonSanit > 0 ? cloisonSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurSanit > 0 ? radiateurSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solSanit > 0 ? solSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheSanit > 0 ? plintheSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Sanitaires</strong></td>
                                    <td>{totalSanitaires > 0 ? `${totalSanitaires} / ${sanitairesTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Douches/Vestiares</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Lavabos</td>
                                    <td>{lavaboDouche > 0 ? lavaboDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Robinets / siphons</td>
                                    <td>{robinetDouche > 0 ? robinetDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Miroirs</td>
                                    <td>{miroirDouche > 0 ? miroirDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Distributeurs de savon / papier</td>
                                    <td>{distributeurDouche > 0 ? distributeurDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleDouche > 0 ? poubelleDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>WC</td>
                                    <td>{wcDouche > 0 ? wcDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Lunette WC</td>
                                    <td>{lunetteDouche > 0 ? lunetteDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Urinoirs</td>
                                    <td>{urinoirDouche > 0 ? urinoirDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Douches</td>
                                    <td>{doucheDouche > 0 ? doucheDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes / cloisons</td>
                                    <td>{cloisonDouche > 0 ? cloisonDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurDouche > 0 ? radiateurDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solDouche > 0 ? solDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheDouche > 0 ? plintheDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Douches/Vestiaires</strong></td>
                                    <td>{totalDouche > 0 ? `${totalDouche} / ${doucheTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Réfectoire</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solRefect > 0 ? solRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordRefect > 0 ? bordRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteRefect > 0 ? porteRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Tables</td>
                                    <td>{tableRefect > 0 ? tableRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Chaises</td>
                                    <td>{chaiseRefect > 0 ? chaiseRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Dépoussiérage en général</td>
                                    <td>{depoussierageRefect > 0 ? depoussierageRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Réfectoire</strong></td>
                                    <td>{totalRefect > 0 ? `${totalRefect} / ${refectTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Cuisine</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solCuisine > 0 ? solCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordCuisine > 0 ? bordCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteCuisine > 0 ? porteCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Tables</td>
                                    <td>{tableCuisine > 0 ? tableCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Chaises</td>
                                    <td>{chaiseCuisine > 0 ? chaiseCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Dépoussiérage en général</td>
                                    <td>{depoussierageCuisine > 0 ? depoussierageCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Cuisine</strong></td>
                                    <td>{totalCuisine > 0 ? `${totalCuisine} / ${cuisineTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Circulations</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solCirculation > 0 ? solCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordCirculation > 0 ? bordCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteCirculation > 0 ? porteCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Encadrements</td>
                                    <td>{cadreCirculation > 0 ? cadreCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseCirculation > 0 ? priseCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheCirculation > 0 ? plintheCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Circulations</strong></td>
                                    <td>{totalCirculation > 0 ? `${totalCirculation} / ${circulationsTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Ascenseur</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solAscenseur > 0 ? solAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordAscenseur > 0 ? bordAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteAscenseur > 0 ? porteAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Miroirs</td>
                                    <td>{miroirAscenseur > 0 ? miroirAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Paroi intérieure</td>
                                    <td>{parIntAscenseur > 0 ? parIntAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Paroi extérieure</td>
                                    <td>{parExtAscenseur > 0 ? parExtAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Dépoussiérage en général</td>
                                    <td>{depoussierageAscenseur > 0 ? depoussierageAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Ascenseur</strong></td>
                                    <td>{totalAscenseur > 0 ? `${totalAscenseur} / ${ascenseurTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Escaliers</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solEscalier > 0 ? solEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordEscalier > 0 ? bordEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteEscalier > 0 ? porteEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Encadrements</td>
                                    <td>{cadreEscalier > 0 ? cadreEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Main courante</td>
                                    <td>{rampeEscalier > 0 ? rampeEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Escaliers</strong></td>
                                    <td>{totalEscalier > 0 ? `${totalEscalier} / ${escaliersTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Parking</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Visuel gros papiers</td>
                                    <td>{visuelParking > 0 ? visuelParking : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleParking > 0 ? poubelleParking : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Parking</strong></td>
                                    <td>{totalParking > 0 ? `${totalParking} / ${parkingTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Local de Nettoyage / Chariots / Produits</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Local de nettoyage</td>
                                    <td>{local > 0 ? local : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Propreté chariots / matériel</td>
                                    <td>{chariot > 0 ? chariot : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Stock Produits</td>
                                    <td>{stock > 0 ? stock : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Local de Nettoyage / Chariots / Produits</strong></td>
                                    <td>{totalLocal > 0 ? `${totalLocal} / ${localTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Container className='w-50 mb-4 mt-5 newPage'>
                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas de constatation particulière"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={remarks}
                                readOnly={true}
                            />
                        </InputGroup>

                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Actions à prendre</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas d'action requise"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={action}
                                readOnly={true}
                            />
                        </InputGroup>



                        <InputGroup className="m-auto mt-5 ">
                            <InputGroup.Text>Evaluation Générale</InputGroup.Text>
                            <Form.Control type="Texte" value={totalEval + perc} readOnly={true} />
                        </InputGroup>
                        {<h3 className={totalEval < 70 ? 'text-center text-danger mb-5 p-3' : 'text-center text-success mb-5 p-3'} >{totalEval < 70 ? "Insatisfaisant" : totalEval < 80 ? "Satisfaisant" : "Très bien"}</h3>}



                        <InputGroup className="mb-5 p-3">
                            <InputGroup.Text>Nom Client</InputGroup.Text>
                            <Form.Control type="Texte" value={clientName} readOnly={true} />
                        </InputGroup>

                        <p className='text-center h5'>Signature Client</p>

                        <div className="signature">
                            <img src={signature} alt="" />
                        </div>
                    </Container>
                </div>

            </>
        )
    }
    else {
        return (
            <div className="suiviQualiteSingle">
                <div className="suiviQualiteSingleContainer">

                </div>
                <div className="printSend">
                    <Button className='bg-warning text-dark print' onClick={handlePDF}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-1 bi bi-printer-fill" viewBox="0 0 16 16" >
                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    </svg>Créer PDF et envoyer ou imprimer</Button>

                </div>
                <div className="mt-5 text-center" >
                    <h3>Contrôle Qualité du {date}</h3>

                    <Container className='mt-5'>
                        <Form className='m-5' onSubmit={handleSubmit} >
                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Date</InputGroup.Text>
                                <Form.Control type="Texte" value={date} onChange={(event) => { setDate(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Nom de l'inspecteur</InputGroup.Text>
                                <Form.Control type="Texte" value={inspector} onChange={(event) => { setInspector(event.target.value) }} />
                            </InputGroup>



                            <InputGroup className='mb-5 p-3'>
                                <InputGroup.Text>Client / Chantier</InputGroup.Text>
                                <Form.Select value={client} onChange={(event) => { setClient(event.target.value) }} aria-label="Default select example">
                                    <option>Choisir un client</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>

                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Localisation du contrôle</InputGroup.Text>
                                <Form.Control type="Texte" value={localisation} onChange={(event) => { setLocalisation(event.target.value) }} />
                            </InputGroup>

                            <InputGroup className="mb-5 p-3" >
                                <InputGroup.Text>Présents</InputGroup.Text>
                                <Form.Control type="Texte" value={present} onChange={(event) => { setPresent(event.target.value) }} />
                            </InputGroup>



                            <hr />


                            <div >
                              

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            Hall d'accueil/entrée
                                            
                                        </Typography>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleH) => (
                                                <div key={`inline-${poubelleH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        value={0}
                                                        onChange={() => setNaPoubelleHall(5)}
                                                        checked={poubelleHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleHall}
                                                        checked={poubelleHall === '1' ? 'checked' : ''}
               
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleHall}
                                                        checked={poubelleHall === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleHall}
                                                        checked={poubelleHall === '3' ? 'checked' : ''}

                                                        
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubellesHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleHall}
                                                        checked={poubelleHall === '4' ? 'checked' : ''}

                                                        
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubellesHall"
                                                        type="radio"                                                
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleHall}
                                                        checked={poubelleHall === '5' ? 'checked' : ''}
                                                    />
                                                   {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Cendriers</FormLabel>
                                            {['radio'].map((cendrierH) => (
                                                <div key={`inline-${cendrierH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaCendrierHall(5)}
                                                        checked={cendrierHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleCendrierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={cendrierHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCendrierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={cendrierHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCendrierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={cendrierHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCendrierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={cendrierHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cendrierHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCendrierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={cendrierHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes Vitrées</FormLabel>
                                            {['radio'].map((portVitrH) => (
                                                <div key={`inline-${portVitrH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="portVitrH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPortVitrHall(5)}
                                                        checked={portVitrHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="portVitrH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePortVitrHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={portVitrHall === '1' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="portVitrH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePortVitrHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={portVitrHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="portVitrH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePortVitrHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={portVitrHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="portVitrH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePortVitrHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={portVitrHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="portVitrH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePortVitrHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={portVitrHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>

                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Mobilier</FormLabel>
                                            {['radio'].map((mobilierH) => (
                                                <div key={`inline-${mobilierH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="mobilierH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaMobilierHall(5)}
                                                        checked={mobilierHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="mobilierH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleMobilierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={mobilierHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="mobilierH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMobilierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={mobilierHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="mobilierH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMobilierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={mobilierHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="mobilierH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMobilierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={mobilierHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="mobilierH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMobilierHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={mobilierHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiH) => (
                                                <div key={`inline-${appuiH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaAppuiHall(5)}
                                                        checked={appuiHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="appuiH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleAppuiHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={appuiHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={appuiHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={appuiHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={appuiHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiHall"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={appuiHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurH) => (
                                                <div key={`inline-${radiateurH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRadiateurHall(5)}
                                                        checked={radiateurHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="radiateurH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleRadiateurHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={radiateurHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={radiateurHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={radiateurHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={radiateurHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={radiateurHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireH) => (
                                                <div key={`inline-${armoireH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaArmoireHall(5)}
                                                        checked={armoireHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="armoireH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleArmoireHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={armoireHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoireHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={armoireHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoireHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={armoireHall === '3' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoireHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={armoireHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoireHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={armoireHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseH) => (
                                                <div key={`inline-${priseH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPriseHall(5)}
                                                        checked={priseHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="priseHall"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePriseHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={priseHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={priseHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={priseHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={priseHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={priseHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteH) => (
                                                <div key={`inline-${porteH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteHall(5)}
                                                        checked={porteHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="porteH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePorteHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={porteHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={porteHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={porteHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={porteHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={porteHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereH) => (
                                                <div key={`inline-${poussiereH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoussiereHall(5)}
                                                        checked={poussiereHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="poussiereH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePoussiereHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={poussiereHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={poussiereHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={poussiereHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={poussiereHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={poussiereHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solH) => (
                                                <div key={`inline-${solH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolHall(5)}
                                                        checked={solHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="solH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handleSolHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={solHall === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={solHall === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={solHall === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={solHall === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={solHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheH) => (
                                                <div key={`inline-${plintheH}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheH"
                                                        type="radio"
                                                        //disabled={naHall === 60 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheHall(5)}
                                                        checked={plintheHall === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="1"
                                                        name="plintheH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={1}
                                                        onChange={handlePlintheHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={plintheHall === '1' ? 'checked' : ''}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={plintheHall === '2' ? 'checked' : ''}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheH"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={plintheHall === '3' ? 'checked' : ''}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={plintheHall === '4' ? 'checked' : ''}

                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheH"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheHall}
                                                        //disabled={naHall === 60 ? true : false}
                                                        checked={plintheHall === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className='text-center'>{naHall === 60 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Hall / Entrée</InputGroup.Text>
                                            <Form.Control type="Texte" value={naHallTotal !== 60 || totalHall !==0 ? `${totalHall} /  ${hallTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>

                                        
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            Réception
                                            
                                        </Typography>

                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleRec) => (
                                                <div key={`inline-${poubelleRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}

                                                        value={0}
                                                        onChange={() => setNaPoubelleRecept(5)}
                                                        checked={poubelleReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poubelleReception ==='1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poubelleReception ==='2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poubelleReception ==='3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poubelleReception ==='4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleReception"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poubelleReception ==='5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bureaux</FormLabel>
                                            {['radio'].map((bureauRec) => (
                                                <div key={`inline-${bureauRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bureauRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaBureauRecept(5)}
                                                        checked={bureauReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bureauRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBureauRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={bureauReception ==='1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bureauRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBureauRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={bureauReception ==='2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bureauRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBureauRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={bureauReception ==='3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bureauRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBureauRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={bureauReception ==='4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bureauRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBureauRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={bureauReception ==='5' ? 'checked' : ''}

                                                    />
                                                    

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Téléphones</FormLabel>
                                            {['radio'].map((phoneRec) => (
                                                <div key={`inline-${phoneRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="phoneRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaTelRecept(5)}
                                                        checked={phoneReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="phoneRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleTelRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={phoneReception === '1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="phoneRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleTelRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={phoneReception === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="phoneRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleTelRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={phoneReception === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="phoneRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleTelRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={phoneReception === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="phoneRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleTelRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={phoneReception === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Objets de bureau</FormLabel>
                                            {['radio'].map((objetRec) => (
                                                <div key={`inline-${objetRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="objetRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaObjRecept(5)}
                                                        checked={objetReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="objetRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleObjRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={objetReception ==='1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="objetRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleObjRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={objetReception ==='2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="objetRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleObjRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={objetReception ==='3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="objetRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleObjRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={objetReception ==='4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="objetRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleObjRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={objetReception ==='5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sièges de bureau</FormLabel>
                                            {['radio'].map((siegeRec) => (
                                                <div key={`inline-${siegeRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="siegeRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSiegeRecept(5)}
                                                        checked={siegeReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="siegeRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSiegeRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={siegeReception === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="siegeRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSiegeRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={siegeReception === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="siegeRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSiegeRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={siegeReception === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="siegeRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSiegeRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={siegeReception === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="siegeRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSiegeRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={siegeReception === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiRec) => (
                                                <div key={`inline-${appuiRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaAppuiRecept(5)}
                                                        checked={appuiReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="appuiRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleAppuiRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={appuiReception === '1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={appuiReception === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={appuiReception === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={appuiReception === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={appuiReception === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurRec) => (
                                                <div key={`inline-${radiateurRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRadiateurRecept(5)}
                                                        checked={radiateurReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={radiateurReception === '1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={radiateurReception === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={radiateurReception === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={radiateurReception === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={radiateurReception === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireRec) => (
                                                <div key={`inline-${armoireRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaArmoireRecept(5)}
                                                        checked={armoireReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="armoireRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleArmoireRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={armoireReception === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoireRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={armoireReception === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoireRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={armoireReception === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoireRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={armoireReception === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoireRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={armoireReception === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseRec) => (
                                                <div key={`inline-${priseRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPriseRecept(5)}
                                                        checked={priseReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={priseReception === '1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={priseReception === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={priseReception === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={priseReception === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={priseReception === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteRec) => (
                                                <div key={`inline-${porteRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteRecept(5)}
                                                        checked={porteReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={porteReception === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={porteReception === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={porteReception === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={porteReception === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={porteReception === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereRec) => (
                                                <div key={`inline-${poussiereRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoussiereRecept(5)}
                                                        checked={poussiereReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poussiereRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoussiereRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poussiereReception === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poussiereReception === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poussiereReception === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereRece"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poussiereReception === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={poussiereReception === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solRec) => (
                                                <div key={`inline-${solRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolRecept(5)}
                                                        checked={solReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={solReception === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={solReception === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={solReception === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={solReception === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={solReception === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}


                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheRec) => (
                                                <div key={`inline-${plintheRec}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheRec"
                                                        type="radio"
                                                        //disabled={naReception === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheRecept(5)}
                                                        checked={plintheReception === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheRec"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={plintheReception === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={plintheReception === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheRec"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={plintheReception === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={plintheReception === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheRec"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheRecept}
                                                        //disabled={naReception === 65 ? true : false}
                                                        checked={plintheReception === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p>{naReception === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Réception</InputGroup.Text>
                                            <Form.Control type="Texte" value={naReceptTotal !== 65 || totalReception !== 0 ? `${totalReception} / ${receptionTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography>
                                            Bureaux
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleBur) => (
                                                <div key={`inline-${poubelleBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoubelleBureau(5)}
                                                        checked={poubelleBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poubelleBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poubelleBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poubelleBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poubelleBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poubelleBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? 'NON APPLICABLE' : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bureaux</FormLabel>
                                            {['radio'].map((bureauBur) => (
                                                <div key={`inline-${bureauBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bureauBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaBureauBureau(5)}
                                                        checked={bureauBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bureauBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBureauBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={bureauBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bureauBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBureauBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={bureauBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bureauBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBureauBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={bureauBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bureauBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBureauBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={bureauBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bureauBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBureauBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={bureauBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Téléphones</FormLabel>
                                            {['radio'].map((phoneBur) => (
                                                <div key={`inline-${phoneBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="phoneBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPhoneBureau(5)}
                                                        checked={phoneBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="phoneBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePhoneBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={phoneBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="phoneBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePhoneBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={phoneBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="phoneBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePhoneBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={phoneBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="phoneBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePhoneBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={phoneBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="phoneBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePhoneBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={phoneBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Objets de bureau</FormLabel>
                                            {['radio'].map((objetBur) => (
                                                <div key={`inline-${objetBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="objetBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaObjBureau(5)}
                                                        checked={objetBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="objetBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleObjBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={objetBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="objetBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleObjBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={objetBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="objetBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleObjBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={objetBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="objetBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleObjBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={objetBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="objetBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleObjBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={objetBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sièges de bureau</FormLabel>
                                            {['radio'].map((siegeBur) => (
                                                <div key={`inline-${siegeBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="siegeBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSiegeBureau(5)}
                                                        checked={siegeBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="siegeBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSiegeBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={siegeBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="siegeBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSiegeBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={siegeBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="siegeBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSiegeBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={siegeBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="siegeBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSiegeBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={siegeBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="siegeBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSiegeBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={siegeBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiBur) => (
                                                <div key={`inline-${appuiBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaAppuiBureau(5)}
                                                        checked={appuiBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="appuiBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleAppuiBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={appuiBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={appuiBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiBureau}
                                                        checked={appuiBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={appuiBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={appuiBureau === '5' ? 'checked' : ''}
                                                    />
                                                    

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurBur) => (
                                                <div key={`inline-${radiateurBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRadiateurBureau(5)}
                                                        checked={radiateurBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={radiateurBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={radiateurBureau === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={radiateurBureau === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={radiateurBureau === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={radiateurBureau === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireBur) => (
                                                <div key={`inline-${armoireBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={(e) => setNaArmoireBureau(5)}
                                                        checked={armoireBureau === 0 ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="armoireBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleArmoireBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={armoireBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoireBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={armoireBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoireBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={armoireBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoireBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={armoireBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoireBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={armoireBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseBur) => (
                                                <div key={`inline-${priseBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPriseBureau(5)}
                                                        checked={priseBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={priseBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={priseBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={priseBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={priseBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={priseBureau === '5' ? 'checked' : ''}


                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteBur) => (
                                                <div key={`inline-${porteBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteBureau(5)}
                                                        checked={porteBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={porteBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={porteBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={porteBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={porteBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={porteBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereBur) => (
                                                <div key={`inline-${poussiereBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoussiereBureau(5)}
                                                        checked={poussiereBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poussiereBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoussiereBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poussiereBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poussiereBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poussiereBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poussiereBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={poussiereBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solBur) => (
                                                <div key={`inline-${solBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolBureau(5)}
                                                        checked={solBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={solBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={solBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={solBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={solBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={solBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheBur) => (
                                                <div key={`inline-${plintheBur}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheBur"
                                                        type="radio"
                                                        //disabled={naBureau === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheBureau(5)}
                                                        checked={plintheBureau === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheBur"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={plintheBureau === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={plintheBureau === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheBur"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={plintheBureau === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={plintheBureau === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheBur"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheBureau}
                                                        //disabled={naBureau === 65 ? true : false}
                                                        checked={plintheBureau === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Bureaux</InputGroup.Text>
                                            <Form.Control type="Texte" value={naBureauTotal !== 65 || totalBureaux !== 0 ? `${totalBureaux} / ${bureauxTotal}` : 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <Typography >
                                            Sanitaires
                                            
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lavabos</FormLabel>
                                            {['radio'].map((lavaboSan) => (
                                                <div key={`inline-${lavaboSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lavaboSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaLavabSanit(5)}
                                                        checked={lavaboSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lavaboSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLavabSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lavaboSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lavaboSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLavabSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lavaboSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lavaboSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLavabSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lavaboSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lavaboSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLavabSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lavaboSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lavaboSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLavabSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lavaboSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Robinets / siphons</FormLabel>
                                            {['radio'].map((robinetSan) => (
                                                <div key={`inline-${robinetSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="robinetSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRobinSanit(5)}
                                                        checked={robinetSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="robinetSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRobinSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={robinetSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="robinetSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRobinSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={robinetSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="robinetSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRobinSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={robinetSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="robinetSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRobinSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={robinetSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="robinetSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRobinSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={robinetSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Miroirs</FormLabel>
                                            {['radio'].map((miroirSan) => (
                                                <div key={`inline-${miroirSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="miroirSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaMiroirSanit(5)}
                                                        checked={miroirSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="miroirSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleMiroirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={miroirSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="miroirSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMiroirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={miroirSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="miroirSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMiroirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={miroirSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="miroirSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleMiroirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={miroirSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="miroirSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMiroirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={miroirSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Distributeurs de savon / papier</FormLabel>
                                            {['radio'].map((distributeurSan) => (
                                                <div key={`inline-${distributeurSan}`} className="mb-3">
                                                    
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="distributeurSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={(e) => setNaDistrSanit(5)}
                                                        checked={distributeurSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="distributeurSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDistrSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={distributeurSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="distributeurSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDistrSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={distributeurSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="distributeurSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDistrSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={distributeurSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="distributeurSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDistrSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={distributeurSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="distributeurSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDistrSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={distributeurSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleSan) => (
                                                <div key={`inline-${poubelleSan}`} className="mb-3">
                                                     <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoubelleSanit(5)}
                                                        checked={poubelleSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={poubelleSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={poubelleSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={poubelleSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={poubelleSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={poubelleSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naBureau === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>WC</FormLabel>
                                            {['radio'].map((wcSan) => (
                                                <div key={`inline-${wcSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="wcSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaWcSanit(5)}
                                                        checked={wcSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="wcSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleWcSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={wcSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="wcSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleWcSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={wcSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="wcSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleWcSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={wcSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="wcSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleWcSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={wcSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="wcSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleWcSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={wcSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lunette WC</FormLabel>
                                            {['radio'].map((lunetteSan) => (
                                                <div key={`inline-${lunetteSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lunetteSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaLunSanit(5)}
                                                        checked={lunetteSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lunetteSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLunSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lunetteSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lunetteSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLunSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lunetteSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lunetteSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLunSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lunetteSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lunetteSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLunSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lunetteSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lunetteSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLunSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={lunetteSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Urinoirs</FormLabel>
                                            {['radio'].map((urinoirSan) => (
                                                <div key={`inline-${urinoirSan}`} className="mb-3">
                                                     <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="urinoirSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaUrinoirSanit(5)}
                                                        checked={urinoirSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="urinoirSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleUrinoirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={urinoirSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="urinoirSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleUrinoirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={urinoirSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="urinoirSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleUrinoirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={urinoirSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="urinoirSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleUrinoirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={urinoirSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="urinoirSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleUrinoirSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={urinoirSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Douches</FormLabel>
                                            {['radio'].map((doucheSan) => (
                                                <div key={`inline-${doucheSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="doucheSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={(e) => setNaDoucheSanit(5)}
                                                        checked={doucheSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="doucheSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDoucheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={doucheSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="doucheSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDoucheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={doucheSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="doucheSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDoucheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={doucheSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="doucheSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDoucheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={doucheSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="doucheSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDoucheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={doucheSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes / cloisons</FormLabel>
                                            {['radio'].map((cloisonSan) => (
                                                <div key={`inline-${cloisonSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cloisonSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaCloisonSanit(5)}
                                                        checked={cloisonSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cloisonSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCloisonSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={cloisonSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cloisonSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCloisonSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={cloisonSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cloisonSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCloisonSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={cloisonSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cloisonSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCloisonSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={cloisonSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cloisonSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCloisonSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={cloisonSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurSan) => (
                                                <div key={`inline-${radiateurSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRaditeurSanit(5)}
                                                        checked={radiateurSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={radiateurSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={radiateurSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={radiateurSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={radiateurSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={radiateurSanit === '5' ? 'checked' : ''}
                                                    />
                                                    

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solSan) => (
                                                <div key={`inline-${solSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolSanit(5)}
                                                        checked={solSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={solSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={solSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={solSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={solSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={solSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheSan) => (
                                                <div key={`inline-${plintheSan}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheSan"
                                                        type="radio"
                                                        //disabled={naSanit === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheSanit(5)}
                                                        checked={plintheSanit === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheSan"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={plintheSanit === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={plintheSanit === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheSan"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={plintheSanit === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={plintheSanit === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheSan"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheSanit}
                                                        //disabled={naSanit === 65 ? true : false}
                                                        checked={plintheSanit === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naSanit === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Sanitaires</InputGroup.Text>
                                            <Form.Control type="Texte" value={naSanitTotal !== 65 || totalSanitaires !== 0 ? `${totalSanitaires} / ${sanitairesTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                    >

                                        <Typography >
                                            Salles de Réunion
                                            
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleReun) => (
                                                <div key={`inline-${poubelleReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoubelleReunion(5)}
                                                        checked={poubelleReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poubelleReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poubelleReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poubelleReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poubelleReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poubelleReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bureaux</FormLabel>
                                            {['radio'].map((bureauReun) => (
                                                <div key={`inline-${bureauReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bureauReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBureauReunion(5)}
                                                        checked={bureauReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bureauReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBureauReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={bureauReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bureauReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBureauReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={bureauReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bureauReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBureauReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={bureauReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bureauReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBureauReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={bureauReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bureauReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBureauReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={bureauReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Téléphones</FormLabel>
                                            {['radio'].map((phoneReun) => (
                                                <div key={`inline-${phoneReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="phoneReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPhoneReunion(5)}
                                                        checked={phoneReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="phoneReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePhoneReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={phoneReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="phoneReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePhoneReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={phoneReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="phoneReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePhoneReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={phoneReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="phoneReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePhoneReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={phoneReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="phoneReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePhoneReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={phoneReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Objets de bureau</FormLabel>
                                            {['radio'].map((objetReun) => (
                                                <div key={`inline-${objetReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="objetReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaObjReunion(5)}
                                                        checked={objetReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="objetReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleObjReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={objetReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="objetReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleObjReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={objetReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="objetReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleObjReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={objetReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="objetReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleObjReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={objetReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="objetReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleObjReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={objetReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sièges de bureau</FormLabel>
                                            {['radio'].map((siegeReun) => (
                                                <div key={`inline-${siegeReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="siegeReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSiegeReunion(5)}
                                                        checked={siegeReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="siegeReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSiegeReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={siegeReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="siegeReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSiegeReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={siegeReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="siegeReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSiegeReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={siegeReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="siegeReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSiegeReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={siegeReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="siegeReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSiegeReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={siegeReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Appuis Fenêtres</FormLabel>
                                            {['radio'].map((appuiReun) => (
                                                <div key={`inline-${appuiReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="appuiReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaAppuiReunion(5)}
                                                        checked={appuiReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="appuiReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleAppuiReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={appuiReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="appuiReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleAppuiReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={appuiReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="appuiReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleAppuiReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={appuiReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="appuiReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleAppuiReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={appuiReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="appuiReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleAppuiReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={appuiReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurReun) => (
                                                <div key={`inline-${radiateurReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRadiateurReunion(5)}
                                                        checked={radiateurReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={radiateurReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={radiateurReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={radiateurReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={radiateurReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={radiateurReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Armoires</FormLabel>
                                            {['radio'].map((armoireReun) => (
                                                <div key={`inline-${armoireReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="armoireReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaArmoireReunion(5)}
                                                        checked={armoireReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="armoireReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleArmoirReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={armoireReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="armoireReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleArmoirReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={armoireReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="armoireReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleArmoirReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={armoireReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="armoireReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleArmoirReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={armoireReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="armoireReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleArmoirReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={armoireReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseReun) => (
                                                <div key={`inline-${priseReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPriseReunion(5)}
                                                        checked={priseReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={priseReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={priseReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={priseReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={priseReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={priseReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteReun) => (
                                                <div key={`inline-${porteReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteReunion(5)}
                                                        checked={porteReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={porteReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={porteReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={porteReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={porteReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={porteReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Hautes Poussières</FormLabel>
                                            {['radio'].map((poussiereReun) => (
                                                <div key={`inline-${poussiereReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poussiereReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoussiereReunion(5)}
                                                        checked={poussiereReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poussiereReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoussiereReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poussiereReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poussiereReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoussiereReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poussiereReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poussiereReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoussiereReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poussiereReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poussiereReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoussiereReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poussiereReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poussiereReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoussiereReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={poussiereReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solReun) => (
                                                <div key={`inline-${solReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolReunion(5)}
                                                        checked={solReunion=== 0 ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={solReunion=== '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={solReunion=== '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={solReunion=== '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={solReunion=== '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={solReunion=== '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheReun) => (
                                                <div key={`inline-${plintheReun}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheReun"
                                                        type="radio"
                                                        //disabled={naReunion === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheReunion(5)}
                                                        checked={plintheReunion === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheReun"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={plintheReunion === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={plintheReunion === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheReun"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={plintheReunion === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={plintheReunion === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheReun"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheReunion}
                                                        //disabled={naReunion === 65 ? true : false}
                                                        checked={plintheReunion === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naReunion === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Salles de Réunion</InputGroup.Text>
                                            <Form.Control type="Texte" value={naReunionTotal !== 65 || totalReunion !== 0 ? `${totalReunion} / ${reunionTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6a-content"
                                        id="panel6a-header"
                                    >
                                        <Typography>
                                            Douches/Vestiares
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lavabos</FormLabel>
                                            {['radio'].map((lavaboDouc) => (
                                                <div key={`inline-${lavaboDouc}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lavaboDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaLavabDouche(5)}
                                                        checked={lavaboDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lavaboDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLavabDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lavaboDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lavaboDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLavabDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lavaboDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lavaboDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLavabDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lavaboDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lavaboDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLavabDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lavaboDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lavaboDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLavabDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lavaboDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Robinets / siphons</FormLabel>
                                            {['radio'].map((robinetDou) => (
                                                <div key={`inline-${robinetDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="robinetDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRobinDouche(5)}
                                                        checked={robinetDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="robinetDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRobinDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={robinetDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="robinetDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRobinDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={robinetDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="robinetDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRobinDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={robinetDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="robinetDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRobinDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={robinetDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="robinetDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRobinDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={robinetDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Miroirs</FormLabel>
                                            {['radio'].map((miroirDou) => (
                                                <div key={`inline-${miroirDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="miroirDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaMiroirDouche(5)}
                                                        checked={miroirDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="miroirDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleMiroirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={miroirDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="miroirDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMiroirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={miroirDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="miroirDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMiroirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={miroirDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="miroirDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleMiroirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={miroirDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="miroirDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMiroirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={miroirDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Distributeurs de savon / papier</FormLabel>
                                            {['radio'].map((distributeurDou) => (
                                                <div key={`inline-${distributeurDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="distributeurDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaDistrDouche(5)}
                                                        checked={distributeurDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="distributeurDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDistrDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={distributeurDouche === '1' ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="distributeurDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDistrDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={distributeurDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="distributeurDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDistrDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={distributeurDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="distributeurDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDistrDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={distributeurDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="distributeurDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDistrDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={distributeurDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubelleDou) => (
                                                <div key={`inline-${poubelleDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubelleDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoubelleDouche(5)}
                                                        checked={poubelleDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubelleDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={poubelleDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubelleDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={poubelleDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubelleDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={poubelleDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubelleDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={poubelleDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubelleDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={poubelleDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>WC</FormLabel>
                                            {['radio'].map((wcDou) => (
                                                <div key={`inline-${wcDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="wcDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaWcDouche(5)}
                                                        checked={wcDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="wcDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleWcDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={wcDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="wcDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleWcDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={wcDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="wcDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleWcDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={wcDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="wcDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleWcDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={wcDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="wcDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleWcDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={wcDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Lunette WC</FormLabel>
                                            {['radio'].map((lunetteDou) => (
                                                <div key={`inline-${lunetteDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="lunetteDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaLunDouche(5)}
                                                        checked={lunetteDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="lunetteDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLunDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lunetteDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="lunetteDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLunDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lunetteDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="lunetteDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLunDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lunetteDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="lunetteDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLunDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lunetteDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="lunetteDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLunDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={lunetteDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Urinoirs</FormLabel>
                                            {['radio'].map((urinoirDou) => (
                                                <div key={`inline-${urinoirDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="urinoirDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaUrinoirDouche(5)}
                                                        checked={urinoirDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="urinoirDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleUrinoirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={urinoirDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="urinoirDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleUrinoirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={urinoirDouche === '2' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="urinoirDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleUrinoirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={urinoirDouche === '3' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="urinoirDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleUrinoirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={urinoirDouche === '4' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="urinoirDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleUrinoirDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={urinoirDouche === '5' ? 'checked' : ''}

                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Douches</FormLabel>
                                            {['radio'].map((doucheDou) => (
                                                <div key={`inline-${doucheDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="doucheDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaDoucheDouche(5)}
                                                        checked={doucheDouche === 0 ? 'checked' : ''}


                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="doucheDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDoucheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={doucheDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="doucheDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDoucheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={doucheDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="doucheDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDoucheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={doucheDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="doucheDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDoucheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={doucheDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="doucheDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDoucheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={doucheDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes / cloisons</FormLabel>
                                            {['radio'].map((cloisonDou) => (
                                                <div key={`inline-${cloisonDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cloisonDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaCloisonDouche(5)}
                                                        checked={cloisonDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cloisonDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCloisonDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={cloisonDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cloisonDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCloisonDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={cloisonDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cloisonDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCloisonDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={cloisonDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cloisonDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCloisonDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={cloisonDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cloisonDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCloisonDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={cloisonDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Radiateurs/Convecteurs</FormLabel>
                                            {['radio'].map((radiateurDou) => (
                                                <div key={`inline-${radiateurDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="radiateurDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRadiateurDouche(5)}
                                                        checked={radiateurDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="radiateurDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRadiateurDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={radiateurDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="radiateurDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRadiateurDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={radiateurDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="radiateurDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRadiateurDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={radiateurDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="radiateurDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRadiateurDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={radiateurDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="radiateurDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRadiateurDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={radiateurDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solDou) => (
                                                <div key={`inline-${solDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolDouche(5)}
                                                        checked={solDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={solDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={solDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={solDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={solDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={solDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheDou) => (
                                                <div key={`inline-${plintheDou}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheDou"
                                                        type="radio"
                                                        //disabled={naDouche === 65 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheDouche(5)}
                                                        checked={plintheDouche === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheDou"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={plintheDouche === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={plintheDouche === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheDou"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={plintheDouche === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={plintheDouche === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheDou"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheDouche}
                                                        //disabled={naDouche === 65 ? true : false}
                                                        checked={plintheDouche === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naDouche === 65 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Douches/Vestiaires</InputGroup.Text>
                                            <Form.Control type="Texte" value={naDoucheTotal !== 65 || totalDouche !== 0 ? `${totalDouche} / ${doucheTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7a-content"
                                        id="panel7a-header"
                                    >
                                        <Typography>
                                            Réfectoire
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solRef) => (
                                                <div key={`inline-${solRef}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solRef"
                                                        type="radio"
                                                        //disabled={naRefect === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolRefect(5)}
                                                        checked={solRefect === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solRef"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={solRefect === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={solRefect === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={solRefect === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={solRefect === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={solRefect === '5' ? 'checked' : ''}
                                                    />
                                                   

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / coins</FormLabel>
                                            {['radio'].map((bordRef) => (
                                                <div key={`inline-${bordRef}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordRef"
                                                        type="radio"
                                                        //disabled={naRefect === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaBordRefect(5)}
                                                        checked={bordRefect === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordRef"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={bordRefect === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={bordRefect === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={bordRefect === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={bordRefect === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={bordRefect === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naRefect === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteRef) => (
                                                <div key={`inline-${porteRef}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteRef"
                                                        type="radio"
                                                        //disabled={naRefect === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteRefect(5)}
                                                        checked={porteRefect === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteRef"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={porteRefect === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={porteRefect === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={porteRefect === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={porteRefect === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={porteRefect === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naRefect === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Tables</FormLabel>
                                            {['radio'].map((tableRef) => (
                                                <div key={`inline-${tableRef}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="tableRef"
                                                        type="radio"
                                                        //disabled={naRefect === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaTableRefect(5)}
                                                        checked={tableRefect === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="tableRef"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleTableRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={tableRefect === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="tableRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleTableRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={tableRefect === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="tableRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleTableRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={tableRefect === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="tableRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleTableRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={tableRefect === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="tableRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleTableRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={tableRefect === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naRefect === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Chaises</FormLabel>
                                            {['radio'].map((chaiseRef) => (
                                                <div key={`inline-${chaiseRef}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="chaiseRef"
                                                        type="radio"
                                                        //disabled={naRefect === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaChaiseRefect(5)}
                                                        checked={chaiseRefect === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="chaiseRef"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleChaiseRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={chaiseRefect === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="chaiseRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleChaiseRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={chaiseRefect === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="chaiseRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleChaiseRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={chaiseRefect === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="chaiseRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleChaiseRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={chaiseRefect === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="chaiseRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleChaiseRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={chaiseRefect === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naRefect === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Dépoussiérage en général</FormLabel>
                                            {['radio'].map((depoussierageRef) => (
                                                <div key={`inline-${depoussierageRef}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="depoussierageRef"
                                                        type="radio"
                                                        //disabled={naRefect === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaDepoussRefect(5)}
                                                        checked={depoussierageRefect === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="depoussierageRef"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDepoussRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={depoussierageRefect === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="depoussierageRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDepoussRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={depoussierageRefect === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="depoussierageRef"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDepoussRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={depoussierageRefect === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="depoussierageRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDepoussRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={depoussierageRefect === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="depoussierageRef"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDepoussRefect}
                                                        //disabled={naRefect === 30 ? true : false}
                                                        checked={depoussierageRefect === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naRefect === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Réfectoire</InputGroup.Text>
                                            <Form.Control type="Texte" value={naRefectTotal !== 30 || totalRefect !== 0 ? `${totalRefect} / ${refectTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8a-content"
                                        id="panel8a-header"
                                    >
                                        <Typography>
                                            Cuisine
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solCuis) => (
                                                <div key={`inline-${solCuis}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solCuis"
                                                        type="radio"
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolCuisine(5)}
                                                        checked={solCuisine === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solCuis"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={solCuisine === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={solCuisine === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={solCuisine === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={solCuisine === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={solCuisine === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCuisine === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordCuis) => (
                                                <div key={`inline-${bordCuis}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordCuis"
                                                        type="radio"
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaBordCuisine(5)}
                                                        checked={bordCuisine === 0 ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordCuis"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={bordCuisine === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={bordCuisine === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={bordCuisine === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={bordCuisine === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={bordCuisine === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCuisine === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteCuis) => (
                                                <div key={`inline-${porteCuis}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteCuis"
                                                        type="radio"
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        value={"N/A"}
                                                        onChange={(e) => setNaPorteCuisine(5)}
                                                        checked={porteCuisine === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteCuis"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={porteCuisine === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={porteCuisine === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={porteCuisine === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={porteCuisine === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={porteCuisine === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCuisine === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Tables</FormLabel>
                                            {['radio'].map((tableCuis) => (
                                                <div key={`inline-${tableCuis}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="tableCuis"
                                                        type="radio"
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaTableCuisine(5)}
                                                        checked={tableCuisine === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="tableCuis"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleTableCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={tableCuisine === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="tableCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleTableCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={tableCuisine === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="tableCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleTableCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={tableCuisine === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="tableCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleTableCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={tableCuisine === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="tableCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleTableCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={tableCuisine === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCuisine === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Chaises</FormLabel>
                                            {['radio'].map((chaiseCuis) => (
                                                <div key={`inline-${chaiseCuis}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="chaiseCuis"
                                                        type="radio"
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaChaiseCuisine(5)}
                                                        checked={chaiseCuisine === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="chaiseCuis"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleChaiseCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={chaiseCuisine === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="chaiseCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleChaiseCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={chaiseCuisine === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="chaiseCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleChaiseCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={chaiseCuisine === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="chaiseCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleChaiseCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={chaiseCuisine === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="chaiseCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleChaiseCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={chaiseCuisine === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCuisine === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Dépoussiérage en général</FormLabel>
                                            {['radio'].map((depoussierageCuis) => (
                                                <div key={`inline-${depoussierageCuis}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="depoussierageCuis"
                                                        type="radio"
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaDepoussCuisine(5)}
                                                        checked={depoussierageCuisine === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="depoussierageCuis"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDepoussCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={depoussierageCuisine === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="depoussierageCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDepoussCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={depoussierageCuisine === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="depoussierageCuis"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDepoussCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={depoussierageCuisine === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="depoussierageCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDepoussCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={depoussierageCuisine === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="depoussierageCuis"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDepoussCuisine}
                                                        //disabled={naCuisine === 30 ? true : false}
                                                        checked={depoussierageCuisine === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCuisine === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Cuisine</InputGroup.Text>
                                            <Form.Control type="Texte" value={naCuisineTotal !== 30 || totalCuisine !== 0 ? `${totalCuisine} / ${cuisineTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9a-content"
                                        id="panel9a-header"
                                    >
                                        <Typography>
                                            Circulations
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solCirculat) => (
                                                <div key={`inline-${solCirculat}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solCirculat"
                                                        type="radio"
                                                        //disabled={naCircul === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolCircul(5)}
                                                        checked={solCirculation === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solCirculat"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={solCirculation === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={solCirculation === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={solCirculation === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={solCirculation === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={solCirculation === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCircul === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordCirculat) => (
                                                <div key={`inline-${bordCirculat}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordCirculat"
                                                        type="radio"
                                                        //disabled={naCircul === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaBordCircul(5)}
                                                        checked={bordCirculation === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordCirculat"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordCircul}
                                                        checked={bordCirculation === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordCircul}
                                                        checked={bordCirculation === '2' ? 'checked' : ''}
                                            
                                                        
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordCircul}
                                                        checked={bordCirculation === '3' ? 'checked' : ''}
                                                        
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordCircul}
                                                        checked={bordCirculation === '4' ? 'checked' : ''}
                                                        
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordCircul}
                                                        checked={bordCirculation === '5' ? 'checked' : ''}
                                                        
                                                    />
                                                    
                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteCirculat) => (
                                                <div key={`inline-${porteCirculat}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteCirculat"
                                                        type="radio"
                                                        //disabled={naCircul === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteCircul(5)}
                                                        checked={porteCirculation === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteCirculat"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={porteCirculation === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={porteCirculation === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={porteCirculation === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={porteCirculation === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={porteCirculation === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCircul === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Encadrements</FormLabel>
                                            {['radio'].map((cadreCirculat) => (
                                                <div key={`inline-${cadreCirculat}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cadreCirculat"
                                                        type="radio"
                                                        //disabled={naCircul === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaCadreCircul(5)}
                                                        checked={cadreCirculation === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cadreCirculat"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCadreCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={cadreCirculation === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cadreCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCadreCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={cadreCirculation === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cadreCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCadreCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={cadreCirculation === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cadreCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCadreCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={cadreCirculation === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cadreCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCadreCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={cadreCirculation === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCircul === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Interrupteurs/prises</FormLabel>
                                            {['radio'].map((priseCirculat) => (
                                                <div key={`inline-${priseCirculat}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="priseCirculat"
                                                        type="radio"
                                                        //disabled={naCircul === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPriseCircul(5)}
                                                        checked={priseCirculation === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="priseCirculat"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePriseCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={priseCirculation === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="priseCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePriseCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={priseCirculation === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="priseCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePriseCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={priseCirculation === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="priseCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePriseCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={priseCirculation === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="priseCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePriseCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={priseCirculation === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCircul === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Plinthes/coins</FormLabel>
                                            {['radio'].map((plintheCirculat) => (
                                                <div key={`inline-${plintheCirculat}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="plintheCirculat"
                                                        type="radio"
                                                        //disabled={naCircul === 30 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPlintheCircul(5)}
                                                        checked={plintheCirculation === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="plintheCirculat"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePlintheCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={plintheCirculation === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="plintheCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePlintheCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={plintheCirculation === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="plintheCirculat"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePlintheCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={plintheCirculation === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="plintheCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePlintheCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={plintheCirculation === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="plintheCirculat"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePlintheCircul}
                                                        //disabled={naCircul === 30 ? true : false}
                                                        checked={plintheCirculation === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naCircul === 30 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Circulations</InputGroup.Text>
                                            <Form.Control type="Texte" value={naCirculTotal !== 30 || totalCirculation !== 0 ? `${totalCirculation} / ${circulationsTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography>
                                            Ascenseur
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solAscens) => (
                                                <div key={`inline-${solAscens}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solAscens"
                                                        type="radio"
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolAscens(5)}
                                                        checked={solAscenseur === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solAscens"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={solAscenseur === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={solAscenseur === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={solAscenseur === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={solAscenseur === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={solAscenseur === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naAscenseur === 35 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordAscens) => (
                                                <div key={`inline-${bordAscens}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordAscens"
                                                        type="radio"
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaBordAscens(5)}
                                                        checked={bordAscenseur === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordAscens"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={bordAscenseur === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={bordAscenseur === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={bordAscenseur === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={bordAscenseur === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={bordAscenseur === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naAscenseur === 35 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteAscens) => (
                                                <div key={`inline-${porteAscens}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteAscens"
                                                        type="radio"
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteAscens(5)}
                                                        checked={porteAscenseur === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteAscens"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={porteAscenseur === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={porteAscenseur === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={porteAscenseur === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={porteAscenseur === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={porteAscenseur === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naAscenseur === 35 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Miroirs</FormLabel>
                                            {['radio'].map((miroirAscens) => (
                                                <div key={`inline-${miroirAscens}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="miroirAscens"
                                                        type="radio"
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        value={"N/A"}
                                                        onChange={() => setNaMiroirAscens(5)}
                                                        checked={miroirAscenseur === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="miroirAscens"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleMiroirAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={miroirAscenseur === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="miroirAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleMiroirAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={miroirAscenseur === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="miroirAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleMiroirAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={miroirAscenseur === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="miroirAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleMiroirAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={miroirAscenseur === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="miroirAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleMiroirAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={miroirAscenseur === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naAscenseur === 35 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Paroi intérieure</FormLabel>
                                            {['radio'].map((parIntAscens) => (
                                                <div key={`inline-${parIntAscens}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="parIntAscens"
                                                        type="radio"
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaParoiIntAscens(5)}
                                                        checked={parIntAscenseur === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="parIntAscens"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleParoiIntAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parIntAscenseur === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="parIntAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleParoiIntAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parIntAscenseur === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="parIntAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleParoiIntAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parIntAscenseur === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="parIntAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleParoiIntAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parIntAscenseur === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="parIntAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleParoiIntAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parIntAscenseur === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naAscenseur === 35 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Paroi extérieure</FormLabel>
                                            {['radio'].map((parExtAscens) => (
                                                <div key={`inline-${parExtAscens}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="parExtAscens"
                                                        type="radio"
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaParoiExtAscens(5)}
                                                        checked={parExtAscenseur === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="parExtAscens"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleParoiExtAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parExtAscenseur === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="parExtAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleParoiExtAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parExtAscenseur === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="parExtAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleParoiExtAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parExtAscenseur === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="parExtAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleParoiExtAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parExtAscenseur === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="parExtAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleParoiExtAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={parExtAscenseur === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naAscenseur === 35 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Dépoussiérage en général</FormLabel>
                                            {['radio'].map((depoussierageAscens) => (
                                                <div key={`inline-${depoussierageAscens}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="depoussierageAscens"
                                                        type="radio"
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaDepoussAscens(5)}
                                                        checked={depoussierageAscenseur === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="depoussierageAscens"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleDepoussAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={depoussierageAscenseur === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="depoussierageAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleDepoussAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={depoussierageAscenseur === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="depoussierageAscens"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleDepoussAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={depoussierageAscenseur === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="depoussierageAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleDepoussAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={depoussierageAscenseur === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="depoussierageAscens"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleDepoussAscenseur}
                                                        //disabled={naAscenseur === 35 ? true : false}
                                                        checked={depoussierageAscenseur === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naAscenseur === 35 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Ascenseur</InputGroup.Text>
                                            <Form.Control type="Texte" value={naAscenseurTotal !== 35 || totalAscenseur !== 0 ? `${totalAscenseur} / ${ascenseurTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography>
                                            Escaliers
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Sols</FormLabel>
                                            {['radio'].map((solEscal) => (
                                                <div key={`inline-${solEscal}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="solEscal"
                                                        type="radio"
                                                        //disabled={naEscal === 25 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaSolEscal(5)}
                                                        checked={solEscalier === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="solEscal"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleSolEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={solEscalier === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="solEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleSolEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={solEscalier === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="solEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleSolEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={solEscalier === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="solEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleSolEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={solEscalier === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="solEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleSolEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={solEscalier === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naEscal === 25 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Bords / Coins</FormLabel>
                                            {['radio'].map((bordEscal) => (
                                                <div key={`inline-${bordEscal}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="bordEscal"
                                                        type="radio"
                                                        //disabled={naEscal === 25 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaBordEscal(5)}
                                                        checked={bordEscalier === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="bordEscal"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleBordEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={bordEscalier === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="bordEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleBordEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={bordEscalier === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="bordEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleBordEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={bordEscalier === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="bordEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleBordEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={bordEscalier === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="bordEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleBordEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={bordEscalier === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naEscal === 25 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Portes</FormLabel>
                                            {['radio'].map((porteEscal) => (
                                                <div key={`inline-${porteEscal}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="porteEscal"
                                                        type="radio"
                                                        //disabled={naEscal === 25 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPorteEscal(5)}
                                                        checked={porteEscalier === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="porteEscal"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePorteEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={porteEscalier === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="porteEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePorteEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={porteEscalier === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="porteEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePorteEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={porteEscalier === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="porteEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePorteEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={porteEscalier === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="porteEscalier"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePorteEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={porteEscalier === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naEscal === 25 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Encadrements</FormLabel>
                                            {['radio'].map((cadreEscal) => (
                                                <div key={`inline-${cadreEscal}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="cadreEscal"
                                                        type="radio"
                                                        //disabled={naEscal === 25 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaCadreEscal(5)}
                                                        checked={cadreEscalier === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="cadreEscal"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleCadreEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={cadreEscalier === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="cadreEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleCadreEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={cadreEscalier === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="cadreEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleCadreEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={cadreEscalier === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="cadreEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleCadreEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={cadreEscalier === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="cadreEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleCadreEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={cadreEscalier === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naEscal === 25 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Main courante</FormLabel>
                                            {['radio'].map((rampeEscal) => (
                                                <div key={`inline-${rampeEscal}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="rampeEscal"
                                                        type="radio"
                                                        //disabled={naEscal === 25 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaRampeEscal(5)}
                                                        checked={rampeEscalier === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="rampeEscal"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleRampeEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={rampeEscalier === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="rampeEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleRampeEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={rampeEscalier === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="rampeEscal"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleRampeEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={rampeEscalier === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="rampeEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleRampeEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={rampeEscalier === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="rampeEscal"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleRampeEscal}
                                                        //disabled={naEscal === 25 ? true : false}
                                                        checked={rampeEscalier === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naEscal === 25 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Escaliers</InputGroup.Text>
                                            <Form.Control type="Texte" value={naEscalTotal !== 25 || totalEscalier !== 0 ? `${totalEscalier} / ${escaliersTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography>
                                            Parking
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Visuel gros papiers</FormLabel>
                                            {['radio'].map((visuelPark) => (
                                                <div key={`inline-${visuelPark}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="visuelPark"
                                                        type="radio"
                                                        //disabled={naParking === 10 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaVisuelParking(5)}
                                                        checked={visuelParking === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="visuelPark"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleVisuelParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={visuelParking === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="visuelPark"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleVisuelParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={visuelParking === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="visuelPark"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleVisuelParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={visuelParking === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="visuelPark"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleVisuelParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={visuelParking === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="visuelPark"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleVisuelParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={visuelParking === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naParking === 10 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Poubelles</FormLabel>
                                            {['radio'].map((poubellePark) => (
                                                <div key={`inline-${poubellePark}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="poubellePark"
                                                        type="radio"
                                                        //disabled={naParking === 10 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaPoubelleParking(5)}
                                                        checked={poubelleParking === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="poubellePark"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handlePoubelleParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={poubelleParking === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="poubellePark"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handlePoubelleParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={poubelleParking === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="poubellePark"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handlePoubelleParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={poubelleParking === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="poubellePark"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handlePoubelleParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={poubelleParking === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="poubellePark"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handlePoubelleParking}
                                                        //disabled={naParking === 10 ? true : false}
                                                        checked={poubelleParking === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naParking === 10 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Parking</InputGroup.Text>
                                            <Form.Control type="Texte" value={naParkingTotal !== 10 || totalParking !== 0 ? `${totalParking} / ${parkingTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel11a-content"
                                        id="panel11a-header"
                                    >
                                        <Typography>
                                            Local de Nettoyage / Chariots / Produits
                                            
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Local de nettoyage</FormLabel>
                                            {['radio'].map((localNet) => (
                                                <div key={`inline-${localNet}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="localNet"
                                                        type="radio"
                                                        //disabled={naLocal === 15 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaLocalLocal(5)}
                                                        checked={local === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="localNet"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleLocal}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={local === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="localNet"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleLocal}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={local === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="localNet"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleLocal}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={local === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="localNet"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleLocal}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={local === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="localNet"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleLocal}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={local === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naLocal === 15 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Propreté chariots / matériel</FormLabel>
                                            {['radio'].map((chariotProp) => (
                                                <div key={`inline-${chariotProp}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="chariotProp"
                                                        type="radio"
                                                        //disabled={naLocal === 15 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaChariotLocal(5)}
                                                        checked={chariot === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="chariotProp"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleChariot}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={chariot === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="chariotProp"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleChariot}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={chariot === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="chariotProp"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleChariot}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={chariot === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="chariotProp"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleChariot}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={chariot === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="chariotProp"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleChariot}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={chariot === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naLocal === 15 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <FormGroup className='mb-3'>
                                            <FormLabel className='h5 mt-4 mb-3'>Stock Produits</FormLabel>
                                            {['radio'].map((stockProd) => (
                                                <div key={`inline-${stockProd}`} className="mb-3">
                                                    <Form.Check
                                                        className='text-dark'
                                                        inline
                                                        label="N/A"
                                                        name="stockProd"
                                                        type="radio"
                                                        //disabled={naLocal === 15 ? true : false}
                                                        value={0}
                                                        onChange={() => setNaStockLocal(5)}
                                                        checked={stock === 0 ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        className='text-danger'
                                                        inline
                                                        label="1"
                                                        name="stockProd"
                                                        type="radio"
                                                        value={1}
                                                        onChange={handleStock}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={stock === '1' ? 'checked' : ''}

                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="2"
                                                        name="stockProd"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={2}
                                                        onChange={handleStock}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={stock === '2' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="3"
                                                        name="stockProd"
                                                        type="radio"
                                                        className='text-danger'
                                                        value={3}
                                                        onChange={handleStock}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={stock === '3' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="4"
                                                        name="stockProd"
                                                        type="radio"
                                                        className='text-success'
                                                        value={4}
                                                        onChange={handleStock}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={stock === '4' ? 'checked' : ''}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="5"
                                                        name="stockProd"
                                                        type="radio"
                                                        className='text-success'
                                                        value={5}
                                                        onChange={handleStock}
                                                        //disabled={naLocal === 15 ? true : false}
                                                        checked={stock === '5' ? 'checked' : ''}
                                                    />
                                                    {/* <p className="text-center">{naLocal === 15 ? "NON APPLICABLE" : ""}</p> */}

                                                </div>
                                            ))}
                                        </FormGroup>
                                        <InputGroup className="m-auto mt-5 mb-5 p-3">
                                            <InputGroup.Text>Total Local de Nettoyage / Chariots / Produits</InputGroup.Text>
                                            <Form.Control type="Texte" value={naLocalTotal !== 15 || totalLocal !== 0 ? `${totalLocal} / ${localTotal}`: 'NON APPLICABLE'} readOnly={true} />
                                        </InputGroup>
                                    </AccordionDetails>
                                </Accordion> 
                            </div>



                            <InputGroup className='mb-5 p-3 mt-5'>
                                <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                                <FormControl as="textarea" value={remarks} onChange={(event) => { setRemarks(event.target.value) }} aria-label="With textarea" />
                            </InputGroup>

                            <InputGroup className='mb-5 p-3' >
                                <InputGroup.Text>Actions à prendre</InputGroup.Text>
                                <FormControl as="textarea" name="actions" value={action} onChange={(event) => { setAction(event.target.value) }} aria-label="With textarea" />
                            </InputGroup>



                            <InputGroup className="m-auto mt-5 ">
                                <InputGroup.Text>Evaluation Générale</InputGroup.Text>
                                <Form.Control type="Texte" value={totalEval + perc} readOnly={true} />
                            </InputGroup>
                            {<h3 className={totalEval < 70 ? 'text-center text-danger mb-5 p-3' : 'text-center text-success mb-5 p-3'} >{totalEval < 70 ? "Insatisfaisant" : totalEval < 80 ? "Satisfaisant" : "Très bien"}</h3>}



                            <InputGroup className="mb-5 p-3">
                                <InputGroup.Text>Nom Client</InputGroup.Text>
                                <Form.Control type="Texte" value={clientName} onChange={(event) => { setClientName(event.target.value) }} />
                            </InputGroup>

                            <p className='text-center h5'>Signature du Client</p>
                            <p className='text-secondary text-center mb-5 printP'>La signature n'est pas modifiable</p>
                            <div className="signature">
                                <img src={signature} alt="" />
                            </div>

                            <h5>Marquer ce rapport comme "Vérifié" : </h5>
                            <ToggleButton
                                id="toggle-check"
                                type="checkbox"
                                variant="warning"
                                value={checked}
                                onChange={(e) => setChecked("Vérifié")}
                                className={checked === "Vérifié" ? "mb-3 text-white bg-success h6" : "bg-warning text-dark"}
                            >
                                {checked === undefined || checked === '' ? "Non Vérifié" : checked}
                            </ToggleButton>
                            <p>Cliquer sur Modifier pour valider</p>
                            <div className='mt-5 mb-5'>
                                <button className="button" type="submit"> Modifier</button>
                            </div>

                        </Form>
                    </Container>
                    <div className="p-2 h5 m-2 text-center">

                        {message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>{message?.msg}</Alert>)}
                    </div>
                </div>
            </div>
        )
    }
}

export default SuiviQualiteSingle