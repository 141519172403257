import './widget.scss'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import NotificationDataService from '../../services/notificationsService'


function Widget({type}){

    let data;


    switch(type){
        case "rapportsPassage":
            data={
                title: "RAPPORTS DE PASSAGE",
                link:"Voir tous les rapports",
                 
                
            };
            break;
        case "rapportsMateriel":
            data={
                title: "CHECKS MATERIEL",
                link: "Voir tous les rapports",
                
            };
            break;
        case "rapportsQualite":
            data={
                title: "CONTROLE QUALITE",
                link: "Voir tous les rapports",
                
            };
            break
        default:
            break;

    }

    const [rapportsP, setRapportsP] = useState([])
    const [rapportsQ,setRapportsQ] = useState([])
    const [rapportsM,setRapportsM] = useState([])

    


    useEffect(() => {
        getRapports()
    }, [])

    const getRapports = async () => {
        const dataP = await NotificationDataService.getPassageNotifications()
        setRapportsP(dataP.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
       

        const dataQ = await NotificationDataService.getQualityNotifications()
        setRapportsQ(dataQ.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        

        const dataM = await NotificationDataService.getMaterieleNotifications()
        setRapportsM(dataM.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        
    }

    const deleteRapport = async (id) => {
        await NotificationDataService.deleteNotification(id)
        
    }

    const handleClick = async () => {
        if(data.title ==="RAPPORTS DE PASSAGE"){
            rapportsP.map((rapportP) => {
               deleteRapport(rapportP.id)
            })
        }
        else if(data.title === "CHECKS MATERIEL"){
            rapportsM.map((rapportM) => {
                deleteRapport(rapportM.id)
            })
        }
        else {
            rapportsQ.map((rapportQ) => {
                deleteRapport(rapportQ.id)
            })
        }
       
    }
   
    return(
        <div className="widget">
             <div className="left">
                { data.title === "RAPPORTS DE PASSAGE" ? <span className="title">{data.title} 
                <NotificationsOutlinedIcon style={{marginLeft:"60px"}}
                />{rapportsP.length > 0 ?<div className="counter">{rapportsP.length}</div>: ""}</span> : data.title === "CHECKS MATERIEL" ? <span className="title">{data.title} 
                <NotificationsOutlinedIcon style={{marginLeft:"60px"}}
                />{rapportsM.length > 0 ?<div className="counter">{rapportsM.length}</div>: ""}</span> : <span className="title">{data.title} 
                <NotificationsOutlinedIcon style={{marginLeft:"60px"}}
                />{rapportsQ.length > 0 ?<div className="counter">{rapportsQ.length}</div>: ""}</span>}
                
                <span className="link"><Link onClick={handleClick} to={data.title ==="RAPPORTS DE PASSAGE" ? "/listRapportsPassage" : data.title === "CHECKS MATERIEL" ? "/listCheckMateriels" : "/listSuiviQualite"} style={{textDecoration:"none",color:"#555",cursor:"pointer"}}> {data.link} </Link></span>
            </div>
            
        </div>
    )
}

export default Widget

