export const productsColumns = [

    {
        field: "product",
        headerName: "Article",
        width: 350,

    },
    {
        field: "packing",
        headerName: "par",
        width: 180,

    },
    {
        field: "category",
        headerName: "catégorie",
        width: 200,

    }

]


