import { db } from '../firebase-config'
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, getDoc, orderBy, startAt, endAt,where} from 'firebase/firestore'

const checksMaterielCollectionRef = collection(db,'checksMateriel')

class ChecksMaterielDataService {

    addCheckMateriel = ( newCheckMateriel) => {
        return addDoc(checksMaterielCollectionRef,newCheckMateriel)
    }

    updateCheckMateriel = (id,updatedCheckMateriel) => {
        const checkMaterielDoc = doc(db,"checksMateriel",id)
        return updateDoc(checkMaterielDoc,updatedCheckMateriel)
    }

    deleteCheckMateriel = (id) => {
        const checkMaterielDoc = doc(db,"checksMateriel",id) 
        return deleteDoc(checkMaterielDoc)
    }

    getChecksMateriel = () => {
        const sortedChecks = query (checksMaterielCollectionRef,orderBy("createdAt","desc"))
        return getDocs(sortedChecks)
    }

     getChecksMaterielWithinDateRange =  (startDate,endDate) => {
        const sortedChecks = query (checksMaterielCollectionRef,orderBy('date'),startAt(startDate),endAt(endDate))
        return getDocs(sortedChecks)
        
    }

    getChecksMaterielByClient = (client) => {
        const checksMaterielByClient = query(checksMaterielCollectionRef,where("client","==",client),where("checked","==","Vérifié"))
        return getDocs(checksMaterielByClient)
    }
    

    getCheckMateriel = (id) => {
        const checkMaterielDoc = doc(db,"checksMateriel",id)
        return getDoc(checkMaterielDoc) 
    }


    
}

export default new ChecksMaterielDataService()