import { db } from '../firebase-config'
import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc, query, where, orderBy } from 'firebase/firestore'

const notificaionsCollectionRef = collection(db,"notifications")


class NotificationDataService {
    addNotification = ( newNotification ) => {
        return addDoc(notificaionsCollectionRef,newNotification)
    }

    
    deleteNotification = (id) => {
        const notificationDoc = doc(db,"notifications",id)
        return deleteDoc(notificationDoc)
    }

    getPassageNotifications = () => {
        const passageNotifications = query(notificaionsCollectionRef,where('type','==','passage'))
        return getDocs(passageNotifications)
    }

    getMaterieleNotifications = () => {
        const materielNotifications = query(notificaionsCollectionRef,where('type','==','materiel'))
        return getDocs(materielNotifications)
    }

    getQualityNotifications = () => {
        const qualityNotifications = query(notificaionsCollectionRef,where('type','==','quality'))
        return getDocs(qualityNotifications)
    }

    
}

export default new NotificationDataService()