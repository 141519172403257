


import { Form, Button, Container, InputGroup } from 'react-bootstrap'
import { useState, useEffect, useRef } from 'react'
import SuiviQualiteDataService from '../../services/suiviQualiteServices'
import { useReactToPrint } from 'react-to-print'
import TextareaAutosize from '@mui/base/TextareaAutosize'


function ClientQualiteSingle({ id, close }) {


    const [date, setDate] = useState('')
    const [inspector, setInspector] = useState('')
    const [client, setClient] = useState("")
    const [localisation, setLocalisation] = useState('')
    const [present, setPresent] = useState('')
    const [remarks, setRemarks] = useState('')
    const [action, setAction] = useState('')
    const [clientName, setClientName] = useState('')
    const [signature, setSignature] = useState('')
    const [message, setMessage] = useState({ error: false, msg: "" })
    

    //Accordeon - Hall d'accueil
    const [poubelleHall, setPoubelleHall] = useState(0)
    const [cendrierHall, setCendrierHall] = useState(0)
    const [portVitrHall, setPortVitrHall] = useState(0)
    const [mobilierHall, setMobilierHall] = useState(0)
    const [appuiHall, setAppuiHall] = useState(0)
    const [radiateurHall, setRadiateurHall] = useState(0)
    const [armoireHall, setArmoireHall] = useState(0)
    const [priseHall, setPriseHall] = useState(0)
    const [porteHall, setPorteHall] = useState(0)
    const [poussiereHall, setPoussiereHall] = useState(0)
    const [solHall, setSolHall] = useState(0)
    const [plintheHall, setPlintheHall] = useState(0)
    const [totalHall, setTotalHall] = useState(0)

    //non apllicable hall
    const [naHallTotal, setNaHallTotal] = useState(0)

    //Accordeon Réception
    const [poubelleReception, setPoubelleReception] = useState(0)
    const [bureauReception, setBureauReception] = useState(0)
    const [phoneReception, setPhoneReception] = useState(0)
    const [objetReception, setObjetReception] = useState(0)
    const [siegeReception, setSiegeReception] = useState(0)
    const [appuiReception, setAppuiReception] = useState(0)
    const [radiateurReception, setRadiateurReception] = useState(0)
    const [armoireReception, setArmoireReception] = useState(0)
    const [priseReception, setPriseReception] = useState(0)
    const [porteReception, setPorteReception] = useState(0)
    const [poussiereReception, setPoussiereReception] = useState(0)
    const [solReception, setSolReception] = useState(0)
    const [plintheReception, setPlintheReception] = useState(0)
    const [totalReception, setTotalReception] = useState(0)

    //non applicable Reception
    const [naReceptTotal, setNaReceptTotal] = useState(0)

    //Accordeon Bureaux
    const [poubelleBureau, setPoubelleBureau] = useState(0)
    const [bureauBureau, setBureauBureau] = useState(0)
    const [phoneBureau, setPhoneBureau] = useState(0)
    const [objetBureau, setObjetBureau] = useState(0)
    const [siegeBureau, setSiegeBureau] = useState(0)
    const [appuiBureau, setAppuiBureau] = useState(0)
    const [radiateurBureau, setRadiateurBureau] = useState(0)
    const [armoireBureau, setArmoireBureau] = useState(0)
    const [priseBureau, setPriseBureau] = useState(0)
    const [porteBureau, setPorteBureau] = useState(0)
    const [poussiereBureau, setPoussiereBureau] = useState(0)
    const [solBureau, setSolBureau] = useState(0)
    const [plintheBureau, setPlintheBureau] = useState(0)
    const [totalBureaux, setTotalBureaux] = useState(0)

    //non applicable Bureaux
    const [naBureauTotal, setNaBureauTotal] = useState(0)

    //Accordeon Sanitaires
    const [lavaboSanit, setLavaboSanit] = useState(0)
    const [robinetSanit, setRobinetSanit] = useState(0)
    const [miroirSanit, setMiroirSanit] = useState(0)
    const [distributeurSanit, setDistributeurSanit] = useState(0)
    const [poubelleSanit, setPoubelleSanit] = useState(0)
    const [wcSanit, setWcSanit] = useState(0)
    const [lunetteSanit, setLunetteSanit] = useState(0)
    const [urinoirSanit, setUrinoirSanit] = useState(0)
    const [doucheSanit, setDoucheSanit] = useState(0)
    const [cloisonSanit, setCloisonSanit] = useState(0)
    const [radiateurSanit, setRadiateurSanit] = useState(0)
    const [solSanit, setSolSanit] = useState(0)
    const [plintheSanit, setPlintheSanit] = useState(0)
    const [totalSanitaires, setTotalSanitaires] = useState(0)

    //non applicable Sanitaires
    const [naSanitTotal, setNaSanitTotal] = useState(0)

    //Accordeon Salles de Réunion 
    const [poubelleReunion, setPoubelleReunion] = useState(0)
    const [bureauReunion, setBureauReunion] = useState(0)
    const [phoneReunion, setPhoneReunion] = useState(0)
    const [objetReunion, setObjetReunion] = useState(0)
    const [siegeReunion, setSiegeReunion] = useState(0)
    const [appuiReunion, setAppuiReunion] = useState(0)
    const [radiateurReunion, setRadiateurReunion] = useState(0)
    const [armoireReunion, setArmoireReunion] = useState(0)
    const [priseReunion, setPriseReunion] = useState(0)
    const [porteReunion, setPorteReunion] = useState(0)
    const [poussiereReunion, setPoussiereReunion] = useState(0)
    const [solReunion, setSolReunion] = useState(0)
    const [plintheReunion, setPlintheReunion] = useState(0)
    const [totalReunion, setTotalReunion] = useState(0)

    //non applicable Reunion
    const [naReunionTotal, setNaReunionTotal] = useState(0)


    //Accordeon Douches / Vestiaires
    const [lavaboDouche, setLavaboDouche] = useState(0)
    const [robinetDouche, setRobinetDouche] = useState(0)
    const [miroirDouche, setMiroirDouche] = useState(0)
    const [distributeurDouche, setDistributeurDouche] = useState(0)
    const [poubelleDouche, setPoubelleDouche] = useState(0)
    const [wcDouche, setWcDouche] = useState(0)
    const [lunetteDouche, setLunetteDouche] = useState(0)
    const [urinoirDouche, setUrinoirDouche] = useState(0)
    const [doucheDouche, setDoucheDouche] = useState(0)
    const [cloisonDouche, setCloisonDouche] = useState(0)
    const [radiateurDouche, setRadiateurDouche] = useState(0)
    const [solDouche, setSolDouche] = useState(0)
    const [plintheDouche, setPlintheDouche] = useState(0)
    const [totalDouche, setTotalDouche] = useState(0)

    //non applicable Douches
    const [naDoucheTotal, setNaDoucheTotal] = useState(0)

    //Accordeon Réfectoire 
    const [solRefect, setSolRefect] = useState(0)
    const [bordRefect, setBordRefect] = useState(0)
    const [porteRefect, setPorteRefect] = useState(0)
    const [tableRefect, setTableRefect] = useState(0)
    const [chaiseRefect, setChaiseRefect] = useState(0)
    const [depoussierageRefect, setDepoussierageRefect] = useState(0)
    const [totalRefect, setTotalRefect] = useState(0)

    //non applicable Refectoire
    const [naRefectTotal, setNaRefectTotal] = useState(0)

    //Accordeon Cuisine 
    const [solCuisine, setSolCuisine] = useState(0)
    const [bordCuisine, setBordCuisine] = useState(0)
    const [porteCuisine, setPorteCuisine] = useState(0)
    const [tableCuisine, setTableCuisine] = useState(0)
    const [chaiseCuisine, setChaiseCuisine] = useState(0)
    const [depoussierageCuisine, setDepoussierageCuisine] = useState(0)
    const [totalCuisine, setTotalCuisine] = useState(0)

    //non applicable Cuisine
    const [naCuisineTotal, setNaCuisineTotal] = useState(0)

    //Accordeon Circulations
    const [solCirculation, setSolCirculation] = useState(0)
    const [bordCirculation, setBordCirculation] = useState(0)
    const [porteCirculation, setPorteCirculation] = useState(0)
    const [cadreCirculation, setCadreCirculation] = useState(0)
    const [priseCirculation, setPriseCirculation] = useState(0)
    const [plintheCirculation, setPlintheCirculation] = useState(0)
    const [totalCirculation, setTotalCirculation] = useState(0)

    //non applicable Circulations
    const [naCirculTotal, setNaCirculTotal] = useState(0)

    //Accordeon Ascenseur
    const [solAscenseur, setSolAscenseur] = useState(0)
    const [bordAscenseur, setBordAscenseur] = useState(0)
    const [porteAscenseur, setPorteAscenseur] = useState(0)
    const [miroirAscenseur, setMiroirAscenseur] = useState(0)
    const [parIntAscenseur, setParIntAscenseur] = useState(0)
    const [parExtAscenseur, setParExtAscenseur] = useState(0)
    const [depoussierageAscenseur, setDepoussierageAscenseur] = useState(0)
    const [totalAscenseur, setTotalAscenseur] = useState(0)

    //non applicable Ascenseur
    const [naAscenseurTotal, setNaAscenseurTotal] = useState(0)

    //Accordeon Escaliers
    const [solEscalier, setSolEscalier] = useState(0)
    const [bordEscalier, setBordEscalier] = useState(0)
    const [porteEscalier, setPorteEscalier] = useState(0)
    const [cadreEscalier, setCadreEscalier] = useState(0)
    const [rampeEscalier, setRampeEscalier] = useState(0)
    const [totalEscalier, setTotalEscalier] = useState(0)

    //non applicable Escaliers
    const [naEscalTotal, setNaEscalTotal] = useState(0)

    //Accordeon Parking
    const [visuelParking, setVisuelParking] = useState(0)
    const [poubelleParking, setPoubelleParking] = useState(0)
    const [totalParking, setTotalParking] = useState(0)

    //non applicable Parking
    const [naParkingTotal, setNaParkingTotal] = useState(0)

    //Accordeon Local de Nettoyage / Chariots / Produits
    const [local, setLocal] = useState(0)
    const [chariot, setChariot] = useState(0)
    const [stock, setStock] = useState(0)
    const [totalLocal, setTotalLocal] = useState(0)

    //non applicable Local
    const [naLocalTotal, setNaLocalTotal] = useState(0)

    //total Evaluation générale
    const [totalEval, setTotalEval] = useState(0)

    
    const hallTotal = 60 - naHallTotal 

    const receptionTotal = 65 - naReceptTotal

    const bureauxTotal = 65 - naBureauTotal

    const sanitairesTotal = 65 - naSanitTotal

    const reunionTotal = 65 - naReunionTotal

    const doucheTotal = 65 - naDoucheTotal

    const refectTotal = 30 - naRefectTotal

    const cuisineTotal = 30 - naCuisineTotal

    const circulationsTotal = 30 - naCirculTotal

    const ascenseurTotal = 35 - naAscenseurTotal

    const escaliersTotal = 25 - naEscalTotal

    const parkingTotal = 10 - naParkingTotal

    const localTotal = 15 - naLocalTotal

    const perc = '%'

    
    const handleEdit = async () => {
        setMessage("")
        try {
            const docSnap = await SuiviQualiteDataService.getSuiviQualite(id)
            setDate(docSnap.data().date)
            setInspector(docSnap.data().inspector)
            setClient(docSnap.data().client)
            setLocalisation(docSnap.data().localisation)
            setPresent(docSnap.data().present)
            setRemarks(docSnap.data().remarks)
            setAction(docSnap.data().action)
            setClientName(docSnap.data().clientName)
            setSignature(docSnap.data().signature)
            //Hall
            setPoubelleHall(docSnap.data().poubelleHall)
            setCendrierHall(docSnap.data().cendrierHall)
            setPortVitrHall(docSnap.data().portVitrHall)
            setMobilierHall(docSnap.data().mobilierHall)
            setAppuiHall(docSnap.data().appuiHall)
            setRadiateurHall(docSnap.data().radiateurHall)
            setArmoireHall(docSnap.data().armoireHall)
            setPriseHall(docSnap.data().priseHall)
            setPorteHall(docSnap.data().porteHall)
            setPoussiereHall(docSnap.data().poussiereHall)
            setSolHall(docSnap.data().solHall)
            setPlintheHall(docSnap.data().plintheHall)
            setTotalHall(docSnap.data().totalHall)
            setNaHallTotal(docSnap.data().naHallTotal)
            //Réception
            setPoubelleReception(docSnap.data().poubelleReception)
            setBureauReception(docSnap.data().bureauReception)
            setPhoneReception(docSnap.data().phoneReception)
            setObjetReception(docSnap.data().objetReception)
            setSiegeReception(docSnap.data().siegeReception)
            setAppuiReception(docSnap.data().appuiReception)
            setRadiateurReception(docSnap.data().radiateurReception)
            setArmoireReception(docSnap.data().armoireReception)
            setPriseReception(docSnap.data().priseReception)
            setPorteReception(docSnap.data().porteReception)
            setPoussiereReception(docSnap.data().poussiereReception)
            setSolReception(docSnap.data().solReception)
            setPlintheReception(docSnap.data().plintheReception)
            setTotalReception(docSnap.data().totalReception)
            setNaReceptTotal(docSnap.data().naReceptTotal)
            //Bureaux
            setPoubelleBureau(docSnap.data().poubelleBureau)
            setBureauBureau(docSnap.data().bureauBureau)
            setPhoneBureau(docSnap.data().phoneBureau)
            setObjetBureau(docSnap.data().objetBureau)
            setSiegeBureau(docSnap.data().siegeBureau)
            setAppuiBureau(docSnap.data().appuiBureau)
            setRadiateurBureau(docSnap.data().radiateurBureau)
            setArmoireBureau(docSnap.data().armoireBureau)
            setPriseBureau(docSnap.data().priseBureau)
            setPorteBureau(docSnap.data().porteBureau)
            setPoussiereBureau(docSnap.data().poussiereBureau)
            setSolBureau(docSnap.data().solBureau)
            setPlintheBureau(docSnap.data().plintheBureau)
            setTotalBureaux(docSnap.data().totalBureaux)
            setNaBureauTotal(docSnap.data().naBureauTotal)
            //Sanitaires
            setLavaboSanit(docSnap.data().lavaboSanit)
            setRobinetSanit(docSnap.data().robinetSanit)
            setMiroirSanit(docSnap.data().miroirSanit)
            setDistributeurSanit(docSnap.data().distributeurSanit)
            setPoubelleSanit(docSnap.data().poubelleSanit)
            setWcSanit(docSnap.data().wcSanit)
            setLunetteSanit(docSnap.data().lunetteSanit)
            setUrinoirSanit(docSnap.data().urinoirSanit)
            setDoucheSanit(docSnap.data().doucheSanit)
            setCloisonSanit(docSnap.data().cloisonSanit)
            setRadiateurSanit(docSnap.data().radiateurSanit)
            setSolSanit(docSnap.data().solSanit)
            setPlintheSanit(docSnap.data().plintheSanit)
            setTotalSanitaires(docSnap.data().totalSanitaires)
            setNaSanitTotal(docSnap.data().naSanitTotal)
            //Salles de Réunion
            setPoubelleReunion(docSnap.data().poubelleReunion)
            setBureauReunion(docSnap.data().bureauReunion)
            setPhoneReunion(docSnap.data().phoneReunion)
            setObjetReunion(docSnap.data().objetReunion)
            setSiegeReunion(docSnap.data().siegeReunion)
            setAppuiReunion(docSnap.data().appuiReunion)
            setRadiateurReunion(docSnap.data().radiateurReunion)
            setArmoireReunion(docSnap.data().armoireReunion)
            setPriseReunion(docSnap.data().priseReunion)
            setPorteReunion(docSnap.data().porteReunion)
            setPoussiereReunion(docSnap.data().poussiereReunion)
            setSolReunion(docSnap.data().solReunion)
            setPlintheReunion(docSnap.data().plintheReunion)
            setTotalReunion(docSnap.data().totalReunion)
            setNaReunionTotal(docSnap.data().naReunionTotal)
            //Douches / Vestiaies
            setLavaboDouche(docSnap.data().lavaboDouche)
            setRobinetDouche(docSnap.data().robinetDouche)
            setMiroirDouche(docSnap.data().miroirDouche)
            setDistributeurDouche(docSnap.data().distributeurDouche)
            setPoubelleDouche(docSnap.data().poubelleDouche)
            setWcDouche(docSnap.data().wcDouche)
            setLunetteDouche(docSnap.data().lunetteDouche)
            setUrinoirDouche(docSnap.data().urinoirDouche)
            setDoucheDouche(docSnap.data().doucheDouche)
            setCloisonDouche(docSnap.data().cloisonDouche)
            setRadiateurDouche(docSnap.data().radiateurDouche)
            setSolDouche(docSnap.data().solDouche)
            setPlintheDouche(docSnap.data().plintheDouche)
            setTotalDouche(docSnap.data().totalDouche)
            setNaDoucheTotal(docSnap.data().naDoucheTotal)
            //Réfectoire
            setSolRefect(docSnap.data().solRefect)
            setBordRefect(docSnap.data().bordRefect)
            setPorteRefect(docSnap.data().porteRefect)
            setTableRefect(docSnap.data().tableRefect)
            setChaiseRefect(docSnap.data().chaiseRefect)
            setDepoussierageRefect(docSnap.data().depoussierageRefect)
            setTotalRefect(docSnap.data().totalRefect)
            setNaRefectTotal(docSnap.data().naRefectTotal)
            //Cuisine
            setSolCuisine(docSnap.data().solCuisine)
            setBordCuisine(docSnap.data().bordCuisine)
            setPorteCuisine(docSnap.data().porteCuisine)
            setTableCuisine(docSnap.data().tableCuisine)
            setChaiseCuisine(docSnap.data().chaiseCuisine)
            setDepoussierageCuisine(docSnap.data().depoussierageCuisine)
            setTotalCuisine(docSnap.data().totalCuisine)
            setNaCuisineTotal(docSnap.data().naCuisineTotal)
            //Circulations
            setSolCirculation(docSnap.data().solCirculation)
            setBordCirculation(docSnap.data().bordCirculation)
            setPorteCirculation(docSnap.data().porteCirculation)
            setCadreCirculation(docSnap.data().cadreCirculation)
            setPriseCirculation(docSnap.data().priseCirculation)
            setPlintheCirculation(docSnap.data().plintheCirculation)
            setTotalCirculation(docSnap.data().totalCirculation)
            setNaCirculTotal(docSnap.data().naCirculTotal)
            //Ascenseur
            setSolAscenseur(docSnap.data().solAscenseur)
            setBordAscenseur(docSnap.data().bordAscenseur)
            setPorteAscenseur(docSnap.data().porteAscenseur)
            setMiroirAscenseur(docSnap.data().miroirAscenseur)
            setParIntAscenseur(docSnap.data().parIntAscenseur)
            setParExtAscenseur(docSnap.data().parExtAscenseur)
            setDepoussierageAscenseur(docSnap.data().depoussierageAscenseur)
            setTotalAscenseur(docSnap.data().totalAscenseur)
            setNaAscenseurTotal(docSnap.data().naAscenseurTotal)
            //Escaliers
            setSolEscalier(docSnap.data().solEscalier)
            setBordEscalier(docSnap.data().bordEscalier)
            setPorteEscalier(docSnap.data().porteEscalier)
            setCadreEscalier(docSnap.data().cadreEscalier)
            setRampeEscalier(docSnap.data().rampeEscalier)
            setTotalEscalier(docSnap.data().totalEscalier)
            setNaEscalTotal(docSnap.data().naEscalTotal)
            //Parking
            setVisuelParking(docSnap.data().visuelParking)
            setPoubelleParking(docSnap.data().poubelleParking)
            setTotalParking(docSnap.data().totalParking)
            setNaParkingTotal(docSnap.data().naParkingTotal)
            //Local de Nettoyage / Chariots / Produits
            setLocal(docSnap.data().local)
            setChariot(docSnap.data().chariot)
            setStock(docSnap.data().stock)
            setTotalLocal(docSnap.data().totalLocal)
            setNaLocalTotal(docSnap.data().naLocalTotal)
            //Evaluation Générale
            setTotalEval(docSnap.data().totalEval)

        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }

    useEffect(() => {
        if (id !== undefined && id !== "") {
            handleEdit()
        }
    }, [id])

    console.log(naHallTotal)

    //imprimer le rapport, créer PDF 
    const componentRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `suivi qualité ${date}${client} `,
        

    })

    
        return (
            <>
                <Button className='m-5 bg-secondary' onClick={handlePrint} style={{ cursor: "pointer" }}>Print / PDF </Button>
                
                <div ref={componentRef} className='text-center'>
                    <h4 className='mb-5 '>Contrôle Qualité du {date}</h4>
                    <Container className='w-50 mb-4'>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Date</InputGroup.Text>
                            <Form.Control type="Texte" value={date} readOnly={true} />
                        </InputGroup>

                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Nom de l'inspecteur</InputGroup.Text>
                            <Form.Control type="Texte" value={inspector} readOnly={true} />
                        </InputGroup>

                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Client / Chantier</InputGroup.Text>
                            <Form.Control type="Texte" value={client} readOnly={true} />
                        </InputGroup>


                        <InputGroup className="mb-3 p-3" >
                            <InputGroup.Text>Localisation du contrôle</InputGroup.Text>
                            <Form.Control type="Texte" value={localisation} readOnly={true} />
                        </InputGroup>

                        <InputGroup className="mb-3 p-3" >
                            <InputGroup.Text>Présents</InputGroup.Text>
                            <Form.Control type="Texte" value={present} readOnly={true} />
                        </InputGroup>
                    </Container>
                    <div className='text-center mt-4 newPage'>
                        <h5>Hall d'accueil/entrée</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleHall > 0 ? poubelleHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Cendriers</td>
                                    <td>{cendrierHall > 0 ? cendrierHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes Vitrées</td>
                                    <td>{portVitrHall > 0 ? portVitrHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Mobilier</td>
                                    <td>{mobilierHall > 0 ? mobilierHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiHall > 0 ? appuiHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurHall > 0 ? radiateurHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireHall > 0 ? armoireHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseHall > 0 ? priseHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteHall > 0 ? porteHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereHall > 0 ? poussiereHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solHall > 0 ? solHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheHall > 0 ? plintheHall : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Hall / Entrée</strong></td>
                                    <td>{totalHall > 0 ? `${totalHall}  / ${hallTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Réception</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleReception  > 0 ? poubelleReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bureaux</td>
                                    <td>{bureauReception > 0 ? bureauReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Téléphones</td>
                                    <td>{phoneReception > 0 ? phoneReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Objets de bureau</td>
                                    <td>{objetReception > 0 ? objetReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sièges de bureau</td>
                                    <td>{siegeReception > 0 ? siegeReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiReception > 0 ? appuiReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurReception > 0 ? radiateurReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireReception > 0 ? armoireReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseReception > 0 ? priseReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteReception > 0 ? porteReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereReception > 0 ? poussiereReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solReception > 0 ? solReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheReception > 0 ? plintheReception : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Réception</strong></td>
                                    <td>{totalReception > 0 ? `${totalReception} / ${receptionTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Bureaux</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleBureau > 0 ? poubelleBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bureaux</td>
                                    <td>{bureauBureau > 0 ? bureauBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Téléphones</td>
                                    <td>{phoneBureau > 0 ? phoneBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Objets de bureau</td>
                                    <td>{objetBureau > 0 ? objetBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sièges de bureau</td>
                                    <td>{siegeBureau > 0 ? siegeBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiBureau > 0 ? appuiBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurBureau > 0 ? radiateurBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireBureau > 0 ? armoireBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseBureau > 0 ? priseBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteBureau > 0 ? porteBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereBureau > 0 ? poussiereBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solBureau > 0 ? solBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheBureau > 0 ? plintheBureau : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Bureaux</strong></td>
                                    <td>{totalBureaux > 0 ? `${totalBureaux} / ${bureauxTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Salles de Réunion</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleReunion > 0 ? poubelleReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bureaux</td>
                                    <td>{bureauReunion > 0 ? bureauReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Téléphones</td>
                                    <td>{phoneReunion > 0 ? phoneReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Objets de bureau</td>
                                    <td>{objetReunion > 0 ? objetReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sièges de bureau</td>
                                    <td>{siegeReunion > 0 ? siegeReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Appuis Fenêtres</td>
                                    <td>{appuiReunion > 0 ? appuiReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurReunion > 0 ? radiateurReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Armoires</td>
                                    <td>{armoireReunion > 0 ? armoireReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseReunion > 0 ? priseReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteReunion > 0 ? porteReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Hautes Poussières</td>
                                    <td>{poussiereReunion > 0 ? poussiereReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solReunion > 0 ? solReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheReunion > 0 ? plintheReunion : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Salles de Réunion</strong></td>
                                    <td>{totalReunion > 0 ? `${totalReunion} / ${reunionTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Sanitaires</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Lavabos</td>
                                    <td>{lavaboSanit > 0 ? lavaboSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Robinets / siphons</td>
                                    <td>{robinetSanit > 0 ? robinetSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Miroirs</td>
                                    <td>{miroirSanit > 0 ? miroirSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Distributeurs de savon / papier</td>
                                    <td>{distributeurSanit > 0 ? distributeurSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleSanit > 0 ? poubelleSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>WC</td>
                                    <td>{wcSanit > 0 ? wcSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Lunette WC</td>
                                    <td>{lunetteSanit > 0 ? lunetteSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Urinoirs</td>
                                    <td>{urinoirSanit > 0 ? urinoirSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Douches</td>
                                    <td>{doucheSanit > 0 ? doucheSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes / cloisons</td>
                                    <td>{cloisonSanit > 0 ? cloisonSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurSanit > 0 ? radiateurSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solSanit > 0 ? solSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheSanit  > 0 ? plintheSanit : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Sanitaires</strong></td>
                                    <td>{totalSanitaires > 0 ? `${totalSanitaires} / ${sanitairesTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Douches/Vestiares</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Lavabos</td>
                                    <td>{lavaboDouche > 0 ? lavaboDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Robinets / siphons</td>
                                    <td>{robinetDouche > 0 ? robinetDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Miroirs</td>
                                    <td>{miroirDouche > 0 ? miroirDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Distributeurs de savon / papier</td>
                                    <td>{distributeurDouche > 0 ? distributeurDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleDouche > 0 ? poubelleDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>WC</td>
                                    <td>{wcDouche > 0 ? wcDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Lunette WC</td>
                                    <td>{lunetteDouche > 0 ? lunetteDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Urinoirs</td>
                                    <td>{urinoirDouche > 0 ? urinoirDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Douches</td>
                                    <td>{doucheDouche > 0 ? doucheDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes / cloisons</td>
                                    <td>{cloisonDouche > 0 ? cloisonDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Radiateurs/Convecteurs</td>
                                    <td>{radiateurDouche > 0 ? radiateurDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solDouche > 0 ? solDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheDouche > 0 ? plintheDouche : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Douches/Vestiaires</strong></td>
                                    <td>{totalDouche > 0 ? `${totalDouche} / ${doucheTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Réfectoire</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solRefect > 0 ? solRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordRefect > 0 ? bordRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteRefect > 0 ? porteRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Tables</td>
                                    <td>{tableRefect > 0 ? tableRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Chaises</td>
                                    <td>{chaiseRefect > 0 ? chaiseRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Dépoussiérage en général</td>
                                    <td>{depoussierageRefect > 0 ? depoussierageRefect : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Réfectoire</strong></td>
                                    <td>{totalRefect > 0 ? `${totalRefect} / ${refectTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Cuisine</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solCuisine > 0 ? solCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordCuisine > 0 ? bordCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteCuisine > 0 ? porteCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Tables</td>
                                    <td>{tableCuisine > 0 ? tableCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Chaises</td>
                                    <td>{chaiseCuisine > 0 ? chaiseCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Dépoussiérage en général</td>
                                    <td>{depoussierageCuisine > 0 ? depoussierageCuisine : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Cuisine</strong></td>
                                    <td>{totalCuisine > 0 ? `${totalCuisine} / ${cuisineTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Circulations</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solCirculation > 0 ? solCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordCirculation > 0 ? bordCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteCirculation > 0 ? porteCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Encadrements</td>
                                    <td>{cadreCirculation > 0 ? cadreCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Interrupteurs/prises</td>
                                    <td>{priseCirculation > 0 ? priseCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Plinthes/coins</td>
                                    <td>{plintheCirculation > 0 ? plintheCirculation : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Circulations</strong></td>
                                    <td>{totalCirculation > 0 ? `${totalCirculation} / ${circulationsTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Ascenseur</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solAscenseur > 0 ? solAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordAscenseur > 0 ? bordAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteAscenseur > 0 ? porteAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Miroirs</td>
                                    <td>{miroirAscenseur > 0 ? miroirAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Paroi intérieure</td>
                                    <td>{parIntAscenseur > 0 ? parIntAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Paroi extérieure</td>
                                    <td>{parExtAscenseur > 0 ? parExtAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Dépoussiérage en général</td>
                                    <td>{depoussierageAscenseur > 0 ? depoussierageAscenseur : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Ascenseur</strong></td>
                                    <td>{totalAscenseur > 0 ? `${totalAscenseur} / ${ascenseurTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Escaliers</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sols</td>
                                    <td>{solEscalier > 0 ? solEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Bords / coins</td>
                                    <td>{bordEscalier > 0 ? bordEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Portes</td>
                                    <td>{porteEscalier > 0 ? porteEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Encadrements</td>
                                    <td>{cadreEscalier > 0 ? cadreEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Main courante</td>
                                    <td>{rampeEscalier > 0 ? rampeEscalier : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Escaliers</strong></td>
                                    <td>{totalEscalier > 0 ? `${totalEscalier} / ${escaliersTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4 newPage'>
                        <h5>Parking</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Visuel gros papiers</td>
                                    <td>{visuelParking > 0 ? visuelParking : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Poubelles</td>
                                    <td>{poubelleParking > 0 ? poubelleParking : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Parking</strong></td>
                                    <td>{totalParking > 0 ? `${totalParking} / ${parkingTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Local de Nettoyage / Chariots / Produits</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>items</th>
                                    <th>score sur 5</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Local de nettoyage</td>
                                    <td>{local > 0 ? local : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Propreté chariots / matériel</td>
                                    <td>{chariot > 0 ? chariot : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Stock Produits</td>
                                    <td>{stock > 0 ? stock : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td><strong>TOTAL Local de Nettoyage / Chariots / Produits</strong></td>
                                    <td>{totalLocal > 0 ? `${totalLocal} / ${localTotal}` : 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Container className='w-50 mb-4 mt-5 newPage'>
                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Constatations sur le nettoyage</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas de constatation particulière"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={remarks}
                                readOnly={true}
                            />
                        </InputGroup>

                        <InputGroup className='mb-1 p-3'>
                            <InputGroup.Text>Actions à prendre</InputGroup.Text>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="pas d'action requise"
                                style={{ width: '100%' }}
                                className='mb-5 p-3'
                                value={action}
                                readOnly={true}
                            />
                        </InputGroup>



                        <InputGroup className="m-auto mt-5 ">
                            <InputGroup.Text>Evaluation Générale</InputGroup.Text>
                            <Form.Control type="Texte" value={totalEval + perc} readOnly={true} />
                        </InputGroup>
                        {<h3 className={totalEval < 70 ? 'text-center text-danger mb-5 p-3' : 'text-center text-success mb-5 p-3'} >{totalEval < 70 ? "Insatisfaisant" : totalEval < 80 ? "Satisfaisant" : "Très bien"}</h3>}



                        <InputGroup className="mb-5 p-3">
                            <InputGroup.Text>Nom Client</InputGroup.Text>
                            <Form.Control type="Texte" value={clientName} readOnly={true} />
                        </InputGroup>

                        <p className='text-center h5'>Signature Client</p>

                        <div className="signature">
                            <img src={signature} alt="" />
                        </div>
                    </Container>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button>
                </div>

            </>
        )
    }
    export default ClientQualiteSingle