import HomeNavbar from '../../components/homeNavbar/HomeNavbar'
import HomeSidebar from '../../components/homeSidebar/HomeSidebar'
import './privateUsers.scss'
import { Alert } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { setDoc, doc, } from 'firebase/firestore'
import { auth, db } from '../../firebase-config'
import { createUserWithEmailAndPassword } from "firebase/auth";
import DataTablePrivateUsers from '../../components/dataTablePrivateUsers/DataTablePrivateUsers'
import ClientDataService from '../../services/clientsService'


function Users() {

    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }

    const [username, setUsername] = useState('')
    const [client, setClient] = useState('')
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState('')
    const [message, setMessage] = useState({ error: false, msg: "" })



    const [privateUserId, setPrivateUserId] = useState("")

    const handleGetPrivateUserId = (id) => {
        setPrivateUserId(id)

    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setMessage('')
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password)
            await setDoc(doc(db, "privateUsers", res.user.uid), {

                username: username,
                client: client,
                email: email,
                password: password,
                role: role
            })

            setMessage({ error: false, msg: "Le nouvel utilisateur a été créé" })

        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }



    }

    return (
        <div className="profile">
            <HomeSidebar />
            <div className="profileContainer">
                <HomeNavbar />
                <div className="top">
                    <h1>Créer un nouvel utilisateur</h1>
                </div>
                <div className="p-2 h4 m-5 text-center">
                    {message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => {
                        if (message?.error) {
                            setMessage('')
                        } else {
                            window.location.reload()
                        }

                    }


                    }>{message?.msg}</Alert>)}
                </div>

                <div className="body">
                    <form onSubmit={handleSubmit}>
                        <div className="formInput">
                            <label>Nom d'utilisateur</label>
                            <input type="text" onChange={(e) => setUsername(e.target.value)} />
                        </div>
                        <div className="formInput">
                            <label>Client (facultatif)</label>
                            {/* <input type="text" onChange={(e) => setClient(e.target.value)} /> */}
                            <select className='formInput' value={client} onChange={(e) => setClient(e.target.value)}>
                                <option>Choisir un client</option>
                                {clients.map((client) => (
                                    <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                ))}
                            </select>
                        </div>
                        <div className="formInput">
                            <label>Email</label>
                            <input type="email" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="formInput">
                            <label>mot de passe</label>
                            <input type="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>



                        <div className="formInput">
                            <label>Rôle</label>
                            <select value={role} onChange={(event) => { setRole(event.target.value) }}>
                                <option>Choisir un rôle</option>
                                <option>Admin</option>
                                <option>Employé</option>
                                <option>Client</option>
                            </select>
                        </div>

                        <button type="submit" className='submit'>Ajouter un utilisateur</button>
                    </form>
                </div>
                <DataTablePrivateUsers getPrivateUserId={handleGetPrivateUserId} />
            </div>

        </div>
    )
}

export default Users