import { Navigate } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'

const ProtectedRoute = ({ children }) => {

    let { user } = useUserAuth()

    const admin = localStorage.getItem("isAdmin")
   

    if (!user || !admin ) {
        return <Navigate to='/' />

    }
   
    else {

        return children

    }
    
}

export default ProtectedRoute