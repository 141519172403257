export const clientColumns = [
    
    {
        field: "client",
        headerName: "Nom de la firme",
        width: 180,
    },

    {
        field: "manager",
        headerName: "Responsable",
        width: 180,
    },
    {
        field: "email",
        headerName: "email",
        width: 230,
    },

    {
        field: "phone",
        headerName: "Téléphone",
        width: 150,
    },

    {
        field: "address",
        headerName: "Adresse",
        width: 250,
    },

    {
        field: "contract",
        headerName: "Révision",
        width: 100,
    },
    {
        field: "access",
        headerName: "Accès",
        width: 80,
    },
    
];

