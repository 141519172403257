export const privateUsersColumns = [
    {
        field: "username",
        headerName: "Nom d'utilisateur",
        width: 240,
    },
    {
        field: "client",
        headerName: "Client",
        width: 240,
    },
    

    {
        field: "email",
        headerName: "Email",
        width: 270,
    },
    {
        field: "password",
        headerName: "Mdp",
        width: 240,
    },
    {
        field: "role",
        headerName: "Rôle",
        width: 240,
    }
];

