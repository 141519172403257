import './homeNavbar.scss'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';
import { Link } from 'react-router-dom'


function HomeNavbar() {
    return (
        <div className="homeNavbar">
            <div className="wrapper">
                
                <div className="items">
                    
                    
                    <div className="item">
                        <Link to="/rapport_passage" style={{ textDecoration: "none", color: "#555", cursor: "pointer" }}>
                            <AutoStoriesOutlinedIcon className='icon' />
                            Passage
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="/check_materiel" style={{ textDecoration: "none", color: "#555", cursor: "pointer" }}>
                            <FactCheckOutlinedIcon className='icon' />
                            Matériel
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="/suivi_qualite" style={{ textDecoration: "none", color: "#555", cursor: "pointer" }}>
                            <AssistantPhotoOutlinedIcon className='icon' />
                            Qualité
                        </Link>
                    </div>
                    <div className="item">
                        <Link to="/acces_clients" style={{ textDecoration: "none", color: "#555", cursor: "pointer" }}>
                            <AssistantPhotoOutlinedIcon className='icon' />
                            Accès Clients
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNavbar