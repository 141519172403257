import './listCheckMateriels.scss'
import HomeSidebar from '../../components/homeSidebar/HomeSidebar'
import HomeNavbar from '../../components/homeNavbar/HomeNavbar'
import { useState, useEffect} from 'react'
import { Button } from 'react-bootstrap'
import DataTableCheckMateriels from '../../components/dataTableCheckMateriels/DataTableCheckMateriels'
import ChecksMaterielDataService from '../../services/checksMaterielServices'
import CheckMaterielSingle from '../rapports/checkMaterielSingle/CheckMaterielSingle'

function ListCheckMateriels(){

    const [rapports,setRapports] = useState([])

    useEffect(() => {
        getRapports()
    },[])

    const getRapports = async () => {
        const data = await ChecksMaterielDataService.getChecksMateriel()
        setRapports(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    const [rapportId, setRapportId] = useState('')

    const [open, setOpen] = useState(false)

    const handleGetRapportId = (id) => {
        setRapportId(id)
        setOpen(true)
    }

    const close = () => {
        setOpen(false)
    }

    const show = () => {
        setOpen(true)
    }


    return(
        <div className="checkMateriels">
            <HomeSidebar/>
            <div className="checkMaterielsContainer">
                <HomeNavbar/>
                <h1 className='text-center mt-5'>Contrôle Matériel - Produits</h1>
                
                {(open === false ) ? <DataTableCheckMateriels getRapportId={handleGetRapportId} open={show}/> : <CheckMaterielSingle id={rapportId} setRapportId={setRapportId} />}
                {(open === true ) ? <div className='mb-2'>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button></div> : ""}
            </div>
        </div>
    )
}

export default ListCheckMateriels