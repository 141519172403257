import HomeNavbar from '../../components/homeNavbar/HomeNavbar'
import HomeSidebar from '../../components/homeSidebar/HomeSidebar'
import { Button } from 'react-bootstrap'
import './listRapportsPassage.scss'
import { useState, useEffect } from 'react' 
import DataTableRapportsPassages from '../../components/dataTableRapportsPassage/DataTableRapportsPassages'
import RapportsPasagesDataService from '../../services/rapportsPassagesServices'
import RapportPassageSingle from '../rapports/rapportPassageSingle/RapportPassageSingle'

function ListRapportsPassage() {

    const [rapports,setRapports] = useState([])
    

    useEffect(() => {
        getRapports()
    },[])

    const getRapports = async () => {
        const data = await RapportsPasagesDataService.getRapportsPassages()
        setRapports(data.docs.map((doc) => ({...doc.data(), id: doc.id})))
    }

    const [rapportId, setRapportId] = useState('')

    const [open, setOpen] = useState(false)

    const handleGetRapportId = (id) => {
        setRapportId(id)
        setOpen(true)
    }
    
    const close = () => {
        setOpen(false)
    }

    

    const show = () => {
     setOpen(true)
    }

    

    
    return (
        <div className="rapportsPassage">
            <HomeSidebar />
            <div className="rapportsPassageContainer">
                <HomeNavbar />
                <h1 className='text-center mt-5'>Rapports de passage</h1>
                
                {(open === false ) ? <DataTableRapportsPassages getRapportId={handleGetRapportId} open={show}/> : <RapportPassageSingle id={rapportId} setRapportId={setRapportId} />}
                {(open === true ) ? <div className='mb-2'>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button></div> : ""}
        
               
            </div>

        </div>
    )
}

export default ListRapportsPassage