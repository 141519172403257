import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LabelList
  } from "recharts";
  import productDataService from '../../services/productsServices'
  import { useState, useEffect } from "react";
  
  
  
  
  function BarChartMat({array,client,category}) {
  
   
    const [materiels, setMateriels] = useState([])
  
  
    useEffect(() => {
      getMateriels()
  
    }, [])
  
    const getMateriels = async () => {
        const dataMateriel = await productDataService.getOnlyMateriel()
        setMateriels(dataMateriel.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
  
  
    const data = materiels.map((materiel,index) => {
  
      return {
        key: [materiel.id],
        name: [materiel.product],
        pv: Math.max(...array) + 6,
        qty: array[index]
      }
  
    })
  
  
    return (
      <>
      <h2 className="text-center mt-5">{category} : {client}</h2>
        
        <BarChart
  
          width={1200}
          height={1000}
          data={data}
          margin={{
            top: 15,
            right: 5,
            left: 5,
            bottom: 15
          }}
          layout="vertical"
          barSize={30}
        >
          <XAxis type="number" dataKey="pv" orientation="top" stroke="#285A64" />
          <YAxis type="category" dataKey="name" axisLine={false} dx={-10} tickLine={false} style={{ fill: "#285A64",display:'none' }} interval={0} />
  
          <Tooltip />
          <Legend dataKey='name'/>
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="qty" fill="#8884d8" background={{ fill: "#eee" }}>
            <LabelList dataKey='name' position="insideLeft" style={{ fill: "white",fontSize:"15px" }} /> 
            <LabelList dataKey='qty' position="insideRight" style={{ fill: "white" }} />
  
          </Bar>
  
        </BarChart>
  
        <button className="mt-3 p-3 text-white" style={{backgroundColor:"lightseagreen",bordeRadius:"5px",border:"none",cursor:"pointer"}} onClick={() => window.location.reload()}>
        Retour à la séléction
        </button>
      </>
    );
  }
  
  export default BarChartMat
  