import { db } from '../firebase-config'
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where, getDoc, orderBy} from 'firebase/firestore'

const rapportsPassagesCollectionRef = collection(db,'rapportsPassages')

class RapportsPassagesDataService {

    addRapportPassage = ( newRapportPassage) => {
        return addDoc(rapportsPassagesCollectionRef,newRapportPassage)
    }

    updateRapportPassage = (id,updatedRapportPassge) => {
        const rapportPassageDoc = doc(db,"rapportsPassages",id)
        return updateDoc(rapportPassageDoc,updatedRapportPassge)
    }

    deleteRapportPassage = (id) => {
        const rapportPassageDoc = doc(db,"rapportsPassages",id) 
        return deleteDoc(rapportPassageDoc)
    }

    getRapportsPassages = () => {
        const rapportsByDate = query (rapportsPassagesCollectionRef,orderBy("createdAt","desc"))
        return getDocs(rapportsByDate)
    }

    getRapportPassage = (id) => {
        const rapportPassageDoc = doc(db,"rapportsPassages",id)
        return getDoc(rapportPassageDoc) 
    }

    getRapportsByClient = (client) => {
        const rapportsPassagesByClient = query(rapportsPassagesCollectionRef,where("client","==",client),where("checked","==","Vérifié"))
       return getDocs(rapportsPassagesByClient)
    }
    
}

export default new RapportsPassagesDataService()