export const passageColumns = [
    
    
    {
        field: "date",
        headerName: "date",
        headerClassName: "header",
        width: 100,
        
    },
    {
        field: "client",
        headerName: "client",
        width: 230,
    },
    {
        field: "present",
        headerName: "Présents",
        width: 160,
    },
    {
        field: "clothes",
        headerName: "Tenue",
        width: 70,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.clothes}`}>
                    {params.row.clothes}
                </div>
            );
        },
    },
    {
        field: "presentation",
        headerName: "Personnel",
        width: 80,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.presentation}`}>
                    {params.row.presentation}
                </div>
            );
        },
    },
    {
        field: "cart",
        headerName: "Chariot",
        width: 70,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.cart}`}>
                    {params.row.cart}
                </div>
            );
        },
    },
    {
        field: "products",
        headerName: "Produits",
        width: 100,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.products}`}>
                    {params.row.products}
                </div>
            );
        },
    },
    {
        field: "colors",
        headerName: "Couleurs",
        width: 85,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.colors}`}>
                    {params.row.colors}
                </div>
            );
        },
    },
    
    {
        field: "sent",
        headerName: "Envoyé ?",
        width: 85,
        
    }
    
]

export const materielColumns = [
    
    
    {
        field: "date",
        headerName: "date",
        width: 250,
    },
    {
        field: "client",
        headerName: "client",
        width: 250,
    },
    
    {
        field: "doneBy",
        headerName: "Etabli par",
        width: 250,
    },
    {
        field: "sent",
        headerName: "Envoyé ?",
        width: 250,
        
    }

]

export const qualiteColumns = [
    
    
    {
        field: "date",
        headerName: "date",
        width: 150,
    },
    {
        field: "inspector",
        headerName: "Inspecteur",
        width: 150,
    },
    {
        field: "client",
        headerName: "client",
        width: 230,
    },
    {
        field: "present",
        headerName: "Présents",
        width: 230,
    },
    
    {
        field: "totalEval",
        headerName: "Evaluation",
        width: 220,
        renderCell: (params) => {
            return (
                params.row.totalEval < 70 ? <div className={`cellWithStatus ${params.row.totalEval}`}>
                    <p className="text-danger">{params.row.totalEval} %  Insatisfaisant</p></div> : params.row.totalEval < 80 ?<div className={`cellWithStatus ${params.row.totalEval}`}><p className="text-success">{params.row.totalEval} %  Satisfaisant</p></div> : <div className={`cellWithStatus ${params.row.totalEval}`}><p className="text-success">{params.row.totalEval} %  Très bien</p></div>
            );
        },
    },
    {
        field: "sent",
        headerName: "Envoyé ?",
        width: 85,
        
    }
]

