import './navigation.scss'
import Navbar from 'react-bootstrap/Navbar'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useUserAuth } from '../../context/UserAuthContext'




function Navigation() {

    const { logOut } = useUserAuth()

    const handleLogOut = async () => {
        try {
            localStorage.removeItem('isWorker')
            await logOut()
        }
        catch (err) {
            console.log(err.message)
        }
    }

    return (
        <div>
            <div className="mb-5">
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Navbar.Brand>
                       {' '}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            
                            <NavLink to="/rapport_passage" className={({isActive}) => (isActive ? "active" : "notActive")}>Rapport de passage</NavLink>
                            <NavLink to="/check_materiel" className={({isActive}) => (isActive ? "active" : "notActive")}>Check Matériel</NavLink>
                            <NavLink to="/suivi_qualite" className={({isActive}) => (isActive ? "active" : "notActive")}>Suivi-Qualité</NavLink>
                            <Nav.Link className='bg-warning text-dark ms-5' onClick= {handleLogOut}>Déconnexion</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    )
}

export default Navigation