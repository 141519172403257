import { useState } from 'react'

const CopyText = ({clientEmail}) => {

    const [isCopied, setIsCopied] = useState(false)
    const handleCopy = () => {
        navigator.clipboard.writeText(clientEmail)
        setIsCopied(true)
    }

    return (
        <div style={{background:'lightgray'}} className='w-25 p-4 text-center'>
            <input value={clientEmail} readOnly={true} className='border border-dark p-1'/>
            <button onClick={handleCopy} className='bg-primary text-white mt-3'>{isCopied === true ? 'Copié !' : 'Copier'}</button>
        </div>
    )
}

export default CopyText