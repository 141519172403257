import './newClient.scss'
import { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import ClientDataService from '../../services/clientsService'

const NewClient = ({ id, setClientId }) => {

    const [client, setClient] = useState("")
    const [manager, setManager ] = useState("")
    const [email, setEmail ] = useState("")
    const [password, setPassword] = useState("")
    const [access, setAccess] = useState("")
    const [phone, setPhone ] = useState("")
    const [address, setAddress ] = useState("")
    const [contract,setContract ] = useState("")
    const [status, setStatus ] = useState("")

    const [message, setMessage ] = useState({error: false, msg:""})

    const handleAcces = (event) => {
        event.preventDefault()
        setPassword(event.target.value)
        setAccess("Oui")
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessage("")
        if(client === "" || manager === "" || email === "" || phone === "" || address === "" || contract === " " || status === ""){
            setMessage({error: true, msg: "Les champs avec une * sont obligatoires"})
            return
        }
        const newClient = {
            client: client,
            manager: manager,
            email: email,
            password: password,
            access: access,
            phone: phone,
            address: address,
            contract: contract,
            status: status
        }

        try {
            if(id !== undefined && id !== ""){
                await ClientDataService.updateClient(id,newClient)
                setClientId("")
                setMessage({error: false, msg: "Les modifications sont enregistrées"})
            }
            else {
                await ClientDataService.addClients(newClient)
                setMessage({error: false, msg: "La nouvelle entrée a été créée avec succès"})
            }
        } catch (err){
            setMessage({error: true, msg: err.message})
        }
        setClient("")
        setManager("")
        setEmail("")
        setPassword("")
        setAccess("")
        setPhone("")
        setAddress("")
        setContract("")
        setStatus("")
    }
    
    const handleEdit = async () => {
        setMessage("")
        try{
            const docSnap = await ClientDataService.getClient(id)
            setClient(docSnap.data().client)
            setManager(docSnap.data().manager)
            setEmail(docSnap.data().email)
            setAccess(docSnap.data().access)
            setPassword(docSnap.data().password)
            setPhone(docSnap.data().phone)
            setAddress(docSnap.data().address)
            setContract(docSnap.data().contract)
            setStatus(docSnap.data().status)

        } catch (err){
            setMessage({error: true, msg: err.message})
        }
    }

    useEffect(() => {
        if(id !== undefined && id !== ""){
            handleEdit()
        }
    },[id])

    return (
        <div className="newClient">
            <div className="newClientContainer">
                <div className="top">
                    <h1>Ajouter / Modifier un client</h1>
                </div>
                <div className="p-2 h4 m-5 text-center">
                {message?.msg && (<Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>{message?.msg}</Alert>)}
                </div>
                <div className="body">
                    <form onSubmit={handleSubmit}>
                        <div className="formInput">
                            <label>Nom de la firme*</label>
                            <input type="text" value={client} onChange={(event) => {setClient(event.target.value)}} required/>
                        </div>

                        <div className="formInput">
                            <label>Nom du Responsable*</label>
                            <input type="text" value={manager} onChange={(event) => {setManager(event.target.value)}} required/>
                        </div>

                        <div className="formInput">
                            <label>Email*</label>
                            <input type="email" value={email} onChange={(event) => {setEmail(event.target.value)}} required/>
                        </div>

                        <div className="formInput">
                            <label>Mot de passe (min 6 caractères)</label>
                            <input type="password" value={password} minLength="6" maxLength="12" onChange={handleAcces}/>
                            <p className='text-secondary'>facultatif - ! Pour valider l'accès à l'Espace clients, le client doit aussi être rajouté à la liste des Utilisateurs !</p>
                        </div>

                        <div className="formInput">
                            <label>Téléphone*</label>
                            <input type="text" value={phone} onChange={(event) => {setPhone(event.target.value)}} required/>
                        </div>

                        <div className="formInput">
                            <label>Adresse*</label>
                            <input type="text" value={address} onChange={(event) => {setAddress(event.target.value)}} required/>
                        </div>

                        <div className="formInput">
                            <label>Date de Révision du contrat*</label>
                            <input type="text" value={contract} onChange={(event) => {setContract(event.target.value)}} required/>
                        </div>

                        <div className="formInput">
                            <label>Status*</label>
                            <select value={status} onChange={(event) => {setStatus(event.target.value)}} required>
                                <option>Choisir un status</option>
                                <option>Actif</option>
                                <option>Inactif</option>
                            </select>
                        </div>

                        <button type="submit">Ajouter / Modifier</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewClient