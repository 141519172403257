

import { Form, Container, InputGroup,Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import ChecksMaterielDataService from '../../services/checksMaterielServices'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'


function CheckMaterielSingle({ id, close }) {


    
    //states


    const [date, setDate] = useState("")
    const [client, setClient] = useState("")
    const [address, setAddress] = useState('')
    const [status, setStatus] = useState('')
    const [doneBy, setDoneBy] = useState('')
    const [produits, setProduits] = useState([])
    const [prodQtys, setProdQtys] = useState([])
    const [materiels, setMateriels] = useState([])
    const [matQtys, setMatQtys] = useState([])
    const [sacs, setSacs] = useState([])
    const [sacsQtys, setSacsQtys] = useState([])
    const [papiers, setPapiers] = useState([])
    const [papiersQtys, setPapiersQtys] = useState([])
    const [autre1, setAutre1] = useState("")
    const [packing1, setPacking1] = useState('')
    const [qty1, setQty1] = useState('')
    const [autre2, setAutre2] = useState("")
    const [packing2, setPacking2] = useState('')
    const [qty2, setQty2] = useState('')
    const [autre3, setAutre3] = useState("")
    const [packing3, setPacking3] = useState('')
    const [qty3, setQty3] = useState('')
    const [autre4, setAutre4] = useState("")
    const [packing4, setPacking4] = useState('')
    const [qty4, setQty4] = useState('')
    const [autre5, setAutre5] = useState("")
    const [packing5, setPacking5] = useState('')
    const [qty5, setQty5] = useState('')
    const [clientName, setClientName] = useState("")
    const [signature, setSignature] = useState('')
    const [deliveredBy, setDeliveredBy] = useState('')
    

    const [message, setMessage] = useState({ error: false, msg: "" })

   

    const handleEdit = async () => {
        setMessage("")
        try {
            const docSnap = await ChecksMaterielDataService.getCheckMateriel(id)
            setDate(docSnap.data().date)
            setClient(docSnap.data().client)
            setAddress(docSnap.data().address)
            setStatus(docSnap.data().status)
            setDoneBy(docSnap.data().doneBy)
            setProduits(docSnap.data().produit)
            setProdQtys(docSnap.data().prodQty)
            setMateriels(docSnap.data().materiel)
            setMatQtys(docSnap.data().matQty)
            setSacs(docSnap.data().sac)
            setSacsQtys(docSnap.data().sacQty)
            setPapiers(docSnap.data().papier)
            setPapiersQtys(docSnap.data().papierQty)
            setAutre1(docSnap.data().autre1)
            setPacking1(docSnap.data().packing1)
            setQty1(docSnap.data().qty1)
            setAutre2(docSnap.data().autre2)
            setPacking2(docSnap.data().packing2)
            setQty2(docSnap.data().qty2)
            setAutre3(docSnap.data().autre3)
            setPacking3(docSnap.data().packing3)
            setQty3(docSnap.data().qty3)
            setAutre4(docSnap.data().autre4)
            setPacking4(docSnap.data().packing4)
            setQty4(docSnap.data().qty4)
            setAutre5(docSnap.data().autre5)
            setPacking5(docSnap.data().packing5)
            setQty5(docSnap.data().qty5)
            setClientName(docSnap.data().clientName)
            setSignature(docSnap.data().signature)
            setDeliveredBy(docSnap.data().deliveredBy)
        }
        catch (err) {
            setMessage({ error: true, msg: err.message })
        }
    }

    useEffect(() => {
        if (id !== undefined && id !== "") {
            handleEdit()
        }
    }, [id])

    //imprimer le rapport,créer PDF 
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `contrôle-matériel${date}${client}`,
        
    })

    
        return (
            <>
                <Button className='m-5 bg-secondary' onClick={handlePrint} style={{ cursor: "pointer" }}>Print / PDF </Button>
                
                <div ref={componentRef} className='text-center'>
                    <h4 className='mb-5 '>Contrôle du {date}</h4>
                    <Container className='w-50 mb-4'>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Date</InputGroup.Text>
                            <Form.Control type="Texte" value={date} readOnly={true} />
                        </InputGroup>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Client</InputGroup.Text>
                            <Form.Control type="Texte" value={client} readOnly={true} />
                        </InputGroup>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Adresse</InputGroup.Text>
                            <Form.Control type="Texte" value={address} readOnly={true} />
                        </InputGroup>
                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Status</InputGroup.Text>
                            <Form.Control type="Texte" value={status} readOnly={true} />
                        </InputGroup>
                    </Container>
                    <div className='text-center mt-4'>
                        <h5>Produits</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {produits.map((produit, index) => {

                                    return (
                                        <tr key={[produit.id]} >
                                            <td value={[produit.product]}>{[produit.product]}</td>
                                            <td>{[produit.packing]}</td>
                                            <td className="table-primary">

                                                <div className="input-group " >
                                                    <input type="number" className="form-control" value={prodQtys[index]} />
                                                </div>

                                            </td>

                                        </tr>

                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Petit Matériel</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materiels.map((materiel, index) => {

                                    return (
                                        <tr key={[materiel.id]} >
                                            <td value={[materiel.product]}>{[materiel.product]}</td>
                                            <td>{[materiel.packing]}</td>
                                            <td className="table-primary">
                                                <div className="input-group">
                                                    <input type="number" className="form-control" value={matQtys[index]} />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Sacs Aspirateur</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sacs.map((sac, index) => {

                                    return (
                                        <tr key={[sac.id]}>
                                            <td value={[sac.product]}>{[sac.product]}</td>
                                            <td>{[sac.packing]}</td>
                                            <td className="table-primary">
                                                <div className="input-group">
                                                    <input type="number" className="form-control" value={sacsQtys[index]} onChange={(event) => {
                                                        setSacsQtys((prev) => {
                                                            const result = [...prev]
                                                            result[index] = event.target.value
                                                            return result
                                                        })


                                                    }} />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Papier</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Quantité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {papiers.map((papier, index) => {

                                    return (
                                        <tr key={[papier.id]} >
                                            <td value={[papier.product]}>{[papier.product]}</td>
                                            <td>{[papier.packing]}</td>
                                            <td className="table-primary">
                                                <div className="input-group">
                                                    <input type="number" className="form-control" value={papiersQtys[index]} onChange={(event) => {
                                                        setPapiersQtys((prev) => {
                                                            const result = [...prev]
                                                            result[index] = event.target.value
                                                            return result
                                                        })


                                                    }} />
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-center mt-4'>
                        <h5>Autre</h5>
                        <table className="table table-striped text-center" style={{ margin: 'auto', width: '50%' }}>
                            <thead>
                                <tr>
                                    <th>Produit</th>
                                    <th>Conditionnement</th>
                                    <th>Quantité</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre1} onChange={(event) => { setAutre1(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing1} onChange={(event) => { setPacking1(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty1} onChange={(event) => { setQty1(event.target.value) }} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre2} onChange={(event) => { setAutre2(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing2} onChange={(event) => { setPacking2(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty2} onChange={(event) => { setQty2(event.target.value) }} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre3} onChange={(event) => { setAutre3(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing3} onChange={(event) => { setPacking3(event.target.value) }} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty3} onChange={(event) => { setQty3(event.target.value) }} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre4} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing4} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty4} />
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={autre5} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={packing5} />
                                        </div>
                                    </td>
                                    <td className="table-primary">
                                        <div className="input-group">
                                            <input type="number" className="form-control" value={qty5} />
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Container className='w-50 mb-4 mt-5'>
                        <InputGroup className='mb-3 p-3 mt-5'>
                            <InputGroup.Text>Etabli par</InputGroup.Text>
                            <Form.Control type="Texte" value={doneBy} readOnly={true} />
                        </InputGroup>

                        <InputGroup className="mb-3 p-3" >
                            <InputGroup.Text>Livré par: </InputGroup.Text>
                            <Form.Control type="Texte" value={deliveredBy} readOnly={true} />
                        </InputGroup>

                        <InputGroup className='mb-3 p-3'>
                            <InputGroup.Text>Nom Client</InputGroup.Text>
                            <Form.Control type="Texte" value={clientName} readOnly={true} />
                        </InputGroup>

                        <p className='text-center h5'>Signature Client</p>
                        <div className="signature">
                            <img src={signature} alt="" />
                        </div>
                    </Container>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button>
                </div>
            </>
        )
    }
  

export default CheckMaterielSingle