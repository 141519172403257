import HomeNavbar from '../../components/homeNavbar/HomeNavbar'
import HomeSidebar from '../../components/homeSidebar/HomeSidebar'
import './listInactiveClients.scss'
import ClientDataService from '../../services/clientsService'
import { Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import DatatableInactiveClients from '../../components/dataTableInactiveClients/DataTableInactiveClients'
import NewClient from '../newClient/NewClient'


const ListInactiveClients = () => {

    const [clients, setClients] = useState([])

    useEffect(() => {
        getClients()
    }, [])

    const getClients = async () => {
        const data = await ClientDataService.getInactiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    const [clientId, setClientId] = useState("")

    const [open, setOpen] = useState(false)

    const handleGetClientId = (id) => {
        setClientId(id)
        setOpen(true)
    }

    const close = () => {
        setOpen(false)
    }

    const show = () => {
        setOpen(true)
    }

    return (
        <div className='listInactiveClients'>
            <HomeSidebar />
            <div className="listInactiveContainer">
                <HomeNavbar />
                <h1>Clients inactifs</h1>
                {(open === false ) ? <DatatableInactiveClients getClientId={handleGetClientId} open={show}/> : <NewClient id={clientId} setClientId={setClientId} />}
                {(open === true) ? <div className='mb-2'>
                    <Button className='retour m-3 p-3' onClick={close}>Retour à la liste</Button></div> : ""}
            </div>
        </div>
    )
}

export default ListInactiveClients
