import '../../components/homeNavbar/HomeNavbar'
import HomeNavbar from '../../components/homeNavbar/HomeNavbar'
import '../../components/homeSidebar/HomeSidebar'
import HomeSidebar from '../../components/homeSidebar/HomeSidebar'
import Widget from '../../components/widget/Widget'
import './home.scss'
import BarChartProd from '../../components/charts/BarChartProd'
import BarChartMat from '../../components/charts/BarChartMat'
import BarChartSac from '../../components/charts/BarChartSac'
import BarChartPaper from '../../components/charts/BarChartPaper'
import { useState, useEffect } from 'react'
import { Alert, Button } from 'react-bootstrap'
import ChecksMaterielDataService from '../../services/checksMaterielServices'
import ClientDataService from '../../services/clientsService'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'date-fns/locale/fr'
import fr from 'date-fns/locale/fr'
import { registerLocale } from 'react-datepicker'




registerLocale('fr', fr)



function Home() {

    //Définir les dates par défaut 
    const [startDate, setStartDate] = useState(new Date("2023/01/01").toLocaleDateString("fr-FR"));
    const [endDate, setEndDate] = useState(new Date("2023/12/31").toLocaleDateString("fr-FR"));



    const [category, setCategory] = useState("")
    const [showChartProd, setShowChartProd] = useState(false)
    const [showChartMat, setShowChartMat] = useState(false)
    const [showChartSac, setShowChartSac] = useState(false)
    const [showChartPaper, setShowChartPaper] = useState(false)
    const [showButton, setShowButton] = useState(true)
    const [showInputs, setShowInputs] = useState(true)
    const [showNoData, setShowNoData] = useState(false)
    const [message, setMessage] = useState("")



    //Voir les clients pour dropdown "Choisir client"
    const [clients, setClients] = useState([])
    const [client, setClient] = useState("")


    useEffect(() => {
        getClients()
    }, [])

    const getClients = async () => {
        const data = await ClientDataService.getActiveClients()
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    }


    //Extraire les rappports par clients pour le graphique
    const [array, setArray] = useState([])


    const rapportsMateriel = async () => {

        const dataRapports = await ChecksMaterielDataService.getChecksMaterielWithinDateRange(startDate, endDate)

        let newArray = []


        

        dataRapports.docs.map((doc) => {
            const data = doc.data()


            if (data.client === client & category === 'produits') {

                if (newArray.length === 0) {
                    for (let i = 0; i < data.prodQty.length; i++) {
                        newArray.push(+data.prodQty[i])

                    }
                }
                else {

                    for (let i = 0; i < data.prodQty.length; i++) {

                        newArray[i] = +newArray[i] + +data.prodQty[i]

                    }

                }

                setShowChartProd(true)


            }
            else if (data.client === client & category === 'matériel') {

                if (newArray.length === 0) {
                    for (let i = 0; i < data.matQty.length; i++) {

                        newArray.push(+data.matQty[i])

                    }
                }
                else {

                    for (let i = 0; i < data.matQty.length; i++) {

                        newArray[i] = +newArray[i] + +data.matQty[i]

                    }
                }
                setShowChartMat(true)
            }
            else if (data.client === client & category === 'sacs') {

                if (newArray.length === 0) {
                    for (let i = 0; i < data.sacQty.length; i++) {

                        newArray.push(+data.sacQty[i])

                    }
                }
                else {

                    for (let i = 0; i < data.sacQty.length; i++) {

                        newArray[i] = +newArray[i] + +data.sacQty[i]

                    }
                }
                setShowChartSac(true)
            }
            else if (data.client === client & category === 'papier') {

                if (newArray.length === 0) {
                    for (let i = 0; i < data.papierQty.length; i++) {

                        newArray.push(+data.papierQty[i])

                    }
                }
                else {

                    for (let i = 0; i < data.papierQty.length; i++) {

                        newArray[i] = +newArray[i] + +data.papierQty[i]

                    }
                }
                setShowChartPaper(true)
            }

            


        })


        setArray(newArray)


    }

    const handleSubmit = (e) => {
        e.preventDefault()
        rapportsMateriel()
        setShowButton(false)
        setShowInputs(false)
        setShowNoData(true)

    }


    return (


        <div className="home">
            <HomeSidebar />
            <div className="homeContainer">
                <HomeNavbar />

                <div className="widgets">
                    <Widget type="rapportsPassage" />
                    <Widget type="rapportsMateriel" />
                    <Widget type="rapportsQualite" />
                </div>


                <div className="listContainer">

                    <h3 className="text-center mt-5 mb-5">Consommation Produits / Matériel</h3>
                    {showInputs === true ?
                        <div className="chartsContainer">
                            <form className='mb-5 text-center' onSubmit={handleSubmit}>

                                <div className="chartsContainer">
                                    <div>


                                        <label className="m-3">Choisir la période entre</label>
                                        <DatePicker
                                            dateFormat='dd/MM/yyyy'
                                            value={startDate}
                                            onChange={(date) => {
                                                const dateString = new Date(date).toLocaleDateString("fr-FR")
                                                setStartDate(dateString)
                                            }}
                                            locale='fr'
                                            showYearDropdown
                                            scrollableMonthYearDropdown
                                            className='input'

                                        />
                                    </div>
                                    <div>
                                        <label className='m-3'>et</label>
                                        <DatePicker
                                            dateFormat='dd/MM/yyyy'
                                            value={endDate}
                                            onChange={(date) => {
                                                const dateString = new Date(date).toLocaleDateString("fr-FR")
                                                setEndDate(dateString)
                                            }}
                                            locale='fr'
                                            showYearDropdown
                                            scrollableMonthYearDropdown
                                            className='mb-5 input'

                                        />

                                    </div>
                                    <label className='m-1'>Client</label>
                                    <select value={client} multiple={false} onChange={(event) => { setClient(event.target.value) }}>
                                        <option>Choisir un client</option>
                                        {clients.map((client) => (
                                            <option key={client.id} value={[client.client]}>{[client.client]}</option>
                                        ))}
                                    </select>
                                    <label className='m-1 ms-5'>Catégorie de produits / matériel</label>
                                    <select value={category} multiple={false} onChange={(event) => { setCategory(event.target.value) }}>
                                        <option>Choisir une catégorie</option>
                                        <option value="produits">Produits</option>
                                        <option value="matériel">Petit Matériel</option>
                                        <option value="sacs">Sacs Aspirateur</option>
                                        <option value="papier">Papier</option>
                                    </select>

                                </div>

                                <div>
                                    {showButton === true ? <Button className='mt-4' type='submit'>Voir Graphique</Button> : ''}
                                </div>
                            </form>
                        </div> : ''}

                    
                    {showNoData && !showChartProd && !showChartMat && !showChartPaper && !showChartSac ? (<div><p className='text-center text-danger'>Pas de données disponibles pour ce client ou cette catégorie pendant la période sélectionnée</p><button className="mt-3 ms-3 p-3 text-white" style={{ backgroundColor: "lightseagreen", bordeRadius: "5px", border: "none", cursor: "pointer" }} onClick={() => window.location.reload()}>
                        Retour à la séléction
                    </button></div>) : ""}
                    {message && <Alert variant='danger' dismissible onClose={() => {
                        setMessage("")
                        window.location.reload()
                    }
                    }>{message}</Alert>}



                    <hr className='mb-5' />
                    {showChartProd === true ? <BarChartProd array={array} client={client} category={category} /> : ""}
                    {showChartMat && <BarChartMat array={array} client={client} category={category} />}
                    {showChartSac && <BarChartSac array={array} client={client} category={category} />}
                    {showChartPaper && <BarChartPaper array={array} client={client} category={category} />}


                </div>

            </div>
        </div>


    )

}



export default Home